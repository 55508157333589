import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  getHomePageProducts,
  addToWishlist,
  deleteWish,
  getWishlist,
} from "../../../actions/productActions";
import {
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import numeral from "numeral";
import FavoriteIcon from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

const NewSlider = ({ product }) => {
  const [openSignin, setOpenSignin] = React.useState(false);

  const handleCloseSignin = () => {
    setOpenSignin(false);
  };
  const dispatch = useDispatch();
  const [User, setUser] = useState(JSON.parse(localStorage.getItem("User")));
  const user = User ? User.user : null;
  const [latestProducts, setLatestProducts] = useState([]);
  const [buttonFavoriteIsClicked, isFavoriteClicked] = useState(false);

  const clickHandler = () => {};

  const clickedFavoriteBtn = (idPost) => {
    const id = user._id;
    const formData = {
      product: idPost,
    };
    isFavoriteClicked(true);
    if (id !== null) {
      dispatch(addToWishlist(id, formData));
    }
  };
  const unClickedFavoriteBtn = (idPost) => {
    const userId = user._id;
    // dispatch(getWishlist(userId));
    isFavoriteClicked(false);
    if (userId !== null) {
      dispatch(deleteWish(userId, idPost));
    }
  };
  const fCurrency = (number) => {
    return numeral(Math.trunc(number / 1000) * 1000).format(
      Number.isInteger(Math.trunc(number / 1000) * 1000) ? "0,0" : "0,0.00"
    );
  };

  useEffect(async () => {
    const data = await dispatch(getHomePageProducts());
    setLatestProducts(data);
  }, [dispatch]);

  return (
    <>
      <div className={styles.card}>
        <Link to={`/product/${product._id}`}>
          <img src={product.images[0].url} alt="" className={styles.img} />
        </Link>
        <div>
          <h1 className={styles.title}>{product.name.substring(0, 15)}</h1>
          <p className={styles.category}>{product.category}</p>
          <p className={styles.price}>LBP {fCurrency(product.price)}</p>
        </div>
        <Link to={`/product/${product._id}`}>
          <button className={styles.details}>View details</button>
        </Link>
        <IconButton
          className={`${styles.fav} ${
            buttonFavoriteIsClicked ? styles.active : ""
          }`}
          aria-label="add to favorites"
          onClick={
            user !== null && user !== undefined
              ? buttonFavoriteIsClicked
                ? () => unClickedFavoriteBtn(product._id)
                : clickedFavoriteBtn(product._id)
              : () => setOpenSignin(true)
          }
        >
          <FavoriteIcon />
        </IconButton>
      </div>

      <Dialog
        color="black"
        open={openSignin}
        keepMounted
        onClose={handleCloseSignin}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="dialogheart" id="alert-dialog-slide-title">
          {"Add To Favorites?"}
        </DialogTitle>
        <DialogContent className="dialogheart">
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ padding: 20, color: "black" }}>
              {" "}
              Please Login to your account to enable add to favorites button
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogheart">
          <Button onClick={handleCloseSignin} color="black">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewSlider;
