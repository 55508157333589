import { makeStyles } from "@material-ui/styles";

const UseStyles = makeStyles(() => ({
  itemName: {
    fontSize: "20px",
    maxWidth: "190px",
    fontFamily: "Myriad Pro",
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    marginTop: "6px",
  },

  cards_Hover: {
    // paddingLeft: "15px",
    // paddingRight: "15px",
    //  width: "300px",
    transition: "all 0.5s ease",
    borderRadius: "12px",
    cursor: "pointer",

    boxShadow: " 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
    "&:hover": {
      transform: "scale(1.05)",
      //   border: "0px solid black",
      backgroundColor: "#fafafa",

      boxShadow: "0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06)",
      color: "black",
      //   backgroundColor: "#fafafa",
    },
  },

  iconHover: {
    color: "black",
    transition: "all 0.5s ease",

    top: "-388px",
    right: "-93px",
    width: "50px",
    height: "50px",
    cursor: "pointer",

    "&:hover": {
      // backgroundColor:"green",
      color: "#ffaaa4",
    },
  },

  viewDetailsBTN: {
    color: "white",
    position: "absolute",
    textAlign: "center",
    marginTop: "10px",
    transition: "all 0.5s ease",
    backgroundColor: "black",
    width: "187px",
    fontSize: "13px",
    fontWeight: "bold",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderBottomRightRadius: "12px",
    borderBottomLeftRadius: "12px",
    zIndex: "500",
    "&:hover": {
      //   border: "0px solid black",
      //   boxShadow:
      //     " 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 14px 0 rgba(0, 0, 0, 0.19)",
      backgroundColor: "#ffa28b",
      color: "black",
      //   backgroundColor: "#fafafa",
    },
  },

  iconHoverClicked: {
    position: "relative",
    top: "-388px",
    right: "-93px",
    color: "#c06573",
    // backgroundColor: "red",
    width: "50px",
    height: "50px",
    cursor: "pointer",
  },

  itemDesc: {
    fontSize: "16px",
    fontFamily: "Source Sans Pro",
    marginTop: "-6px",
    textAlign: "center",
  },
  itemPrice: {
    fontSize: "20px",
    textAlign: "center",
    fontFamily: "Helvetica",
    marginTop: "8px",
  },
  Favorite: {
    position: "absolute",
    top: 10,
    right: 15,
    color: "red",
    // backgroundColor: "red",
    width: "50px",
    height: "50px",
    cursor: "pointer",
  },
  NotFavorite: {
    position: "absolute",
    top: 10,
    right: 15,
    color: "black",
    width: "50px",
    height: "50px",
    cursor: "pointer",
  },

  "@media (max-width: 600px)": {
    itemName: {
      fontSize: "10px",
      maxWidth: "190px",
      marginTop: "0px",
    },
    itemDesc: {
      fontSize: "13px",
      marginTop: "-6px",
    },
    itemPrice: {
      fontSize: "18px",
      marginTop: "4px",
    },
    viewDetailsBTN: {
      marginTop: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      fontSize: "13px",
      width: "147px",
    },
    iconHover: {
      top: "-296px",
      right: "-74px",
    },
    iconHoverClicked: {
      top: "-296px",
      right: "-74px",
    },
  },

  "@media (max-width: 500px)": {
    itemName: {
      fontSize: "10px",
      maxWidth: "190px",
      marginTop: "3px",
    },
    iconHover: {
      top: "-279px",
      right: "-65px",
    },
    iconHoverClicked: {
      top: "-279px",
      right: "-65px",
    },
    viewDetailsBTN: {
      marginTop: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      fontSize: "13px",
      width: "135px",
    },
  },

  "@media (max-width: 455px)": {
    itemName: {
      fontSize: "10px",
      maxWidth: "190px",
      marginTop: "3px",
    },
    itemDesc: {
      fontSize: "13px",
      marginTop: "-6px",
    },
    itemPrice: {
      fontSize: "18px",
      marginTop: "7px",
    },
    iconHover: {
      top: "-262px",
      right: "-59px",
    },
    iconHoverClicked: {
      top: "-262px",
      right: "-59px",
    },
    viewDetailsBTN: {
      marginTop: "18px",
      paddingTop: "4px",
      paddingBottom: "4px",
      fontSize: "13px",
      width: "121px",
    },
  },

  // "@media (max-width: 360px)": {

  //     iconHover: {
  //         top: "-240px",
  //         right: "-52px",
  //     },
  //     iconHoverClicked: {
  //         top: "-240px",
  //         right: "-52px",
  //     },
  //     viewDetailsBTN: {
  //         marginTop: "16px",
  //         paddingTop: "5px",
  //         paddingBottom: "5px",
  //         fontSize: "13px",
  //         width: "108px"
  //     },
  // },

  // "@media (max-width: 320px)": {

  //     iconHover: {
  //         top: "-231px",
  //         right: "-50px",
  //     },
  //     iconHoverClicked: {
  //         top: "-231px",
  //         right: "-50px",
  //     },
  //     viewDetailsBTN: {
  //         marginTop: "16px",
  //         paddingTop: "5px",
  //         paddingBottom: "5px",
  //         fontSize: "13px",
  //         width: "101px"
  //     },
  // },
}));

export default UseStyles;
