import React, { useEffect, useState } from 'react';
import './style.scss'
import { Trash2, Plus, Minus } from 'react-feather';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import UseStyles from "./trash";
import Hidden from '@material-ui/core/Hidden';
import numeral from 'numeral';
import {
    addItemToCart,
    getUserCart,
    removeItemCart,
    removeItemFromCart,
    updateItemQuantity
} from "../../actions/cartActions";
import { useAlert } from "react-alert";
import axios from 'axios';
const ItemCartBox = ({ user }) => {
    const fCurrency = (number) => {
        return numeral((Math.trunc(number / 1000) * 1000)).format(Number.isInteger((Math.trunc(number / 1000) * 1000)) ? '0,0' : '0,0.00');
    }
    const dispatch = useDispatch();
    const alert = useAlert();
    const { cartItems } = useSelector(state => state.cart);
    const [userCart, setUserCart] = useState([]);
    const [quantity, setQuantity] = useState();
    const [cartId, setCartId] = useState('')
    const [TotalCart, setTotalCart] = useState()
    const removeCartItemHandler = (id, color) => {
        dispatch(removeItemFromCart(id, color))
    }
    const removeCartItem = async (id, cartId) => {
        const cartData = {
            id: id,
            cartId: cartId
        }
        const success = await dispatch(removeItemCart(cartData))
        if (success) {
            window.location.reload();
        }
    }
    const increaseQty = (id, quantity, size, color, Quantity) => {
        const newQty = quantity + 1;
        //if (newQty > Quantity) return;
        dispatch(addItemToCart(id, newQty, size, color, Quantity))
    }
    const decreaseQty = (id, quantity, size, color, Quantity) => {
        const newQty = quantity - 1;
        if (newQty <= 0) return;
        dispatch(addItemToCart(id, newQty, size, color, Quantity))
    }
    const increaseQtyCart = (quantity, cartID, Quantity) => {
        const newQty = quantity + 1;
        //if (newQty > Quantity) return alert.error('Quantity exceeded');
        setQuantity(newQty);
        setCartId(cartID)
    }
    const decreaseQtyCart = (quantity, cartID) => {
        const newQty = quantity - 1;
        setQuantity(newQty);
        setCartId(cartID)
        if (newQty <= 0) return;
    }
    const [deliveryRate, setDeliveryRate] = useState(0);
    const getOriginalDeliveryRate = async () => {
        try {
            const response = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/deliveryRate`)
            setDeliveryRate(response.data.dr[0].value);
        } catch (error) {
            console.error(`Error: ${error}`)
        }
    }
    const functGet = async () => {
        const data = await dispatch(getUserCart(user));
        getOriginalDeliveryRate();
        if (data) {
            if (data.cart !== null && data.cart !== undefined) {
                setUserCart(data.cart.cartItems);
            }
        }
    }
    const functGet1 = async () => {
        await dispatch(updateItemQuantity(user, quantity, cartId));
        const data = await dispatch(getUserCart(user));
        if (data) {
            if (data.cart !== null && data.cart !== undefined) {
                setUserCart(data.cart.cartItems);
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        functGet();
        functGet1();
        /*cartItems.length !== 0 && userCart.length !== 0 ?
        cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) +
        userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
        
        : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
        : userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)*/


    }, [user, quantity, cartId]);

    let result = userCart.filter(o1 => cartItems.some(o2 => o1.product === o2.product && o1.color === o2.color && o1.size === o2.size))
    result && result.map((item) => {
        const index = cartItems.findIndex(key => key.product === item.product && key.color === item.color && key.size === item.size);
        cartItems.splice(index, 1);
    })
    const classes = UseStyles();
    return (
        <>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} >
                    <section className="cart_list_wrap">
                        {
                            userCart.length > 0 ? (
                                <>
                                    <div className="cart_responsive">
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            {
                                                userCart.map(item => (
                                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                                                        <div style={{ fontFamily: 'Roboto,sans-serif' }} className="tr_item">
                                                            <div className="td_item item_img">
                                                                <img
                                                                    src={item.image} />
                                                            </div>
                                                            <div style={{ marginLeft: "5px", fontFamily: 'Roboto,sans-serif' }} className="td_item item_name">
                                                                <label style={{ fontFamily: 'Roboto,sans-serif' }} className="main">{item.name}</label>
                                                            </div>
                                                            <div className="td_item item_color">
                                                                <IconButton size="small" style={{ borderStyle: "solid", borderWidth: "1px", borderColor: "gray", color: `${item.color}` }} aria-label="favorite">
                                                                    <FiberManualRecordIcon />
                                                                </IconButton>
                                                                {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                {/* <label className="itemSize"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.size}</label> */}
                                                            </div>
                                                            <div className="td_item item_color">
                                                                {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                <label className="itemSize"
                                                                    style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.size}</label>
                                                            </div>
                                                            <div className="td_item item_qty">
                                                                <div className="quantity-input">
                                                                    <button className="quantity-input__modifier quantity-input__modifier--left"
                                                                        onClick={() => decreaseQtyCart(item.quantity, item._id)}>
                                                                        <Minus />
                                                                    </button>
                                                                    <input className="quantity-input__screen" type="text" value={item.quantity}
                                                                        readOnly />
                                                                    <button className="quantity-input__modifier quantity-input__modifier--right"
                                                                        onClick={() => increaseQtyCart(item.quantity, item._id, item.Quantity)}>
                                                                        <Plus />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="td_item item_price">
                                                                {/* {
                                                                    item.discountRate && item.discountRate !== 0 ? (
                                                                        <>
                                                                            <label style={{ fontFamily: 'Roboto,sans-serif' }}>$ {(item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) * item.quantity}</label>
                                                                        </>
                                                                    ) : (
                                                                        <label style={{ fontFamily: 'Roboto,sans-serif' }}>$ {item.dollarPrice * item.quantity}</label>
                                                                    )
                                                                } */}
                                                                {
                                                                    item.discountRate && item.discountRate !== 0 ? (
                                                                        <>
                                                                            <label style={{ fontFamily: 'Roboto,sans-serif' }}>LBP {fCurrency((item.price - (item.price * item.discountRate / 100)) * item.quantity)}</label>
                                                                        </>
                                                                    ) : (
                                                                        <label style={{ fontFamily: 'Roboto,sans-serif' }}>LBP {fCurrency(item.price * item.quantity)}</label>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className="td_item item_remove"
                                                                onClick={() => removeCartItem(user, item._id)}  >
                                                                <Trash2 />
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                ))
                                            }
                                            {cartItems.length === 0 ? null : (
                                                <>
                                                    <div className="cart_responsive">
                                                        {
                                                            cartItems.map(item => (
                                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                                                                    <div className="tr_item">
                                                                        <div className="td_item item_img">
                                                                            <img
                                                                                src={item.image} />
                                                                        </div>
                                                                        <div className="td_item item_name">
                                                                            <label className="main">{item.name}</label>
                                                                        </div>
                                                                        <div className="td_item item_color">
                                                                            <IconButton size="small" style={{ borderStyle: "solid", borderWidth: "1px", borderColor: "gray", color: `${item.color}` }} aria-label="favorite">
                                                                                <FiberManualRecordIcon />
                                                                            </IconButton>
                                                                            {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                            {/* <label className="itemSize"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.size}</label> */}
                                                                        </div>
                                                                        <div className="td_item item_color">
                                                                            {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                            <label className="itemSize"
                                                                                style={{ fontFamily: 'Roboto,sans-serif', color: 'black' }}>{item.size}</label>
                                                                        </div>
                                                                        <div className="td_item item_qty">
                                                                            <div className="quantity-input">
                                                                                <button className="quantity-input__modifier quantity-input__modifier--left"
                                                                                    onClick={() => decreaseQty(item.product, item.quantity, item.size, item.color, item.Quantity)}>
                                                                                    <Minus />
                                                                                </button>
                                                                                <input className="quantity-input__screen" type="text" value={item.quantity}
                                                                                    readOnly />
                                                                                <button className="quantity-input__modifier quantity-input__modifier--right"
                                                                                    onClick={() => increaseQty(item.product, item.quantity, item.size, item.color, item.Quantity)}>
                                                                                    <Plus />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="td_item item_price">
                                                                            {/* {
                                                                                item.discountRate && item.discountRate !== 0 ? (
                                                                                    <>
                                                                                        <label style={{ fontFamily: 'Roboto,sans-serif' }}>$ {(item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) * item.quantity}</label>
                                                                                    </>
                                                                                ) : (
                                                                                    <label style={{ fontFamily: 'Roboto,sans-serif' }}>$ {item.dollarPrice * item.quantity}</label>
                                                                                )
                                                                            } */}
                                                                            {
                                                                                item.discountRate && item.discountRate !== 0 ? (
                                                                                    <>
                                                                                        <label style={{ fontFamily: 'Roboto,sans-serif' }}>LBP {fCurrency((item.price - (item.price * item.discountRate / 100)) * item.quantity)}</label>
                                                                                    </>
                                                                                ) : (
                                                                                    <label style={{ fontFamily: 'Roboto,sans-serif' }}>LBP {fCurrency(item.price * item.quantity)}</label>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="td_item item_remove"
                                                                            onClick={() => removeCartItemHandler(item.product, item.color)}>
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                            )
                                            }
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                cartItems.length === 0 ? <h2 className="cart_title" style={{ justifyContent: "center" }}>Your Cart Is Empty </h2> : (
                                    <>
                                        <Hidden only={['xs']}>                           <div className="cart_responsive">
                                            {
                                                cartItems.map(item => (
                                                    <Grid container xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', alignItems: "center", justifyContent: "center", direction: "row", border: "0px solid red" }}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', border: "1px solid black", alignItems: "center", justifyContent: "space-around", direction: "row" }}>
                                                            <div>
                                                                <img
                                                                    alt="Order Image"
                                                                    src={item.image} style={{ width: "170px", height: "190px" }} />
                                                            </div>
                                                            <Grid container xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "space-around", direction: "row" }}>
                                                                <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <div >
                                                                        <label style={{ fontSize: "18px" }}>{item.name}</label>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <div>
                                                                        <IconButton size="small" style={{ borderStyle: "solid", borderWidth: "1px", borderColor: "gray", color: `${item.color}` }} aria-label="favorite">
                                                                            <FiberManualRecordIcon />
                                                                        </IconButton>
                                                                        {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                        {/* <label className="itemSize"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.size}</label> */}
                                                                    </div>  </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <div>
                                                                        {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                        <label className="itemSize"
                                                                            style={{ fontFamily: 'Roboto,sans-serif', color: 'black', fontSize: "18px" }}>{item.size}</label>
                                                                    </div> </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', border: "0px solid blue", alignItems: "center", justifyContent: "space-around", direction: "row", marginTop: "25px" }}>
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                        <div style={{ transform: 'scale(0.7)', marginLeft: "-25px", border: "0px solid red" }}>
                                                                            <button className="quantity-input__modifier quantity-input__modifier--left"
                                                                                onClick={() => decreaseQty(item.product, item.quantity, item.size, item.color, item.Quantity)}>
                                                                                <Minus />
                                                                            </button>
                                                                            <input className="quantity-input__screen" type="text" value={item.quantity}
                                                                                readOnly />
                                                                            <button className="quantity-input__modifier quantity-input__modifier--right"
                                                                                onClick={() => increaseQty(item.product, item.quantity, item.size, item.color, item.Quantity)}>
                                                                                <Plus />
                                                                            </button>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                        <div>
                                                                            {/* {
                                                                                item.discountRate && item.discountRate !== 0 ? (
                                                                                    <>
                                                                                        <div>  <label style={{ fontFamily: 'Roboto,sans-serif', fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>$ {(item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) * item.quantity}</label><br /> </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <div>  <label style={{ fontFamily: 'Roboto,sans-serif', fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>$ {item.dollarPrice * item.quantity}</label><br /> </div>
                                                                                )
                                                                            } */}
                                                                            {
                                                                                item.discountRate && item.discountRate !== 0 ? (
                                                                                    <>
                                                                                        <label style={{ fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>LBP {fCurrency((item.price - (item.price * item.discountRate / 100)) * item.quantity)}</label>
                                                                                    </>
                                                                                ) : (
                                                                                    <label style={{ fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>LBP {fCurrency(item.price * item.quantity)}</label>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: 'flex', border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                        <div className={classes.trash}
                                                                            onClick={() => removeCartItemHandler(item.product, item.color)}>
                                                                            <Trash2 />
                                                                        </div>   </Grid>
                                                                </Grid>
                                                            </Grid></Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </div> </Hidden>
                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>                           <div className="cart_responsive">
                                            {
                                                cartItems.map(item => (
                                                    <Grid container xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', alignItems: "center", justifyContent: "center", direction: "row", border: "0px solid red", marginTop: "15px" }}>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', border: "1px solid black", alignItems: "center", justifyContent: "space-around", direction: "row" }}>
                                                            <div>
                                                                <img
                                                                    alt="Order Image"
                                                                    src={item.image} style={{ width: "230px", height: "260px" }} />
                                                            </div>
                                                            <Grid container xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', border: "0px solid black", alignItems: "center", justifyContent: "space-around", direction: "row" }}>
                                                                <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'flex', border: "0px solid black", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <div >
                                                                        <label style={{ fontSize: "18px", marginTop: "10px" }}>{item.name}</label>
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'flex', border: "0px solid green", marginTop: "10px", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <div>
                                                                        <IconButton size="small" style={{ borderStyle: "solid", borderWidth: "1px", borderColor: "gray", color: `${item.color}` }} aria-label="favorite">
                                                                            <FiberManualRecordIcon />
                                                                        </IconButton>
                                                                        {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                        {/* <label className="itemSize"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.size}</label> */}
                                                                    </div>  </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'flex', border: "0px solid green", marginTop: "10px", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <div>
                                                                        {/* <label className="itemColor"
                                                                style={{ fontFamily: 'Open Sans', color: 'black' }}>{item.color}</label> */}
                                                                        <label className="itemSize"
                                                                            style={{ fontFamily: 'Roboto,sans-serif', color: 'black', fontSize: "18px" }}>{item.size}</label>
                                                                    </div> </Grid>
                                                                <Grid container xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', border: "0px solid blue", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                    <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'flex', border: "0px solid green", marginTop: "10px", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                        <div style={{ transform: 'scale(0.7)', border: "0px solid red" }}>
                                                                            <button className="quantity-input__modifier quantity-input__modifier--left"
                                                                                onClick={() => decreaseQty(item.product, item.quantity, item.size, item.color, item.Quantity)}>
                                                                                <Minus />
                                                                            </button>
                                                                            <input className="quantity-input__screen" type="text" value={item.quantity}
                                                                                readOnly />
                                                                            <button className="quantity-input__modifier quantity-input__modifier--right"
                                                                                onClick={() => increaseQty(item.product, item.quantity, item.size, item.color, item.Quantity)}>
                                                                                <Plus />
                                                                            </button>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'flex', marginTop: "10px", border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                        <div>
                                                                            {/* {
                                                                                item.discountRate && item.discountRate !== 0 ? (
                                                                                    <>
                                                                                        <div>  <label style={{ fontFamily: 'Roboto,sans-serif', fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>$ {(item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) * item.quantity}</label><br /> </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <div>  <label style={{ fontFamily: 'Roboto,sans-serif', fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>$ {item.dollarPrice * item.quantity}</label><br /> </div>
                                                                                )
                                                                            } */}
                                                                            {
                                                                                item.discountRate && item.discountRate !== 0 ? (
                                                                                    <>
                                                                                        <label style={{ fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>LBP {fCurrency((item.price - (item.price * item.discountRate / 100)) * item.quantity)}</label>
                                                                                    </>
                                                                                ) : (
                                                                                    <label style={{ fontSize: "17px", marginTop: "5px", marginLeft: "-15px" }}>LBP {fCurrency(item.price * item.quantity)}</label>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4} md={4} lg={12} style={{ display: 'flex', marginTop: "10px", marginBottom: "10px", border: "0px solid green", alignItems: "center", justifyContent: "center", direction: "row" }}>
                                                                        <div
                                                                            onClick={() => removeCartItemHandler(item.product, item.color)}>
                                                                            <Trash2 />
                                                                        </div>   </Grid>
                                                                </Grid>
                                                            </Grid></Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </div> </Hidden>
                                    </>
                                )
                            )
                        }
                        <div className="footer">
                            <div className="subtotal">
                                <label style={{ fontFamily: "Roboto, sans-serif", fontSize: '18px' }}>Subtotal: </label>
                                <strong style={{ fontFamily: "Roboto, sans-serif", fontSize: '16px', fontWeight: 700, marginLeft: '10px' }}>
                                    {/* $ {
                                        cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                            : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                                : userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                    } &nbsp; */}
                                    LBP {
                                        fCurrency(cartItems.length !== 0 && userCart.length !== 0 ?
                                            cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
                                            + (userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0))
                                            :
                                            cartItems.length !== 0 ?
                                                cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
                                                : userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0))
                                    }
                                </strong>
                            </div>
                            {
                                cartItems.length !== 0 || userCart.length !== 0 ? (
                                    <div className="subtotal">
                                        <label style={{ fontFamily: "Roboto, sans-serif", fontSize: '18px' }}>Delivery Cost: </label>
                                        <strong style={{ fontFamily: "Roboto, sans-serif", fontSize: '16px', fontWeight: 700, marginLeft: '10px' }}>
                                            {/* $ {
                                        cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                            : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                                : userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                    } &nbsp; */}
                                            LBP {fCurrency(deliveryRate)}
                                        </strong>
                                    </div>)
                                    : null}
                            {cartItems.length !== 0 || userCart.length !== 0 ? (
                                <div className="subtotal">
                                    <label style={{ fontFamily: "Roboto, sans-serif", fontSize: '18px' }}>Total: </label>
                                    <strong style={{ fontFamily: "Roboto, sans-serif", fontSize: '16px', fontWeight: 700, marginLeft: '10px' }}>
                                        {/* $ {
                                        cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                            : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                                : userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
                                    } &nbsp; */}
                                        LBP {
                                            fCurrency(cartItems.length !== 0 && userCart.length !== 0 ?
                                                cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
                                                + (userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) + deliveryRate)
                                                :
                                                cartItems.length !== 0 ?
                                                    cartItems.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) + deliveryRate
                                                    : userCart.reduce((acc, item) => acc + (item.discountRate && item.discountRate !== 0 ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) + deliveryRate)
                                        }
                                    </strong>
                                </div>
                            ) : null}
                        </div>
                    </section>
                </Grid>
            </Grid>
        </>
    )
}
export default ItemCartBox;