import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Dashboard from './Dashboard';
import ProductReviews from './ProductReviews';
//import ProductsList from './ProductsList';
import NewProductsList from './NewProductList';
import './style.css'
import UsersList from './UsersList';
export const AdminDashboard = () => {
    return (
        <Tabs style={{ marginTop: "150px" }}>
            <TabList>
                <Tab><i className="fa fa-tachometer"></i> Dashboard </Tab>
                <Tab><i className="fa fa-product-hunt"></i> Products</Tab>
                <Tab><i className="fa fa-users"></i> Users</Tab>
                <Tab><i className="fa fa-star"></i> Reviews</Tab>
            </TabList>

            <TabPanel>
                <Dashboard />
            </TabPanel>
            <TabPanel>
                <NewProductsList />
            </TabPanel>
            <TabPanel>
                <UsersList />
            </TabPanel>
            <TabPanel>
                <ProductReviews />
            </TabPanel>
        </Tabs >
    )
}
