import React from 'react'
import 'rc-slider/assets/index.css'
import '../components/product/style.css'
import '../components/product/style.scss'

const CollectionTypes = ({ setCollectiontype }) => {
    const collections = [
        { value: 'Summer', label: 'Summer' },
        { value: 'Winter', label: 'Winter' }
    ]
    return (
        <>
            <div className="swatch clearfix" data-option-index="0">
                {
                    collections.map((size) => (
                        <div data-value={size.value} onClick={() => setCollectiontype(size.value)} className="swatch-element plain m available">
                            <input id={`${size.value}`} type="radio" name="size-1" value={size.value} />
                            <label htmlFor={`${size.value}`}>
                                {size.value}
                            </label>
                        </div>

                    ))
                }
            </div>
        </>
    )
}
export default CollectionTypes;