import React, { useEffect, useState } from 'react';
import '../ButtonStyle.scss'
import { TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeft } from "react-feather";
import { getUserCart } from "../../../actions/cartActions";
import numeral from 'numeral';
import axios from 'axios';

const ConfirmOrderFidele = ({ next, product, user }) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const { cartItems, orderInfo } = useSelector(state => state.cart)
    const [userCart, setUserCart] = useState([]);
    const itemsPrice = cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
        : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
            : userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
    const dollarItemsPrice = cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
        : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
            : userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
    const [totalPrice,setTotalPrice] = useState(itemsPrice);
    const dollarTotalPrice = dollarItemsPrice.toFixed(2)
	const [deliveryRate, setDeliveryRate] = useState(0);
    const getOriginalDeliveryRate = async () => {
        try {
            const response = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/deliveryRate`)
            setDeliveryRate(response.data.dr[0].value);
        } catch (error) {
            console.error(`Error: ${error}`)
        }
    }
    useEffect(async () => {
		getOriginalDeliveryRate();
        const data = await dispatch(getUserCart(user));
        if (data) {
            if (data.cart !== null && data.cart !== undefined) {
                setUserCart(data.cart.cartItems);
            }
        }
    }, [user]);
	const fCurrency = (number) => {
        return numeral((Math.trunc(number / 1000) * 1000)).format(Number.isInteger((Math.trunc(number / 1000) * 1000)) ? '0,0' : '0,0.00');
    }
    return (
        <>
            <span className="addtitle" style={{ fontFamily: 'Montserrat', fontSize: 25, fontWeight: 700, textAlign: "left", width: "100%", border: "0px solid red", display: "inline-block", marginTop: "10px" }}>Your Order</span>
            <Grid item xs={12} sm={12} md={12} style={{ border: "0px solid black", flexGrow: '1' }}>
                {/* <section className="addtitle"> */}
                <Grid container style={{ border: "0px solid black", }}>
                    <Grid item xs={4} sm={2} md={2}>
                        <Typography style={{ fontFamily: 'Montserrat', border: "0px solid red", textAlign: "left" }} variant="h6" component="h4" gutterBottom ><strong>Name:</strong></Typography>
                    </Grid>
                    <Grid item xs={8} sm={10} md={10}>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >{`${product.firstName} ${product.lastName}`}</Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container >
                    <Grid item xs={4} sm={2} md={2}>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom ><strong>Address:</strong></Typography>
                    </Grid>
                    <Grid item xs={8} sm={10} md={10}>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >{`${product.city}, ${product.street}, ${product.building}, ${product.Floor}`}</Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xs={4} sm={2} md={2}>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom ><strong>SubTotal:</strong></Typography>
                    </Grid>
                    <Grid item xs={8} sm={10} md={10}>
                        {
                            product.Payment === 'LBP' ? (
                                <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >LBP {fCurrency(itemsPrice)}</Typography>
                            ) :
                                <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >$ {dollarItemsPrice}</Typography>
                        }
                        {/* <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >LBP {itemsPrice}</Typography> */}
                    </Grid>
                </Grid>
                <br />
				<Grid container>
                    <Grid item xs={4} sm={2} md={2}>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom ><strong>Delivery Cost:</strong></Typography>
                    </Grid>
                    <Grid item xs={8} sm={10} md={10}>
						<Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >LBP {fCurrency(deliveryRate)}</Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xs={4} sm={2} md={2}>
                        <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom ><strong>Total:</strong></Typography>
                    </Grid>
                    <Grid item xs={8} sm={10} md={10}>
                        {
                            product.Payment === 'LBP' ? (
                                <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >LBP {fCurrency(Number(deliveryRate) + Number(itemsPrice))}</Typography>
                            ) :
                                <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >$ {fCurrency(dollarTotalPrice)}</Typography>
                        }
                        {/* <Typography style={{ fontFamily: 'Montserrat' }} variant="h6" component="h2" gutterBottom >LBP {totalPrice}</Typography> */}
                    </Grid>
                </Grid>
                <br />
                {/* </section> */}
            </Grid>
        </>
    )
}
export default ConfirmOrderFidele;