import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { getWishlist } from "../../actions/productActions";
import Typography from "@material-ui/core/Typography";
import FavoriteCard from "./favoriteCard";
import Fav from "./favor";

const Favorites = () => {
    const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    return (
        <Fav user={user && user} />
    )
}
export default Favorites;