import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword, clearErrors } from '../../actions/userActions'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
const NewPassword = ({ history, match }) => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const alert = useAlert();
    const dispatch = useDispatch();
    const { error, success } = useSelector(state => state.forgotPassword)
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (success) {
            alert.success('Password updated successfully')
            history.push('/')
        }
    }, [dispatch, alert, error, success, history])
    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('password', password);
        formData.set('confirmPassword', confirmPassword);
        dispatch(resetPassword(match.params.token, formData))
    }
    return (
        <Fragment>
            <MetaData title={'New Password Reset'} />
            <div className="row wrapper" style={{ marginTop: '150px' }}>
                <div className="col-10 col-lg-5">
                    <form className="shadow-lg" onSubmit={submitHandler}>
                        <Typography variant="h4">
                            New Password
                        </Typography>
                        <div className="form-group">
                            <label
                                className="typo"
                                htmlFor="password_field">Password</label>
                            <input
                                type="password"
                                id="password_field"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label
                                className="typo"
                                htmlFor="confirm_password_field">Confirm Password</label>
                            <input
                                type="password"
                                id="confirm_password_field"
                                className="form-control typo"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        {/* <button
                            id="new_password_button"
                            type="submit"
                            className="btn btn-block py-3">
                            Set Password
                        </button> */}
                        <Button
                            type="submit"
                            variant="contained" className="btn btn-block py-3" style={{ backgroundColor: 'black', color: 'white' }} > Set Password</Button>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}
export default NewPassword
