import React, { useState } from "react";
import CategoryCheckboxNavbar from "../../CategoryCheckboxNavbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from '@material-ui/core/Hidden';
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import "./navbarRalph.css";
import logoImage from '../Navbar/logo.png'
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import { Link, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AccountBox } from "../../accountBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { logout } from "../../../actions/userActions";
import Search from "../Search";
import { useAlert } from "react-alert";
import { saveCart } from "../../../actions/cartActions";
import IconButtonCart from "../../IconButton";
import { DialogTitle, Grid } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
// import MailIcon from '@material-ui/icons/Mail';
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
}));
export default function NavbarRalph() {
    const alert = useAlert();
    const { cartItems } = useSelector((state) => state.cart);
    const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
    const { loading } = useSelector((state) => state.auth);
    const [User, setUser] = useState(JSON.parse(localStorage.getItem("User")));
    const user = User ? User.user : null;
    const role = User ? JSON.parse(atob(User.token.split(".")[1])).role : null;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [openSignin, setOpenSignin] = React.useState(false);
    const [cartId, setCartId] = useState();
    const theme = useTheme();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const dispatch = useDispatch();
    const isMenuOpen = Boolean(anchorEl);
    const logoutHandler = async () => {
        await dispatch(logout());
        sessionStorage.removeItem("keyAnim");
        const cartlist = JSON.parse(localStorage.getItem("cartItems"));
        const cartToSave = {
            user: user._id,
            cartItems: cartlist,
        };
        const savecart = await dispatch(saveCart(cartToSave));
        if (savecart) {
            localStorage.removeItem("cartItems");
            alert.success("Logged out successfully");
            window.location.href = "/";
        } else {
        }
    };
    const closeHandler = () => {
        setOpen(false);
    }
    const handleLanguageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMenuLanguageClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleClickOpenSignin = () => {
        setOpenSignin(true);
    };
    const handleCloseSignin = () => {
        setOpenSignin(false);
    };
    const goToHomePage = () => {
        window.location.href = "/";
    };
    const openSubCat = () => {
        setOpen(false);
    };
    const categories = [
        { name: 'Women' },
        { name: 'Men' },
        { name: 'Kids' },
        { name: 'Babies' },
        { name: 'Bed Linen' },
        { name: 'Kitchen' },
        { name: 'Toilet' }
    ];
    const cats = categories.map((c, i) => {
        return <li key={`${c.name}${i}`} className="font" style={{
            cursor: 'pointer',
            listStyleType: 'none',
            fontSize: 'large',
            padding: "10px"
        }}
            onClick={() => { window.location.href = (`/productCat/${c.name}`) }}
        > {c.name}
        </li>
    });
    const [category, setCategory] = useState("");
    const [categoryForSub, setCategoryForSub] = useState("");
    const [subCategory, setSub] = useState("");
    const [subcategory, setSubcategory] = useState("");
    // const [subCategory, setSubcategory] = useState('')
    const menuId = "primary-search-account-menu";
    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <i className="fa fa-times"></i>
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    // const renderMenu = (
    //     <Menu
    //         anchorEl={anchorEl}
    //         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         id={menuId}
    //         keepMounted
    //         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         open={isMenuOpen}
    //         onClose={handleMenuLanguageClose}
    //     >
    //         <MenuItem onClick={handleMenuLanguageClose}>English</MenuItem>
    //         <MenuItem onClick={handleMenuLanguageClose}>Arabic</MenuItem>
    //         <MenuItem onClick={handleMenuLanguageClose}>French</MenuItem>
    //     </Menu>
    // );
    return (
        <div className={classes.grow}>
            <AppBar /*className="navbar"*/ position="fixed">
                <Grid container>
                    <Hidden only={['xs']}>
                        <Grid container item xs={12} sm={12} direction="row" display="flex" justifyContent="center" alignItems="center" style={{ height: "33px", backgroundColor: "#FFCA9F" }}>
                            <Typography align="center" className="rubanText" style={{ paddingBottom: '7px', paddingTop: '7px', color: "black", border: "0px solid blue", height: "33px", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                <div style={{ border: "0px solid red" }}>
                                    <a href="tel:+96171566998" className="infoMain"><i className="fa fa-phone" style={{ color: 'black' }}></i> +961 71 566 998</a>
                                    <a href="mailto:lingeirefidele@gmail.com" className="infoMain"><i className="fa fa-envelope" style={{ color: 'black', marginLeft: "18px", marginRight: "1px" }}></i> lingeriefidele@gmail.com</a>
                                </div>
                                <div display="flex" alignItems="center" justifyContent=" center">
                                    <a target="_blank" href="https://www.facebook.com/fidelelingeriie" rel="noreferrer" style={{ border: "0px solid red" }}>
                                        <Box
                                            component="img"
                                            src="/images/facebook.svg"
                                            style={{ transition: "all 2s ease" }}
                                            onMouseEnter={e => (e.currentTarget.src = "/images/facebook_blue.svg")}
                                            onMouseLeave={e => (e.currentTarget.src = "/images/facebook.svg")}
                                            sx={{
                                                height: 22, border: "0px solid green", marginTop: "6px", marginLeft: "5px"
                                            }}
                                            xs={{ height: 30 }}
                                        /> </a>
                                    <a target="_blank" href="https://www.instagram.com/lingerie_fidele" rel="noreferrer" >
                                        <Box
                                            component="img"
                                            src="/images/instagram.svg"
                                            onMouseEnter={e => (e.currentTarget.src = "/images/instagram_hover.svg")}
                                            onMouseLeave={e => (e.currentTarget.src = "/images/instagram.svg")}
                                            style={{ transition: "all 2s ease", marginLeft: "3px" }}
                                            sx={{ height: 22, marginTop: "6px", marginLeft: "2px" }}
                                            xs={{ height: 30 }}
                                        />  </a>
                                </div>
                            </Typography>  </Grid>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Grid container item xs={12} sm={12} direction="row" display="flex" justifyContent="center" alignItems="center" style={{ height: "33px", backgroundColor: "#FFCA9F" }}>
                            <Typography align="center" className="rubanText" style={{ paddingBottom: '7px', paddingTop: '7px', color: "black", border: "0px solid blue", height: "33px", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "center" }} >
                                <div style={{ border: "0px solid red" }}>
                                    <a href="tel:+96171566998" className="infoMain"><i className="fa fa-phone" style={{ color: 'black' }}></i> +961 71 566 998</a>
                                    <a href="mailto:lingeirefidele@gmail.com" className="infoMain"><i className="fa fa-envelope" style={{ color: 'black', marginLeft: "18px", marginRight: "1px" }}></i> lingeriefidele@gmail.com</a>
                                </div>
                                <div display="flex" alignItems="center justifyContent=" center>
                                    <a target="_blank" href="https://www.facebook.com/fidelelingeriie" style={{ border: "0px solid red" }} rel="noreferrer">
                                        <Box
                                            component="img"
                                            src="/images/facebook.svg"
                                            style={{ transition: "all 2s ease" }}
                                            onMouseEnter={e => (e.currentTarget.src = "/images/facebook_blue.svg")}
                                            onMouseLeave={e => (e.currentTarget.src = "/images/facebook.svg")}
                                            sx={{
                                                height: 19, border: "0px solid green", marginTop: "6px", marginLeft: "5px"
                                            }}
                                        /> </a>
                                    <a target="_blank" href="https://www.instagram.com/lingerie_fidele/" rel="noreferrer" >
                                        <Box
                                            component="img"
                                            src="/images/instagram.svg"
                                            onMouseEnter={e => (e.currentTarget.src = "/images/instagram_hover.svg")}
                                            onMouseLeave={e => (e.currentTarget.src = "/images/instagram.svg")}
                                            style={{ transition: "all 2s ease", marginLeft: "3px" }}
                                            sx={{ height: 19, marginTop: "6px", marginLeft: "2px" }}
                                        />  </a>
                                </div>
                            </Typography></Grid>
                    </Hidden>
                </Grid>
                <Toolbar className="navbar">
                    <IconButton
                        edge="start"
                        onClick={handleDrawerOpen}
                        className="mobileMenu"
                        style={{ border: "0px solid red", backgroundColor: "white" }}
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography className={classes.title} variant="h6" noWrap> */}
                    <img
                        onClick={goToHomePage}
                        className="logoPosition"
                        src={logoImage}
                        alt="lingerie fidele"
                    ></img >
                    <div className="ml-5" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="itemss mr-3">
                            <ul>
                                <div className="navItems" style={{ paddingBottom: '21px', paddingTop: '19px' }}>
                                    <li className="font" style={{
                                        cursor: 'pointer',
                                        listStyleType: 'none',
                                        fontSize: "20px"
                                    }}
                                        onClick={() => { window.location.href = "/"; }}
                                    // onMouseEnter={() => setCategoryForSub(categories.name)}
                                    >Home</li>
                                </div>
                            </ul>
                        </div>
                        <div className="items mr-3">
                            <ul>
                                <div className="navItems" style={{ paddingBottom: '21px', paddingTop: '19px' }}>
                                    <li className="font" style={{
                                        cursor: 'pointer',
                                        listStyleType: 'none',
                                        fontSize: "20px"
                                    }}
                                    >Products</li>
                                </div>
                            </ul>
                            <div className="subCat">
                                <div className="subList">
                                    <ul >
                                        <>
                                            {
                                                cats
                                            }
                                        </>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="itemss" >
                            <ul>
                                <div className="navItems" style={{ paddingBottom: '21px', paddingTop: '19px' }}>
                                    <li className="font" style={{
                                        cursor: 'pointer',
                                        listStyleType: 'none',
                                        fontSize: "20px"
                                    }}
                                        onClick={() => { window.location.href = "/contactus"; }}
                                    >Contact Us</li>
                                </div>
                            </ul>
                            {/*<CategoryCheckboxNavbar
                            setCategoryForSub={setCategoryForSub}
                            setCategory={setCategory}
                            setSub={setSub}
                            cat={category}
                        />
                        <div className="subCat">
                            <CheckNavbar
                                setCategory={setCategory}
                                category={categoryForSub}
                                setSubCategory={setSubcategory}
                                subCategory={subcategory}
                            />
                        </div> */}
                        </div>
                    </div>
                    {/* <div class="col"> */}
                    {/* <BasicAutoSuggest /> */}
                    {/* <Route
                            render={({ history }) => <SearchRalph history={history} />}
                        /> */}
                    <Route
                        render={({ history }) => <Search history={history} />}
                    />
                    {/* </div> */}
                    {/* <div className={classes.grow} /> */}
                    <div className="col text-right d-flex flex-column justify-content-end itemsMenu" style={{ display: "flex" }}>
                        <div>
                            <Link to="/cartFidele">
                                <IconButtonCart
                                    userId={user ? user._id : null}
                                    cartItems={cartItems}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="loginBtn">
                        {
                            user ? (
                                <div className="mr-5 dropdown d-inline">
                                    <Link
                                        style={{ backgroundColor: "black", paddingRight: 10 }}
                                        to="#!"
                                        className="btn dropdown-toggle text-white mr-3 pr-4"
                                        type="button"
                                        id="dropDownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <span>{user && user.name}</span>
                                    </Link>
                                    <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropDownMenuButton"
                                    >
                                        {role && role !== "admin" ? (
                                            // <Link className="dropdown-item" to="/orders/me">
                                            //     Orders
                                            // </Link>
                                            null
                                        ) : (
                                            <Link className="dropdown-item" to="/admin/dashboard">
                                                Dashboard
                                            </Link>
                                        )}
                                        {user ? (
                                            <Link className="dropdown-item" to="/Favorites">
                                                Favorites
                                            </Link>
                                        ) : null}
                                        <Link className="dropdown-item" to="/me/update">
                                            Profile
                                        </Link>
                                        <Link
                                            className="dropdown-item text-danger"
                                            to="/"
                                            onClick={logoutHandler}
                                        >
                                            Logout
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                !loading && (
                                    // <Link to="/account" id="login_btn">
                                    <Button
                                        className="btnLoginn"
                                        variant="contained"
                                        onClick={handleClickOpenSignin}
                                    // style={{
                                    //     backgroundColor: "black",
                                    //     color: "white",
                                    //     marginRight: "50px",
                                    //     marginLeft: "50px"
                                    // }}
                                    >
                                        Login
                                    </Button>
                                )
                            )
                            // </Link>
                        }
                        <Dialog
                            className={classes.dialog2}
                            open={openSignin}
                            onClose={handleCloseSignin}
                            // PaperComponent={PaperComponent}
                            aria-labelledby="draggable-dialog-title"
                        >
                            <BootstrapDialogTitle style={{ position: "absolute", right: "0" }} id="customized-dialog-title" onClose={handleCloseSignin}>
                            </BootstrapDialogTitle>
                            <div className={classes.dialog}>
                                <DialogContent className={classes.dialog}>
                                    <AppContainer className={classes.appdialog}>
                                        <AccountBox />
                                    </AppContainer>
                                </DialogContent>
                            </div>
                        </Dialog>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer className="drawer" variant="persistent" anchor="left" open={open}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "ltr" ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </div>
                <Divider />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <span className="namemobile">
                        {user && user.name}
                    </span>
                    <IconButton aria-label="show 4 new mails" color="inherit">
                        <Badge badgeContent={cartItems.length} color="secondary">
                            <Link to="/cartFidele">
                                <ShoppingBasketIcon />
                            </Link>
                        </Badge>
                    </IconButton>
                </div>
                {
                    user ? (
                        <div
                            className="mr-5 dropdown d-inline"
                            style={{ textAlign: "center" }}
                        >
                            <div>
                                {role && role !== "admin" ? (
                                    null
                                    // <ListItem>
                                    //     <Link className="listmobile" to="/orders/me">
                                    //         Orders
                                    //     </Link>
                                    // </ListItem>
                                ) : (
                                    <ListItem>
                                        {" "}
                                        <Link className="dropdown-item" to="/admin/dashboard" onClick={closeHandler}>
                                            Dashboard
                                        </Link>
                                    </ListItem>
                                )}
                                {user ? (
                                    <ListItem>
                                        {" "}
                                        <Link className="listmobile" to="/Favorites" onClick={closeHandler}>
                                            Favorites
                                        </Link>{" "}
                                    </ListItem>
                                ) : null}
                                <ListItem>
                                    <Link className="listmobile" to="/me/update" onClick={closeHandler}>
                                        Profile
                                    </Link>{" "}
                                </ListItem>
                                <ListItem>
                                    {" "}
                                    <div>
                                        <Link
                                            className="listmobilelogout"
                                            to="/"
                                            onClick={logoutHandler}
                                        >
                                            Logout
                                        </Link>
                                    </div>
                                </ListItem>
                            </div>
                        </div>
                    ) : (
                        !loading && (
                            // <Link to="/account" id="login_btn">
                            <Button
                                className="btnLoginn1"
                                variant="contained"
                                onClick={handleClickOpenSignin}
                            /*style={{
                                backgroundColor: "black",
                                color: "white",
                                marginRight: "10px",
                                marginLeft: "10px",
                                marginBottom: "10px",
                            }}*/
                            >
                                Login
                            </Button>
                        )
                    )
                    // </Link>
                }
                <Divider />
                <List style={{ marginLeft: "30px" }}>
                    <ul style={{ marginRight: "20px", position: 'relative' }}>
                        <div className="navItems" style={{ paddingBottom: '21px', paddingTop: '19px' }}>
                            <li className="font" style={{
                                cursor: 'pointer',
                                listStyleType: 'none',
                                fontSize: "20px",
                            }}
                                onClick={() => { window.location.href = "/"; }}
                            >Home</li>
                        </div>
                    </ul>
                    <h4>Products</h4>
                    <CategoryCheckboxNavbar
                        setCategoryForSub={setCategoryForSub}
                        setCategory={setCategory}
                        setSub={setSub}
                        cat={category}
                        open={open}
                        setOpen={setOpen}
                    />
                    <ul style={{ marginRight: "20px", position: 'relative' }}>
                        <div className="navItems" style={{ paddingBottom: '21px', paddingTop: '19px' }}>
                            <li className="font" style={{
                                cursor: 'pointer',
                                listStyleType: 'none',
                                fontSize: "20px",
                            }}
                                onClick={() => { window.location.href = "/contactus"; }}
                            >Contact Us</li>
                        </div>
                    </ul>
                </List>
                <Divider />
            </Drawer >
        </div >
    );
}