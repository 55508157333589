import React, { Fragment, useState, useEffect } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Plus, Minus } from "react-feather";
import MetaData from "../layout/MetaData";
import ListReviews from "../review/ListReviews";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDetails,
  newReview,
  clearErrors,
} from "../../actions/productActions";
import {
  addItemToCart,
  getUserCart,
  updateItemQuantity,
} from "../../actions/cartActions";
import { NEW_REVIEW_RESET } from "../../constants/productConstants";
import "./ProductDetail.css";
import "./style.css";
import "./floating_buttons.css";
import "./actionButton.scss";
import ProductImages from "./productImages";
import numeral from "numeral";

const ProductDetails = ({ match }) => {
  const [mainImage, isClicked] = useState(
    "url(https://www.pjpan.co.uk/media/catalog/product/cache/ddc9bd1b85fcfa71e5e91929f8504b46/l/i/linen-dressing-gown_1.jpg)"
  );
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [Quantity, setQuantities] = useState();
  const [linkToShare, setLinkToShare] = useState();
  const [sizes] = useState([
    { value: "One Size", label: "One Size" },
    { value: "XS", label: "XS" },
    { value: "S", label: "S" },
    { value: "M", label: "M" },
    { value: "L", label: "L" },
    { value: "XL", label: "XL" },
    { value: "2XL", label: "2XL" },
    { value: "3XL", label: "3XL" },
    { value: "4XL", label: "4XL" },
    { value: "5XL", label: "5XL" },
    { value: "S-M", label: "S-M" },
    { value: "L-XL", label: "L-XL" },
    { value: "NB-0 Month", label: "NB-0 Month" },
    { value: "3 Months", label: "3 Months" },
    { value: "6 Months", label: "6 Months" },
    { value: "9 Months", label: "9 Months" },
    { value: "12 Months", label: "12 Months" },
    { value: "18 Months", label: "18 Months" },
    { value: "24 Months", label: "24 Months" },
    { value: "30 Months", label: "30 Months" },
    { value: "36 Months", label: "36 Months" },
    { value: "0-3", label: "0-3" },
    { value: "3-6", label: "3-6" },
    { value: "6-9", label: "6-9" },
    { value: "9-12", label: "9-12" },
    { value: "12-18", label: "12-18" },
    { value: "18-24", label: "18-24" },
    { value: "24-30", label: "24-30" },
    { value: "30-36", label: "30-36" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "1/2", label: "1/2" },
    { value: "3/4", label: "3/4" },
    { value: "5/6", label: "5/6" },
    { value: "7/8", label: "7/8" },
    { value: "9/10", label: "9/10" },
    { value: "11/12", label: "11/12" },
    { value: "13/14", label: "13/14" },
    { value: "15/16", label: "15/16" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "Single", label: "Single" },
    { value: "Double", label: "Double" },
    { value: "King", label: "King" },
  ]);
  let ColorArr = [];
  let SizeArr = [];
  const dispatch = useDispatch();
  const alert = useAlert();
  // const [show, setShow] = useState(true);
  const { loading, error, product } = useSelector(
    (state) => state.productDetails
  );
  const [User, setUser] = useState(JSON.parse(localStorage.getItem("User")));
  const user = User ? User.user : null;
  const [userCart, setUserCart] = useState([]);
  const { error: reviewError, success } = useSelector(
    (state) => state.newReview
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchFunction = async () => {
    // window.alert('1')
    await dispatch(getProductDetails(match.params.id));
    // window.alert('2')
    setQuantity(1);
    // window.alert('3')
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Review posted successfully");
      dispatch({ type: NEW_REVIEW_RESET });
    }
    setLinkToShare(
      "https://wa.me?text=https://www.lingeriefidele.com/product/" +
        match.params.id
    );
    if (product.options) {
      for (let i = 0; i < product.options.length; i++) {
        if (
          product.options[i].size === size &&
          product.options[i].color === color
        ) {
          setQuantities(product.options[i].quantity);
          break;
        } else {
          setQuantities(0);
        }
      }
    }
    // if (show) {
    //   setTimeout(() => { setShow(false) }, 5000);
    // }
    // window.alert('4')
  };
  // useEffect(()=>{fetchFunction()}, [ alert, error, reviewError, match.params.id, success, size, color, product.options]);
  useEffect(() => {
    fetchFunction();
  }, [
    dispatch,
    alert,
    error,
    reviewError,
    match.params.id,
    success,
    size,
    color,
  ]);
  product.options &&
    product.options.map((product, index) => {
      if (product.color !== null) ColorArr.push(product.color);
    });
  product.options &&
    product.options.map((product, index) => {
      if (product.size !== null) {
        SizeArr.push(product.size);
      }
    });
  let uniqueChars = [...new Set(ColorArr)];
  let sizeChars = [...new Set(SizeArr)];
  for (let i = 0; i < sizeChars.length - 1; i++) {
    if (
      sizes.findIndex((s) => s.value === sizeChars[i]) >
      sizes.findIndex((s) => s.value === sizeChars[i + 1])
    ) {
      let temp = sizeChars[i];
      sizeChars[i] = sizeChars[i + 1];
      sizeChars[i + 1] = temp;
    }
  }

  const fCurrency = (number) => {
    return numeral(Math.trunc(number / 1000) * 1000).format(
      Number.isInteger(Math.trunc(number / 1000) * 1000) ? "0,0" : "0,0.00"
    );
  };
  const fDCurrency = (number) => {
    return numeral(number).format(
      Number.isInteger(number) ? "$0,0" : "$0,0.00"
    );
  };
  const addToCart = async () => {
    if (product.status === "OUTOFSTOCK") {
      return alert.error("Out of stock item");
    }
    let isValid = true;
    product.options.forEach((o) => {
      if (o.size === size && o.color === color) {
        if (o.statusOpt === "OUTSTOCK") {
          isValid = false;
        }
      }
    });
    if (!isValid) {
      return alert.error("Out of stock size and color");
    }
    /*if (Quantity === 0) {
      return alert.error('Out of stock item');
    }*/
    if (size === "") {
      return alert.error("Please select size");
    }
    /*if (size === '' && color === '') {
      return alert.error('Please select color and size');
    }
    else if (size === '' && color !== '') {
      return alert.error('Please select size');
    }
    else if (color === '' && size !== '') {
      return alert.error('Please select color');
    }*/
    let success = {
      success: false,
    };
    if (user) {
      const Cart = await dispatch(getUserCart(user._id));
      const data = await dispatch(getProductDetails(match.params.id));
      if (Cart.cart) {
        for (let i = 0; i < Cart.cart.cartItems.length; i++) {
          if (
            Cart.cart.cartItems[i].product === data._id &&
            Cart.cart.cartItems[i].color === color &&
            Cart.cart.cartItems[i].size === size
          ) {
            const succ = await dispatch(
              updateItemQuantity(user._id, quantity, Cart.cart.cartItems[i]._id)
            );
            if (succ.success) {
              alert.success("already exist");
              success.success = true;
              break;
            }
          }
        }
        if (success.success) {
          return;
        } else {
          if (!color || (color && color === "")) {
            dispatch(
              addItemToCart(match.params.id, quantity, size, "", Quantity)
            );
          }
          dispatch(
            addItemToCart(match.params.id, quantity, size, color, Quantity)
          );
          alert.success("Item Added to Cart");
        }
      } else {
        if (!color || (color && color === "")) {
          dispatch(
            addItemToCart(match.params.id, quantity, size, "", Quantity)
          );
        } else {
          dispatch(
            addItemToCart(match.params.id, quantity, size, color, Quantity)
          );
        }
        alert.success("Item Added to Cart");
      }
    } else {
      if (!color || (color && color === "")) {
        dispatch(addItemToCart(match.params.id, quantity, size, "", Quantity));
      }
      dispatch(addItemToCart(match.params.id, quantity, size, color, Quantity));
      alert.success("Item Added to Cart");
    }
  };
  const increaseQty = () => {
    const count = document.querySelector(".count");
    if (size === "" && color === "") {
      return alert.error("Please select color and size");
    } else if (size === "" && color !== "") {
      return alert.error("Please select size");
    } else if (color === "" && size !== "") {
      return alert.error("Please select color");
    }
    if (Quantity === undefined || Quantity === "" || Quantity === null) return;
    if (count.valueAsNumber >= Quantity) return;
    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };
  const decreaseQty = () => {
    const count = document.querySelector(".count");
    if (size === "" && color === "") {
      return alert.error("Please select color and size");
    } else if (size === "" && color !== "") {
      return alert.error("Please select size");
    } else if (color === "" && size !== "") {
      return alert.error("Please select color");
    }
    if (Quantity === undefined || Quantity === "" || Quantity === null) return;
    if (count.valueAsNumber <= 1) return;
    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
  };
  const checkAndSetQuantity = (e) => {
    if (size === "") {
      return alert.error("Please select size");
    }
    /*if (size === '' && color === '') {
      return alert.error('Please select color and size');
    }
    else if (size === '' && color !== '') {
      return alert.error('Please select size');
    }
    else if (color === '' && size !== '') {
      return alert.error('Please select color');
    }*/
    if (Quantity === undefined || Quantity === "" || Quantity === null) return;
    if (e >= Quantity) {
      setQuantity(Quantity);
      return;
    }
    if (Number.parseInt(e) === 0) {
      setQuantity(1);
      return;
    }
    setQuantity(e);
  };
  function setUserRatings() {
    const stars = document.querySelectorAll(".star");
    stars.forEach((star, index) => {
      star.starValue = index + 1;
      ["click", "mouseover", "mouseout"].forEach(function (e) {
        star.addEventListener(e, showRatings);
      });
    });
    function showRatings(e) {
      stars.forEach((star, index) => {
        if (e.type === "click") {
          if (index < this.starValue) {
            star.classList.add("orange");
            setRating(this.starValue);
          } else {
            star.classList.remove("orange");
          }
        }
        if (e.type === "mouseover") {
          if (index < this.starValue) {
            star.classList.add("yellow");
          } else {
            star.classList.remove("yellow");
          }
        }
        if (e.type === "mouseout") {
          star.classList.remove("yellow");
        }
      });
    }
  }
  const reviewHandler = () => {
    const formData = new FormData();
    formData.set("rating", rating);
    formData.set("comment", comment);
    formData.set("productId", match.params.id);
    dispatch(newReview(user, formData));
  };

  const [optPrice, setOptPrice] = useState(0);
  const [optdescription, setOptdescription] = useState("");
  const [optCode, setOptCode] = useState("");

  const contactFidele = () => {
    if (size === "" && color === "") {
      return alert.error("Please select color and size");
    } else if (size === "" && color !== "") {
      return alert.error("Please select size");
    } else if (color === "" && size !== "") {
      return alert.error("Please select color");
    }
    if (user) {
      window.open(
        `https://api.whatsapp.com/send?phone=96171566998&text=Order:%0AProductID:${product._id}%0AProduct%20name:${product.name}%0AQuantity:%0AFirst%20Name:${user.name}%0ALast%20Name%0AShipping%20Information:%0ACity:%0AStreet:%0ABuilding:%0AFloor:%0A`
      );
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=96171566998&text=Order:%0AProductID:${product._id}%0AProduct%20name:${product.name}%0AQuantity:%0AFirst%20Name:%0ALast%20Name%0AShipping%20Information:%0ACity:%0AStreet:%0ABuilding:%0AFloor:%0A`
      );
    }
  };

  const colorHandle = (e) => {
    setColor(e);
    var isPrice = false;
    var isDesc = false;
    var isOptCode = false;
    product.options.forEach((p) => {
      if (p.color === color && p.size === size) {
        if (p.oprice && p.oprice !== 0) {
          setOptPrice(p.oprice);
          isPrice = true;
        }
        if (p.optdescription && p.optdescription !== "") {
          setOptdescription(p.optdescription);
          isDesc = true;
        }
        if (p.optCode && p.optCode !== "") {
          setOptCode(p.optCode);
          isOptCode = true;
        }
      }
    });
    if (!isPrice) {
      setOptPrice(product.price);
    }
    if (!isDesc) {
      setOptdescription(product.description);
    }
    if (!isOptCode) {
      setOptCode(product.productId);
    }
    //optPrice
  };

  const sizeHandle = (e) => {
    setSize(e);
    setColor("");
    setOptPrice(0);
    setOptdescription("");
    var isPrice = false;
    var isDesc = false;
    var isOptCode = false;
    product.options.forEach((p) => {
      if (p.size === size && (!p.color || p.color === "")) {
        if (p.oprice && p.oprice !== 0) {
          setOptPrice(p.oprice);
          isPrice = true;
        }
        if (p.optdescription && p.optdescription !== "") {
          setOptdescription(p.optdescription);
          isDesc = true;
        }
        if (p.optCode && p.optCode !== "") {
          setOptCode(p.optCode);
          isOptCode = true;
        }
      }
    });
    if (!isPrice) {
      setOptPrice(product.price);
    }
    if (!isDesc) {
      setOptdescription(product.description);
    }
    if (!isOptCode) {
      setOptCode(product.productId);
    }
  };

  return (
    <div style={{ marginTop: "30px" }}>
      {/* <div style={{ zIndex: "5000" }}>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
        <div
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}>
          <OverlayTrigger
            show={show}
            placement='left'
            overlay={
              <div className="row">
                <div className="col-sm">
                  <div
                    id="button-tooltip"
                    style={{
                      backgroundColor: '#25D3662a',
                      padding: '2px 10px',
                      color: 'Black',
                      borderRadius: "16px",
                      zIndex: "500"
                    }}
                  >
                    Get A Quetsion? Contact Fidele To Ask About A Products.
                  </div>
                </div>
                <div className="col-sm-2">
                  <i class="fa fa-arrow-left" style={{ color: "black" }} variant="success"></i>
                </div>
              </div>
            }
          >
            <a href="https://api.whatsapp.com/send?phone=96171566998&text=Hello%21%20I%20would%20like%20to%20get%20more%20information%20concerning%20a%20certain%20product%20please." alt="Whatsapp button" class="float" target="_blank" rel="noreferrer">
              <i class="fa fa-whatsapp my-float" style={{ color: "white" }} variant="success"></i>
            </a>
          </OverlayTrigger>
        </div>
      </div> */}
      <Fragment>
        <MetaData title={product.name} />
        <div className="row d-flex justify-content-around">
          <div className="col-12 col-lg-5 mt-5"></div>
        </div>
        <section
          aria-label="Main content"
          role="main"
          className="product-detail"
        >
          <div>
            <div className="_cont detail-top">
              <div className="cols">
                <ProductImages product={product} match={match} />
                <div className="right-col">
                  <div className="row">
                    <div className="col-sm">
                      <h1
                        className="prodname"
                        itemProp="name"
                        style={{ fontFamily: "Roboto, sans-serif" }}
                      >
                        {product.name}
                      </h1>
                    </div>
                  </div>
                  <div className="row">
                    <div id="product_id" className="col">
                      Product #{" "}
                      {optCode && optCode !== "" ? optCode : product.productId}
                    </div>
                  </div>
                  <div
                    itemProp="offers"
                    itemScope
                    itemType="https://schema.org/Offer"
                  >
                    <meta itemProp="priceCurrency" content="LBP" />
                    <link
                      itemProp="availability"
                      href="https://schema.org/InStock"
                    />
                    <div className="price-shipping">
                      <div className="col-sm">
                        <div
                          className="row-sm"
                          style={{ fontSize: "x-large", letterSpacing: "-2px" }}
                        >
                          {optPrice && optPrice !== 0 ? (
                            product.discountRate &&
                            product.discountRate !== 0 ? (
                              <>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#808080",
                                  }}
                                >
                                  LBP {fCurrency(optPrice)}
                                </span>
                                <span style={{ marginLeft: "10px" }}>
                                  LBP{" "}
                                  {fCurrency(
                                    optPrice -
                                      (optPrice * product.discountRate) / 100
                                  )}
                                </span>
                              </>
                            ) : (
                              <>
                                <span>LBP {fCurrency(optPrice)}</span>
                              </>
                            )
                          ) : product.discountRate &&
                            product.discountRate !== 0 ? (
                            <>
                              <span
                                style={{
                                  textDecoration: "line-through",
                                  color: "#808080",
                                }}
                              >
                                LBP {fCurrency(product.price)}
                              </span>
                              <span style={{ marginLeft: "10px" }}>
                                LBP{" "}
                                {fCurrency(
                                  product.price -
                                    (product.price * product.discountRate) / 100
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>LBP {fCurrency(product.price)}</span>
                            </>
                          )}
                        </div>
                        {/* Dollar Price */}
                        {/* <div className="row-sm mt-1" style={{ fontSize: "x-large", letterSpacing: "-2px" }}>
                          {
                            product.discountRate && product.isChecked ? (
                              <>
                                <span style={{ textDecoration: "line-through", color: "#808080" }}>{fDCurrency(product.dollarPrice)}</span>
                                <span style={{ marginLeft: "10px" }}>{fDCurrency(product.dollarPrice - (product.dollarPrice * product.discountRate / 100))}</span>
                              </>
                            ) : (
                              <span>{fDCurrency(product.dollarPrice)}</span>
                            )
                          }
                        </div> */}
                      </div>
                    </div>
                    <div className="ratingui">
                      <div className="rating-outer">
                        <div
                          className="rating-inner"
                          style={{
                            width: `${(product.ratings / 5) * 100}% `,
                          }}
                        ></div>
                      </div>
                      <span id="no_of_reviews">
                        ({product.numOfReviews} Reviews)
                      </span>
                    </div>
                    <div className="swatches">
                      <div className="swatch clearfix" data-option-index="0">
                        <div className="header">Size</div>
                        {sizeChars &&
                          sizeChars.map((size) => (
                            <div
                              data-value={size}
                              onClick={(e) => sizeHandle(size)}
                              className="swatch-element plain m available"
                            >
                              <input
                                id={`${size}`}
                                type="radio"
                                name="size-1"
                                value={size}
                              />
                              <label htmlFor={`${size}`}>{size}</label>
                            </div>
                          ))}
                      </div>
                      <div className="swatch clearfix" data-option-index="1">
                        <div className="header">Color</div>
                        {size && size !== ""
                          ? product &&
                            product.options &&
                            product.options.map((p) =>
                              p.size === size ? (
                                <div
                                  data-value="Red"
                                  onClick={(e) => colorHandle(p.color)}
                                  className="swatch-element color available"
                                >
                                  <div className="tooltip">{p.color}</div>
                                  <input
                                    quickbeam="color"
                                    id={`${p.color}`}
                                    type="radio"
                                    name="option-1"
                                    value={p.color}
                                  />
                                  <label for={`${p.color}`}>
                                    {p.color && p.color !== "" ? (
                                      p.color === "White" ? (
                                        <span
                                          style={{
                                            backgroundColor: "#FFF",
                                            border: "1px solid #000",
                                          }}
                                        />
                                      ) : p.color === "Emerald Green" ? (
                                        <span
                                          style={{ backgroundColor: "#009A76" }}
                                        />
                                      ) : p.color === "Brick Red" ? (
                                        <span
                                          style={{ backgroundColor: "#9E4429" }}
                                        />
                                      ) : p.color === "Mustard Yellow" ? (
                                        <span
                                          style={{ backgroundColor: "#EAAA00" }}
                                        />
                                      ) : p.color === "Light Bois De Rose" ? (
                                        <span
                                          style={{ backgroundColor: "#CD9892" }}
                                        />
                                      ) : p.color === "Brown" ? (
                                        <span
                                          style={{ backgroundColor: "#5C4033" }}
                                        />
                                      ) : p.color === "Dark Green" ? (
                                        <span
                                          style={{ backgroundColor: "#313E34" }}
                                        />
                                      ) : p.color === "Light Navy" ? (
                                        <span
                                          style={{ backgroundColor: "#010080" }}
                                        />
                                      ) : p.color === "Neon Fuschia" ? (
                                        <span
                                          style={{ backgroundColor: "#FE4164" }}
                                        />
                                      ) : p.color === "Burgundy" ? (
                                        <span
                                          style={{ backgroundColor: "#610220" }}
                                        />
                                      ) : p.color === "Pistachio" ? (
                                        <span
                                          style={{ backgroundColor: "#BDFBBE" }}
                                        />
                                      ) : p.color === "Cherry" ? (
                                        <span
                                          style={{ backgroundColor: "#A5003D" }}
                                        />
                                      ) : p.color === "Off White" ? (
                                        <span
                                          style={{ backgroundColor: "#F8F0E3" }}
                                        />
                                      ) : p.color === "Pink" ? (
                                        <span
                                          style={{ backgroundColor: "#FEBAD3" }}
                                        />
                                      ) : p.color === "Purple" ? (
                                        <span
                                          style={{ backgroundColor: "#C99AF8" }}
                                        />
                                      ) : p.color === "Dark Purple" ? (
                                        <span
                                          style={{ backgroundColor: "#81007F" }}
                                        />
                                      ) : p.color === "Red" ? (
                                        <span
                                          style={{ backgroundColor: "#FE0500" }}
                                        />
                                      ) : p.color === "Salmon" ? (
                                        <span
                                          style={{ backgroundColor: "#FA7E72" }}
                                        />
                                      ) : p.color === "Turquoise" ? (
                                        <span
                                          style={{ backgroundColor: "#00FFEF" }}
                                        />
                                      ) : p.color === "Yellow" ? (
                                        <span
                                          style={{ backgroundColor: "#FDFC35" }}
                                        />
                                      ) : p.color === "Navy" ? (
                                        <span
                                          style={{ backgroundColor: "#1C1E47" }}
                                        />
                                      ) : p.color === "Green" ? (
                                        <span
                                          style={{ backgroundColor: "#008540" }}
                                        />
                                      ) : p.color === "Beige" ? (
                                        <span
                                          style={{ backgroundColor: "#BF9E7F" }}
                                        />
                                      ) : p.color === "Blue" ? (
                                        <span
                                          style={{ backgroundColor: "#8BCDF0" }}
                                        />
                                      ) : p.color === "Orange" ? (
                                        <span
                                          style={{ backgroundColor: "#FE6023" }}
                                        />
                                      ) : p.color === "Darkgray" ? (
                                        <span
                                          style={{ backgroundColor: "#404040" }}
                                        />
                                      ) : p.color === "Lightgray" ? (
                                        <span
                                          style={{ backgroundColor: "#B2B2B2" }}
                                        />
                                      ) : p.color === "Fuchsia" ? (
                                        <span
                                          style={{ backgroundColor: "#E60081" }}
                                        />
                                      ) : p.color === "Blue Grey" ? (
                                        <span
                                          style={{ backgroundColor: "#B5C9E1" }}
                                        />
                                      ) : p.color === "Bois De Rose" ? (
                                        <span
                                          style={{ backgroundColor: "#864650" }}
                                        />
                                      ) : p.color === "Blue Petrol" ? (
                                        <span
                                          style={{ backgroundColor: "#0F4B63" }}
                                        />
                                      ) : (
                                        <span
                                          style={{
                                            backgroundColor: `${p.color}`,
                                          }}
                                        />
                                      )
                                    ) : null}
                                  </label>
                                </div>
                              ) : null
                            )
                          : null}
                        {/* uniqueChars && uniqueChars.map((color) => (
                        <div data-value="Red" onClick={() => setColor(color)} className="swatch-element color available">
                          <div className="tooltip">{color}</div>
                          <input quickbeam="color" id={`${color}`} type="radio" name="option-1" value={color} />
                          <label for={`${color}`} >
                            {
                                  (<span style={{ backgroundColor: `${color}` }} />)
                            }
                          </label>
                        </div>
                        )) */}
                      </div>
                    </div>
                    <form id="AddToCartForm">
                      <hr /> {/*Remove this line*/}
                      {/* Status display here */}
                      {/* <p>
                        Status:{' '}
                        <span
                          id='stock_status'
                          className={Quantity === 0 ? 'greenColor' : 'redColor'}
                        >
                          {Quantity !== undefined && Quantity !== null ? (Quantity === 0 ? 'In Stock' : 'Out of Stock') : null}
                        </span>
                      </p>
                      <hr /> */}
                      {/* Quanntity display here */}
                      {/* <p>
                        Quantity:{' '}
                        <span
                          id='quantity_status'
                          className={Quantity > 0 ? 'greenColor' : 'redColor'}
                        >
                          {Quantity}
                        </span>
                      </p> */}
                      <br></br>
                      {/* <div className="btn-and-quantity-wrap">
                        <div className="btn-and-quantity">
                          <div className='stockCounter d-inline'>
                            <div className="td_item item_qty">
                              <div className="quantity-input bonjour">
                                <span className='quantity-input__modifier quantity-input__modifier--left' onClick={decreaseQty}>
                                  <Minus />   </span>
                                <input
                                  className="quantity-input__screen count"
                                  type='number'
                                  value={quantity}
                                  onChange={(e) => checkAndSetQuantity(e.target.value)} />
                                <span className='quantity-input__modifier quantity-input__modifier--left' onClick={increaseQty}>
                                  <Plus />   </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="row h-100 mt-2">
                        <button
                          type="button"
                          className="btn btn-outline-primary1 col my-auto mt-2 ml-1 mr-1"
                          onClick={addToCart}
                        >
                          <i class="fa fa fa-shopping-cart"></i> Add to Cart
                        </button>
                        {/* <button
                          type='button'
                          className="btn btn-outline-primary1 col my-auto mt-2 ml-1 mr-1"
                          onClick={contactFidele}
                        >
                          <i class="fa fa fa-id-badge"></i> Contact the shop
                        </button> */}
                        <button
                          type="button"
                          className="btn btn-outline-success1 col my-auto mt-2 ml-1 mr-1"
                          onClick={() => window.open(linkToShare)}
                        >
                          <i class="fa fa-whatsapp"></i> Share with friends
                          {/* <a href={linkToShare} rel="nofollow noopener noreferrer" target="_blank" id="share-wa">
                                </a> */}
                        </button>
                      </div>
                    </form>
                    <div className="tabs">
                      <div className="tab-labels">
                        <span data-id="1" className="active">
                          Description
                        </span>
                        {/* <span data-id="2" >{product.seller}</span>(seller) */}
                      </div>
                      <div className="tab-slides">
                        <div
                          id="tab-slide-1"
                          itemProp="description"
                          className="slide active"
                        >
                          {optdescription && optdescription !== ""
                            ? optdescription
                            : product.description}
                          {/* {product.description} */}
                        </div>
                        <div id="tab-slide-2" className="slide">
                          Fidele
                        </div>
                      </div>
                      {user ? (
                        <button
                          type="button"
                          className="btn btn-outline-primary col-sm-6 my-auto mt-1 ml-1 mr-1"
                          data-toggle="modal"
                          data-target="#ratingModal"
                          onClick={setUserRatings}
                        >
                          Submit Your Review
                        </button>
                      ) : (
                        // <button
                        //   id='review_btn'
                        //   type='button'
                        //   className='btn btn-primary mt-4'
                        //   data-toggle='modal'
                        //   data-target='#ratingModal'
                        //   onClick={setUserRatings}
                        // >
                        //   Submit Your Review
                        // </button>
                        <div className="alert alert-danger mt-5" type="alert">
                          Login to post your review.
                        </div>
                      )}
                    </div>
                    {product.reviews && product.reviews.length > 0 && (
                      <ListReviews reviews={product.reviews} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
      <div className="row mt-2 modalpadding">
        <div className="rating w-50">
          <div
            className="modal fade"
            id="ratingModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="ratingModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="ratingModalLabel">
                    Submit Review
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <ul className="stars">
                    <li className="star">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="star">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="star">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="star">
                      <i className="fa fa-star"></i>
                    </li>
                    <li className="star">
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                  <textarea
                    name="review"
                    id="review"
                    className="form-control mt-3"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                  <button
                    className="btn my-3 float-right review-btn px-4 text-white"
                    onClick={reviewHandler}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductDetails;
