export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL'
export const AUTH = 'AUTH'
export const LOGOUT = 'LOGOUT'
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAIL = 'ALL_USERS_FAIL'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const DELETE_USER_RESET = 'DELETE_USER_RESET'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'