import React, { Fragment, useState, useContext } from "react";
import {
  BoldLink1,
  BoxContainer,
  FormContainer,
  Input,
  MutedLink,
  SubmitButton,
} from "./common";
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";
import Loader from '../layout/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userActions'
import axios from "axios";
import './style.css';

const Login = () => {
  const { switchToSignup } = useContext(AccountContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false)
  };

  const [openSnack, setSnackOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);

  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.auth);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (email.trim() === '' || password.trim() === '') {
      setSuccess(undefined);
      setSnackOpen(true);
    }
    else {
      setEmail(email.toLowerCase());
      let formData = {
        email: email.toLocaleLowerCase(),
        password: password
      }
      const res = await axios.post(`https://backend.lingeriefidele.com/api/v1/login`, formData);
      if (!res.data.success) {
        setSuccess(false);
        setSnackOpen(true);
      }
      else {
        setSuccess(true);
        setSnackOpen(true);
        localStorage.setItem('User', JSON.stringify({ ...res?.data }));
        sessionStorage.removeItem("keyAnim");
        window.location.reload();
      }
      /*const data = await dispatch(login(email, password));
      if (data && data !== undefined && data !== null) {
        setSuccess(true);
        setSnackOpen(true);
        window.location.reload(false);
        //window.location.href = "/";
      }
      else {
        setSuccess(false);
        setSnackOpen(true);
      }*/
    }
  }
  const [showPassword, setShowPassword] = useState(false);
  return (

    <BoxContainer>
      {loading ? <Loader /> : (
        <Fragment>
          {/* <MetaData title={'Login'} />
          {
            message === '' ? null : <div style={{ color: 'red', zIndex: 1000 }}>{message}</div>
          } */}
          <FormContainer onSubmit={submitHandler} style={{ border: "null" }}>
            <Input
              type="email"
              id="email_field"
              className="form-control"
              style={{ marginBottom: "10px" }} value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email" />
            <div className="pass-wrapper">
              <Input
                type={showPassword ? "text" : "password"}
                id="password_field"
                className="form-control"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password" />
              {showPassword ? (
                <i class="fa fa-eye-slash" style={{ cursor: "pointer", position: "absolute", right: "10px" }}
                  onClick={() => setShowPassword(showPassword => !showPassword)}></i>
              ) : (
                <i class="fa fa-eye" style={{ cursor: "pointer", position: "absolute", right: "10px" }}
                  onClick={() => setShowPassword(showPassword => !showPassword)}></i>
              )}
            </div>
            <Marginer direction="vertical" margin={10} />
            <MutedLink href="/password/forgot">Forget your password?</MutedLink>
            <Marginer direction="vertical" margin="1.6em" />
            <SubmitButton type="submit"
            >SIGN IN</SubmitButton>
          </FormContainer>
          <Marginer direction="vertical" margin="1em" />
          <MutedLink href="#">
            Don't have an account?{" "}
            <BoldLink1 href="#" onClick={switchToSignup}>
              SIGN UP
            </BoldLink1>
          </MutedLink>
        </Fragment>
      )}
      {
        success === true ? (
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={handleSnackClose} severity="success">
              Logged In Successfully
            </Alert>
          </Snackbar>
        ) : success === false ? (
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
            <Alert onClose={handleSnackClose} severity="error">
              Invalid Email or Password
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
            <Alert onClose={handleSnackClose} severity="error">
              Please enter email & password
            </Alert>
          </Snackbar>
        )
      }
    </BoxContainer>
  );
}

export default Login