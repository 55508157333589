import React, { Fragment, useState, useEffect } from "react";
import "rc-slider/assets/index.css";
import { useDispatch } from "react-redux";
import { getProductDetails } from "../../actions/productActions";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { OverlayTrigger } from "react-bootstrap";
import Zoom from "react-reveal/Zoom";
const ProductImages = ({ product, match }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [mainImage, isClicked] = useState();
  const [show, setShow] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const data = await dispatch(getProductDetails(match.params.id));
    if (data) {
      isClicked(`${data.images[0].url}`);
    }
    setTimeout(() => {
      setShow(false);
    }, 5000);
    setShow(true);
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <>
      <div className="left-col">
        <div className="thumbs">
          {product.images &&
            product.images.map((image) => (
              <a className="thumb-image" href="#" key={image.url}>
                <span>
                  <img
                    className="thumbImage"
                    onClick={() => isClicked(`${image.url}`)}
                    src={image.url}
                    alt="thumb image Fidele prodycts"
                  />
                </span>
              </a>
            ))}
        </div>
        <div className="big">
          <div
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            style={{
              display: screenWidth < 600 ? "flex" : "",
              justifyContent: screenWidth < 600 ? "center" : "",
              alignContent: screenWidth < 600 ? "center" : "",
            }}
          >
            <OverlayTrigger
              show={show}
              placement="bottom"
              overlay={
                <div className="row mx-auto">
                  <div className="col">
                    <div
                      id="button-tooltip"
                      style={{
                        backgroundColor: "#634F3E",
                        padding: "2px 10px",
                        color: "white",
                        borderRadius: "16px",
                        zIndex: "50000",
                        fontSize: "1em",
                        fontWeight: "bold",
                        transform: "translateY(-50px)",
                      }}
                    >
                      <i
                        className="fa fa-expand"
                        style={{ color: "white" }}
                        variant="success"
                      ></i>{" "}
                      Zoom Image
                    </div>
                  </div>
                </div>
              }
            >
              <TransformWrapper id="big-image" className="img">
                <TransformComponent>
                  <Zoom>
                    <img
                      src={mainImage}
                      alt="Fidele Product"
                      style={{
                        width: screenWidth < 600 ? "80vw" : "",
                      }}
                    />{" "}
                  </Zoom>
                </TransformComponent>
              </TransformWrapper>
            </OverlayTrigger>
          </div>
          {/* <TransformWrapper id="big-image" className="img">
                        <TransformComponent>
                            <img src={mainImage} alt="test" />
                        </TransformComponent>
                    </TransformWrapper> */}
          {/* <span id="big-image" className="img" style={{ backgroundImage: mainImage }} /> */}
        </div>
      </div>
    </>
  );
};
export default ProductImages;
