import React, { Fragment, useState, useEffect } from 'react'
import 'rc-slider/assets/index.css'
import { useDispatch } from 'react-redux'
import { getCategories } from "../actions/categoryActions";
import InnerNavbar from "./innerCategoryNavbar";

const CategoryCheckNavbar = ({ setCategoryForSub, setCategory, setSub, cat, open, setOpen }) => {
    const [categories, setCategories] = useState([])
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const data = await dispatch(getCategories())
        setCategories(data)
    }, [dispatch])

    return (
        <>
            {categories !== undefined ? categories.data !== undefined ?
                categories.data.map((category,i) => (
                    <InnerNavbar key={`uniquekey${i}`} setCategoryForSub={setCategoryForSub} cat={cat} categories={category} setCategory={setCategory} open={open} setOpen={setOpen} />
                )) : null : null
            }
        </>
    )
}
export default CategoryCheckNavbar;
