import './App.css';
import { useEffect, useState } from 'react'
import HomeRalph from './components/home/HomePage';
import ProductDetails from './components/product/ProductDetails'
import Login from './components/user/Login';
import Register from './components/user/Register';
import UpdateProfile from './components/user/UpdateProfile';
import UpdatePassword from './components/user/UpdatePassword';
import ForgotPassword from './components/user/ForgotPassword';
import NewPassword from './components/user/NewPassword';
import ListOrders from './components/order/ListOrders';
import OrderDetails from './components/order/OrderDetails';
import NewProduct from './components/admin/NewProduct';
import UpdateProduct from './components/admin/UpdateProduct';
import NavbarRalph from './components/layout/Navbar/navbarRalph';
import Products from './components/Products/Products';
import ProtectedRoute from './components/route/ProtectedRoute';
import Footer from './components/Footer2/footer';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ContactUs from "./components/ContactUs/contactus";
import Favorites from "./components/Favorites/Favorites";
import ProductsSubCat from './components/Products/ProductBySubCat';
import ReactTooltip from "react-tooltip";
import { AdminDashboard } from './components/admin/AdminDashboard';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CartFidele from './components/cart/CartFideleWithooutPayment';
import './backtotop.css';

function App() {
  const [showButton, setShowButton] = useState(false);
  //const [stripeApiKey, setStripeApiKey] = useState('');
  useEffect(() => {
    /*async function getStripeApiKey() {
      const { data } = await api.PaymentStripeGet()
      setStripeApiKey(data.stripeApiKey)
    }
    getStripeApiKey();*/
    let tooltip = document.querySelectorAll(`[data-tip][data-for=registerTip]`)[0];
    ReactTooltip.show(tooltip);
    setTimeout(() => { ReactTooltip.hide(tooltip) }, 5000);
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }, true);
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: -10,
      behavior: 'smooth'
    });
  };

  return (
    <Router>
      {/* UI fidele */}
      <div className="App">

        <NavbarRalph id="main" />
        <a
          href="https://api.whatsapp.com/send?phone=96171566998&text=Hello%21%20I%20would%20like%20to%20get%20more%20information%20concerning%20a%20certain%20product%20please."
          alt="Whatsapp button"
          className="float"
          target="_blank"
          rel="noreferrer"
          data-tip
          data-for="registerTip"
        >
          <i
            className="fa fa-whatsapp my-float"
            style={{ color: "white" }}
            variant="success"
            data-tip
            data-for="registerTip"
          ></i>
        </a>
        <ReactTooltip id="registerTip" place="left" effect="float" type="success">
          Contact fidele to ask about a product.
        </ReactTooltip>
        {showButton && (
          <div className='backtotop'>
            <KeyboardArrowUpIcon sx={{ color: 'white', fontSize: '2rem' }} onClick={e => scrollToTop()} />
          </div>
          // <a class="float1" href="#" onClick="scrollToTop()">
          //   <p>
          //     <i class="fa fa-arrow-up"></i>
          //   </p>
          // </a>
        )}
        {/* <a class="float1" href="#lingerieFidele">
          <p>
            <i class="fa fa-arrow-up"></i>
          </p>
        </a> */}
        <div>
          {/* <Route path="/account" component={account} /> */}
          <Route path="/products" component={Products} />
          <Route path="/productCat/:category" component={Products} />
          <Route path="/productSubCat/:category/:subCategory" component={Products} />
          <Route path="/productSubCats/:subCategory" component={ProductsSubCat} />
          {/* <Route path="/productdescriptionui" component={ProductDescriptionPage} /> */}
          <Route path="/" component={HomeRalph} exact />
          {/* <Route path="/home" component={Home} exact /> */}
          <Route path="/search/:keyword" component={Products} />
          <Route path="/product/:id" component={ProductDetails} exact />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/password/forgot" component={ForgotPassword} />
          <Route path="/password/reset/:token" component={NewPassword} exact />
          {/* <Route path="/cart" component={Cart} /> */}
          {/* <ProtectedRoute path="/shipping" component={Shipping} /> */}
          {/* <ProtectedRoute path="/confirm" component={ConfirmOrder} exact /> */}
          {/* <ProtectedRoute path="/success" component={OrderSuccess} /> */}
          {/* {stripeApiKey &&
            <Elements stripe={loadStripe(stripeApiKey)}>
              <ProtectedRoute path="/payment" component={Payment} />
            </Elements>
          } */}
          {/* <ProtectedRoute path="/me" component={Profile} exact /> */}
          <ProtectedRoute path="/me/update" component={UpdateProfile} exact />
          <ProtectedRoute path="/password/update" component={UpdatePassword} exact />
          <ProtectedRoute path="/orders/me" component={ListOrders} exact />
          <ProtectedRoute path="/order/:id" component={OrderDetails} exact />
        </div>
        <div style={{ marginTop: '75px' }}>
          <ProtectedRoute path="/admin/dashboard" isAdmin={true} component={AdminDashboard} exact />
          <ProtectedRoute path="/admin/product" isAdmin={true} component={NewProduct} exact />
          <ProtectedRoute path="/admin/product/:id" isAdmin={true} component={UpdateProduct} exact />
          {/* <ProtectedRoute path="/admin/products" isAdmin={true} component={ProductsList} exact />
          <ProtectedRoute path="/admin/users" isAdmin={true} component={UsersList} exact />
          <ProtectedRoute path="/admin/reviews" isAdmin={true} component={ProductReviews} exact /> */}
          {/* <ProtectedRoute path="/admin/orders" isAdmin={true} component={OrdersList} exact />
          {/* <ProtectedRoute path="/admin/order/:id" isAdmin={true} component={ProcessOrder} exact /> 
          <ProtectedRoute path="/admin/categories" isAdmin={true} component={Categories} exact />
          <ProtectedRoute path="/admin/AllCategories" isAdmin={true} component={CategoriesList} exact />*/}
        </div>
      </div>
      <Route path="/cartFidele" component={CartFidele} />
      <Route path="/contactus" component={ContactUs} />
      <Route path="/Favorites" component={Favorites} />
      <Footer />
    </Router>
  );
}
export default App;
