import { makeStyles } from '@material-ui/core/styles';

const UseStyles = makeStyles(() => ({
    categoryTitle: {
        marginTop: "60px",
        marginBottom: "20px",
        fontFamily: 'Dancing Script, cursive',
        fontSize: "80px"
    },
    sectionsIntro: {
        // marginTop: "0px",
        marginBottom: "30px",
        fontFamily: 'Dancing Script, cursive',
        fontSize: '3.75rem',
        textAlign: "left"
    },
    // imagesMen: {
    //     width: "100%",
    //     maxWidth: "400px",
    //     // marginLeft: "130px"
    // },
    // imagesKid: {
    //     width: "100%",
    //     maxWidth: "400px",
    // },
    // imgs: {
    //     marginTop: "40px",
    //     // marginLeft: "60px",
    //     // marginRight: "auto"
    // },
    newsletter: {
        backgroundColor: "#C9E4E7",
        height: "250px",
        marginTop: "60px",
        marginBottom: "50px"
    },
    newsltr: {
        marginTop: "40px",
        fontFamily: 'Dancing Script, cursive',
        fontSize: '3.75rem',
    },
    emailadd: {
        marginTop: "115px"
    },
    email: {
        marginTop: "105px"
    },
    sectionBtns: {
        background: "rgba(255,241,230, 0.5)",
        width: "400px",
        marginTop: "-20%",
        borderRadius: 0,
    },
    sectionBtnsMen: {
        // position:'absolute',
        background: "rgba(255,241,230, 0.5)",
        // width: "400px",
        width: "100%",
        marginTop: "-20%",
        borderRadius: 0,
        // marginLeft: "130px"
    },
    sectionBtnsKid: {
        background: "rgba(255,241,230, 0.5)",
        width: "400px",
        marginTop: "-20%",
        borderRadius: 0,
        marginRight: "160px",
        marginLeft: "-20px"
    },
    // cat1: {
    //     // transform: "translateY(-500px)",
    //     fontFamily: "Freestyle Script",
    //     color: "white",
    //     // marginLeft: "500px"
    //     margin:"0"

    // },
    cat2: {
        transform: "translateY(-250px)",
        fontFamily: 'Dancing Script, cursive',
        color: "white",
        marginLeft: "1150px"

    },
    cat3: {
        transform: "translateY(-250px)",
        fontFamily: 'Dancing Script, cursive',
        color: "white",
        marginLeft: "1110px"

    },
    buttons: {
        // marginTop: "-150px",
        alignItems: "center",
        borderRadius: "0px",
        width: "200px",
        height: "40px",
        fontFamily: "Montserrat",
        backgroundColor: "black",
        color: "#FFF1E6"
    },
    fewCategories: {
        marginTop: "80px",
        marginBottom: "-420px"
    },
    text: {
        marginTop: "30%",
        marginLeft: "50px",
        marginRight: "90px"
    },
    fewcat1: {
        width: "900px",
        height: "700px"
    },
    imgCat2: {
        marginLeft: "50px"
    },
    imgCat3: {
        marginLeft: "50px",
        marginTop: "40px"
    },
    darkCat1: {
        width: "900px",
        height: "700px",
        color: "black",
        backgroundColor: "black",
        marginTop: "-700px",
        opacity: "37%",
        marginLeft: "128px"
    },
    darkCat2: {
        width: "330px",
        height: "330px",
        color: "black",
        backgroundColor: "black",
        marginTop: "-812px",
        opacity: "37%",
        marginLeft: "1062px"
    },
    darkCat3: {
        width: "330px",
        height: "330px",
        color: "black",
        backgroundColor: "black",
        marginTop: "-32px",
        opacity: "37%",
        marginLeft: "1062px"
    },
    instapic1: {
        marginLeft: "125px"
    },
    instapic2: {
        marginLeft: "55px"
    },
    instapic3: {
        marginRight: "55px"
    },
    instapic4: {
        marginRight: "120px",
        marginLeft: "-50px"
    },
    inputemail: {
        fontSize: "20px",
        width: "100%"
    },
    images: {
        width: "400px"
    },
    // instagramWeb: {
    //     display: "block"
    // },
    instagramMobile: {
        display: "none"
    },
    instaStories: {
        display: "none"
    },

    '@media (max-width: 1000px)': {
        newsltr: {
            fontSize: "55px",

        },
        sectionsIntro: {
            fontSize: "50px",
            marginTop: "20px",
            marginBottom: "0px"
        },
    },

    '@media (max-width: 600px)': {
        newsltr: {
            fontSize: "40px",

        },
        email: {
            marginTop: "0px",
        },
        emailadd: {
            marginTop: "0px"
        },
        inputemail: {
            width: '400px'
        },
        sectionsIntro: {
            fontSize: "50px",
            marginTop: "20px",
            marginBottom: "0px"
        },
    },

    '@media (max-width: 376px)': {

        instaStories: {
            display: "block"
        },
        menWomenKid: {
            //   marginLeft: "20px",
            //   width: "320px",
            marginBottom: "20px"
        },
        text: {
            marginTop: "-1px",
            marginLeft: "20px",
            marginRight: "15px"
        },
        categoryTitle: {
            fontSize: "30px !important",
            textAlign: "center",
            marginTop: "0px"
        },
        imagesMen: {
            marginLeft: "0px",
            width: "90%"
        },
        sectionBtnsMen: {
            marginLeft: "0px",
            marginTop: "-150px",
            width: "90%"
        },
        sectionBtns: {
            marginTop: "-150px",
            width: "90%"
        },
        sectionBtnsKid: {
            marginTop: "-150px",
            marginRight: "0px",
            marginLeft: "0px",
            width: "90%",

        },
        imagesKid: {
            marginRight: "0px",
            marginLeft: "0",
            width: "90%"
        },
        fewcat1: {
            width: "330px",
            height: "330px",
            marginLeft: "25px"
        },
        imgCat2: {
            marginLeft: "25px",
            marginTop: "30px"
        },
        imgCat3: {
            marginLeft: "25px",
            marginTop: "60px",
            marginBottom: "-150px"
        },
        darkCat1: {
            width: "330px",
            height: "330px",
            marginLeft: "25px",
            transform: "translateY(-290px)"
        },
        darkCat2: {
            marginTop: "-342px",
            marginLeft: "25px"
        },
        darkCat3: {
            marginLeft: "25px",
            marginTop: "-42px"
        },
        cat1: {
            marginLeft: "110px",
            transform: "translateY(-550px)",
            fontSize: "70px"
        },
        cat2: {
            marginLeft: "110px"
        },
        cat3: {
            marginLeft: "80px",
            marginBottom: "50px"
        },
        sectionsIntro: {
            fontSize: "40px",
            marginTop: "20px",
            marginBottom: "0px"
        },
        instapic1: {
            marginLeft: "0px",
            marginTop: "30px"
        },
        instapic2: {
            marginLeft: "0px",
            marginTop: "30px"
        },
        instapic3: {
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "30px"
        },
        instapic4: {
            marginLeft: "0px",
            marginRight: "0px",
            marginTop: "30px"
        },
        newsltr: {
            fontSize: "50px",
        },
        newsletter: {
            height: "350px"
        },
        email: {
            marginTop: "-30px"
        },
        emailadd: {
            marginTop: "0px"
        },
        inputemail: {
            width: "80%"
        },
        images: {
            width: "90%"
        },
        instagramWeb: {
            display: "none"
        },
        instagramMobile: {
            display: "block",
            marginTop: "30px",
            marginBottom: "-60px"
        },
        luxury: {
            marginTop: "40px"
        }

    },
}))

export default UseStyles;