import React, { useRef, useState, Fragment, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./styles.css";
import bra11 from "../../images/bra11.jpg";
import bra12 from "../../images/bra12.jpg";
import robe1 from "../../images/robe1.jpg";
import bikinibottom from "../../images/bikinibottom.jpg";
import UseStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FavoriteIcon from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
////////////////////////////////////////////////////
import Button from "@material-ui/core/Button";
import "rc-slider/assets/index.css";
import MetaData from "../layout/MetaData";
import Pagination from "react-js-pagination";
import Product from "../product/Product";
import Loader from "../layout/Loader";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  getProducts,
  getHomePageProducts,
  addToWishlist,
  deleteWish,
  getWishlist,
} from "../../actions/productActions";
import { useAlert } from "react-alert";
import Posts from "../Posts/Posts";
import { useDispatch, useSelector } from "react-redux";
// import { getProducts } from '../actions/productActions'
// import { useAlert } from 'react-alert'
import {
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Home from "../Home";
///////////////////////////////////////////////////
import DialogContent from "@material-ui/core/DialogContent";
import { AccountBox } from "../accountBox";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import numeral from "numeral";
// install Swiper modules
SwiperCore.use([Navigation]);
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
export default function SwiperComponent({ product }) {
  const [openSignin, setOpenSignin] = React.useState(false);
  const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
  const handleCloseSignin = () => {
    setOpenSignin(false);
  };
  const dispatch = useDispatch();
  const [User, setUser] = useState(JSON.parse(localStorage.getItem("User")));
  const user = User ? User.user : null;
  const [latestProducts, setLatestProducts] = useState([]);
  const [buttonFavoriteIsClicked, isFavoriteClicked] = useState(false);
  //const [buttonFavoriteIsClicked1, isFavoriteClicked1] = useState(true);
  //const [wishlist, setWishlist] = useState([]);
  const clickedFavoriteBtn = (idPost) => {
    const id = user._id;
    const formData = {
      product: idPost,
    };
    isFavoriteClicked(true);
    if (id !== null) {
      dispatch(addToWishlist(id, formData));
    }
  };
  const unClickedFavoriteBtn = (idPost) => {
    const userId = user._id;
    // dispatch(getWishlist(userId));
    isFavoriteClicked(false);
    if (userId !== null) {
      dispatch(deleteWish(userId, idPost));
    }
  };
  const fCurrency = (number) => {
    return numeral(Math.trunc(number / 1000) * 1000).format(
      Number.isInteger(Math.trunc(number / 1000) * 1000) ? "0,0" : "0,0.00"
    );
  };
  /*const clickedFavoriteBtn1 = (idPost) => {
        const id = user._id;
        const formData = {
            product: idPost,
        };
        isFavoriteClicked1(true);
        if (id !== null) {
            dispatch(addToWishlist(id, formData));
        }
    };
    const unClickedFavoriteBtn1 = (idPost) => {
        const userId = user._id;
        // dispatch(getWishlist(userId));
        isFavoriteClicked1(false);
        if (userId !== null) {
            dispatch(deleteWish(userId, idPost));
        }
    };*/
  // const alert = useAlert();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const data = await dispatch(getHomePageProducts());
    /*if (user !== null && user !== undefined) {
            const id = user._id;
            const wishlist = await dispatch(getWishlist(id));
            setWishlist(wishlist);
        }*/
    setLatestProducts(data);
  }, [dispatch]);
  const classes = UseStyles();
  return (
    <>
      {user !== null && user !== undefined ? (
        <Grid item className={classes.cards_Hover}>
          {" "}
          <div>
            <a href={`/product/${product._id}`}>
              <img
                className="imageWidth"
                style={{
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                }}
                src={product.images.length > 0 ? product.images[0].url : null}
              />
              <Typography className={classes.itemName}>
                {product.name.length > 15
                  ? product.name.substring(0, 15)
                  : product.name}
              </Typography>
              <Typography className={classes.itemDesc}>
                {product.category}
              </Typography>
              <Typography className={classes.itemPrice}>
                LBP {fCurrency(product.price)}
              </Typography>
              <Link to={`/product/${product._id}`}>
                <div style={{ position: "relative", width: 0, height: 0 }}>
                  <button className={classes.viewDetailsBTN}>
                    View details
                  </button>
                </div>
              </Link>
            </a>
            {buttonFavoriteIsClicked ? (
              <IconButton
                aria-label="add to favorites"
                onClick={() => unClickedFavoriteBtn(product._id)}
                className={classes.iconHoverClicked}
              >
                <FavoriteIcon />
              </IconButton>
            ) : (
              <IconButton
                aria-label="add to favorites"
                className={classes.iconHover}
                onClick={() => clickedFavoriteBtn(product._id)}
              >
                <FavoriteIcon />
              </IconButton>
            )}
          </div>
        </Grid>
      ) : (
        <Grid item className={classes.cards_Hover}>
          <div>
            <a href={`/product/${product._id}`}>
              <img
                className="imageWidth"
                style={{
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                  widows: "200px",
                }}
                src={product.images.length > 0 ? product.images[0].url : null}
              />
              <Typography className={classes.itemName}>
                {product.name.length > 15
                  ? product.name.substring(0, 15)
                  : product.name}
              </Typography>
              <Typography className={classes.itemDesc}>
                {product.category}
              </Typography>
              <Typography className={classes.itemPrice}>
                LBP {fCurrency(product.price)}
              </Typography>
              <Link to={`/product/${product._id}`}>
                <div style={{ position: "relative", width: 0, height: 0 }}>
                  <button className={classes.viewDetailsBTN}>
                    View details
                  </button>
                </div>
              </Link>{" "}
            </a>
            <IconButton
              aria-label="add to favorites"
              className={classes.iconHover}
              onClick={() => setOpenSignin(true)}
            >
              <FavoriteIcon />
            </IconButton>
          </div>
        </Grid>
      )}
      <Dialog
        color="black"
        open={openSignin}
        keepMounted
        onClose={handleCloseSignin}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className="dialogheart" id="alert-dialog-slide-title">
          {"Add To Favorites?"}
        </DialogTitle>
        <DialogContent className="dialogheart">
          <DialogContentText id="alert-dialog-slide-description">
            <div style={{ padding: 20, color: "black" }}>
              {" "}
              Please Login to your account to enable add to favorites button
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialogheart">
          <Button onClick={handleCloseSignin} color="black">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
