import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LogoWhite from './LogoWhite'
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import './style.css'

const Footer = () => {
    return (
        <Box mt={{ xs: 5, sm: 10 }} px={{ xs: 3, sm: 10 }} py={{ xs: 3, sm: 10 }} bgcolor="#E7F2F7" color="black" style={{ padding: 0, bottom: 0 }}>
            <Grid container direction="row" xs={12} sm={12} md={12} justifyContent="center" alignItems="center" style={{ border: "0px solid red" }}>
                <Grid container direction="row" xs={12} sm={12} md={12} display="flex" justifyContent="center" alignItems="center" style={{ padding: 25, marginTop: 12, marginBottom: 12 }}>
                    <Grid container item xs={12} sm={12} md={4} direction="column" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid red" }} >
                        <LogoWhite />
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Grid item xs={12} sm={12} md={4} direction="column" display="flex" justifyContent="center" alignItems="center"  >
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, border: "0px solid red", textAlign: "center" }}   >
                                <Link to="/" className='Link'>
                                    Lingerie Fidele
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center" }}>
                                <Link to="/products" className='Link'>
                                    Products
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center" }}>
                                <Link to="/contactus" className='Link'>
                                    Contact Us
                                </Link>
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Hidden only={['xs', 'md', 'lg', 'xl']}>
                        <Grid container item xs={12} sm={12} md={4} direction="column" display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "20px", marginBottom: "10px" }} >
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, border: "0px solid red", textAlign: "center" }}   >
                                <Link to="/" className='Link'>
                                    Lingerie Fidele
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center" }}>
                                <Link to="/products" className='Link'>
                                    Products
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center" }}>
                                <Link to="/contactus" className='Link'>
                                    Contact Us
                                </Link>
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Grid container item xs={12} sm={12} md={4} direction="column" display="flex" justifyContent="center" alignItems="center" style={{ marginTop: "20px", marginBottom: "10px" }} >
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, border: "0px solid red", textAlign: "center" }}   >
                                <Link to="/" className='Link'>
                                    Lingerie Fidele
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center" }}>
                                <Link to="/products" className='Link'>
                                    Products
                                </Link>
                            </Typography>
                            <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center" }}>
                                <Link to="/contactus" className='Link'>
                                    Contact Us
                                </Link>
                            </Typography>
                        </Grid>
                    </Hidden>
                    <Grid container item xs={12} sm={12} md={4} direction="column" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid blue" }}  >
                        <Typography className='bodyHover' variant="body1" style={{ padding: 10, textAlign: "center", paddingBottom: 5 }}>
                            Follow us
                        </Typography>
                        <div sx={{ mb: 0, mt: 0 }} direction="row" display="flex" justifyContent="center" spacing={2} style={{ padding: 10, textAlign: "center" }}>
                            <a target="_blank" href="https://www.facebook.com/fidelelingeriie" rel="noreferrer" >
                                <Box
                                    component="img"
                                    src="/images/facebook.svg"
                                    style={{ transition: "all 2s ease", marginRight: "3px" }}
                                    onMouseEnter={e => (e.currentTarget.src = "/images/facebook_blue.svg")}
                                    onMouseLeave={e => (e.currentTarget.src = "/images/facebook.svg")}
                                    sx={{
                                        height: 30, mx: 'auto',
                                    }}
                                    xs={{ height: 50 }}
                                /> </a>
                            <a target="_blank" href="https://www.instagram.com/lingerie_fidele" rel="noreferrer" >
                                <Box
                                    component="img"
                                    src="/images/instagram.svg"
                                    onMouseEnter={e => (e.currentTarget.src = "/images/instagram_hover.svg")}
                                    onMouseLeave={e => (e.currentTarget.src = "/images/instagram.svg")}
                                    style={{ transition: "all 2s ease", marginLeft: "3px" }}
                                    sx={{ height: 30, mx: 'auto' }}
                                    xs={{ height: 50 }}
                                />  </a>
                        </div >
                    </Grid>
                </Grid>
                <Grid container direction="row" md={12} display="flex" justifyContent="center" alignItems="center" style={{ padding: 5, margin: 0, }}>
                    <Grid container item sm={4} md={4} direction="column" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid green" }} >
                    </Grid>
                    <Hidden only={['xs', 'md', 'lg', 'xl']}>
                        <Grid item xs={12} sm={12} md={4} direction="row" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid green", marginBottom: "5px" }} >
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                <Typography style={{ color: "black", fontSize: "15px", width: "188px", border: "0px solid red" }}>Designed and Developed by </Typography>
                                <a target="_blank" href="http://btekup.com" style={{ border: "0px solid red", display: "flex", justifyContent: "center", alignItems: "center", width: "80px" }} rel="noreferrer">
                                    <Box
                                        component="img"
                                        src="/images/btekup.svg"
                                        sx={{
                                            height: 17, mx: 'auto',
                                        }}
                                        xs={{ height: 50 }}
                                    /> </a>
                            </div >
                        </Grid>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Grid container item xs={12} sm={12} md={4} direction="row" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid green", marginBottom: "5px" }} >
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", border: "0px solid red" }}>
                                <Typography style={{ color: "black", fontSize: "15px", width: "190px", border: "0px solid red" }}>Designed and Developed by </Typography>
                                <a target="_blank" href="http://btekup.com" style={{ border: "0px solid red", display: "flex", justifyContent: "center", alignItems: "center", width: "78px" }} rel="noreferrer">
                                    <Box
                                        component="img"
                                        src="/images/btekup.svg"
                                        sx={{
                                            height: 17, mx: 'auto',
                                        }}
                                        xs={{ height: 50 }}
                                    /> </a>
                            </div >
                        </Grid>
                    </Hidden>
                    <Grid container item xs={12} sm={12} md={4} direction="row" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid green" }} >
                        <Typography style={{ paddingTop: 0, textAlign: "center", fontSize: "15px" }} textAlign="center" >lingeriefidele.com ©  {new Date().getFullYear()}</Typography>
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Grid container item xs={12} sm={12} md={4} direction="row" display="flex" justifyContent="center" alignItems="center" style={{ border: "0px solid green" }} >
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography style={{ color: "black", fontSize: "15px", width: "188px", border: "0px solid red" }}>Designed and Developed by </Typography>
                                <a target="_blank" href="http://btekup.com" style={{ border: "0px solid red", display: "flex", justifyContent: "center", alignItems: "center", width: "80px" }} rel="noreferrer">
                                    <Box
                                        component="img"
                                        src="/images/btekup.svg"
                                        sx={{
                                            height: 17, mx: 'auto',
                                        }}
                                        xs={{ height: 50 }}
                                    /> </a>
                            </div >
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </Box>
    );
};
export default Footer