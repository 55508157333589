import { makeStyles } from "@material-ui/core/styles";
// import '../../../node_modules/open-sans-fonts/open-sans.scss';
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    filter: {
        [theme.breakpoints.down("xs")]: {
            // display:'none',
        },
        [theme.breakpoints.up("lg")]: {
            marginBottom: 15,
        }
    }, filters: {
        [theme.breakpoints.down("xs")]: {
            marginTop: 50,
            marginLeft: '9px'
        },
        [theme.breakpoints.up("lg")]: {
            display: 'none'
        }
    },
    subFilters: {
        fontFamily: 'Open Sans',
    },
    root: {
        marginTop: theme.spacing(3),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    heading: {
        // fontFamily: 'Roboto sans-serif ',
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
    },
    subheading: {
        // fontFamily: 'Roboto sans-serif ',
        fontSize: theme.typography.pxToRem(10),
        fontWeight: theme.typography.fontWeightRegular,
    },
    radiored: {
        color: '#621940',
    },
    radioyellow: {
        color: 'yellow',
    },
    radioblack: {
        color: 'black',
        marginLeft: 15
    },
    radiogrey: {
        color: 'grey',
        marginLeft: 15
    },
    Color1: {
        display: "table-column",
        marginTop: "-20px"
    },
    Color2: {
        display: "table-column",
        position: 'absolute',
        marginTop: '10px'
    },
    cart: {
        [theme.breakpoints.up("lg")]: {
            fontFamily: 'Open Sans Light',
            fontStyle: 'italic',
            marginTop: '1em',
            marginBottom: '2.5em',
        },
        [theme.breakpoints.down("md")]: {
            display: 'none'
        }, [theme.breakpoints.down("xs")]: {
            display: 'none'
        },
    },
    Cart: {
        [theme.breakpoints.down("md")]: {
            display: 'none'
        }, [theme.breakpoints.down("xs")]: {
            display: 'none'
        },
    },
    shoppingBag: {
        [theme.breakpoints.up("lg")]: {
            position: 'absolute',
            marginLeft: '4em',
        }, [theme.breakpoints.down("md")]: {
            display: 'none'
        },
    },
    productContainer: {
        [theme.breakpoints.down("xs")]: {
            marginLeft: '0em'
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: '3.8em'
        }
    },
    results: {
        [theme.breakpoints.down("xs")]: {
            display: 'none',
        },
        [theme.breakpoints.down("md")]: {
            marginLeft: '2.1em',
            marginTop: '0.9em',
            fontFamily: 'Open Sans Light',
            paddingBottom: '20px',
            display: 'none',

        },
        [theme.breakpoints.up("lg")]: {
            position: 'absolute',
            marginLeft: '19.8em',
            marginTop: '0.9em',
            fontFamily: 'Open Sans Light'
        }
    }
}));

export default useStyles