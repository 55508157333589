import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword, clearErrors } from '../../actions/userActions'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const alert = useAlert();
    const dispatch = useDispatch();
    const { error, loading, message } = useSelector(state => state.forgotPassword)
    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, alert, error, message])
    const submitHandler = async (e) => {
        e.preventDefault();
        if (email === '') {
            alert.error('Fill the email field');
            return;
        }
        const formData = new FormData();
        formData.set('email', email);
        const data = await dispatch(forgotPassword(formData))
        if (data.success === false) alert.error(data.message);
        if (data.success === true) alert.success(data.message);
    }
    return (
        <Fragment>
            <MetaData title={'Forgot Password'} />
            <div className="row wrapper" style={{ marginTop: '150px' }} >
                <div className="col-10 col-lg-5">
                    <form className="shadow-lg" onSubmit={submitHandler}>
                        {/* <h1 className="mb-3">Forgot Password</h1> */}
                        <Typography variant="h4" className="titlesmobile">
                            Forgot Password
                        </Typography>
                        <div className="form-group">
                            <label
                                className="typo"
                                htmlFor="email_field">Enter Email</label>
                            <input
                                type="email"
                                id="email_field"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {/* <button
                            id="forgot_password_button"
                            type="submit"
                            className="btn btn-block py-3"
                            disabled={loading ? true : false} >
                            Send Email
                    </button> */}
                        <Button
                            type="submit"
                            disabled={loading ? true : false}
                            variant="contained" className="btn btn-block py-3" style={{ backgroundColor: 'black', color: 'white' }} >Send Email</Button>
                    </form>
                </div>
            </div>
            <div style={{ marginBottom: '125px' }}></div>
        </Fragment>
    )
}
export default ForgotPassword
