import axios from 'axios';
import {
  ADD_TO_CART,
  REMOVE_ITEM_CART,
  SAVE_SHIPPING_INFO,
  EMPTY_CART,
} from '../constants/cartConstants';
import * as api from '../api/index.js';

export const addItemToCart = (id, quantity, size, color, Quantity, dollarPrice) => async (dispatch, getState) => {
  try {
    const { data } = await api.addItemToCart(id)
    let optCodee = ''
    let price = data.product.price;
    if (color && color !== '') {if (!color || (color && color === '')) {
    }
      let opt = data.product.options.find(o => (o.size === size && o.color === color));
      if (opt.oprice && opt.oprice !== 0) {
        price = opt.oprice;
      }
      if (opt.optCode && opt.optCode !== '') {
        optCodee = opt.optCode
      }
    }
    dispatch({
      type: ADD_TO_CART,
      payload: {
        product: data.product._id,
        productId: data.product.productId,
        optCode: optCodee,
        name: data.product.name,
        price: price,
        dollarPrice: data.product.dollarPrice,
        image: data.product.images[0].url,
        stock: data.product.stock,
        discountRate: data.product.discountRate,
        isChecked: data.product.isChecked,
        quantity,
        size,
        color,
        Quantity
      },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  } catch (err) {
  }
};
export const removeItemFromCart = (id, color) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REMOVE_ITEM_CART,
      payload: { id, color },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
  } catch (err) {
  }
};
export const saveShippingInfo = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_SHIPPING_INFO,
      payload: data,
    });
    localStorage.setItem('shippingInfo', JSON.stringify(data));
  } catch (err) {
  }
};
export const emptyCart = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPTY_CART,
    });
    localStorage.removeItem('cartItems');
  } catch (err) {
  }
};
export const saveCart = (cartData) => async (dispatch) => {
  try {
    const { data } = await api.saveCart(cartData);
    return data;
  } catch (error) {
  }
}
export const getUserCart = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    const { data } = await api.getUserCart(id);
    return data;
  } catch (error) {
  }
}
export const removeItemCart = (cartData) => async (dispatch) => {
  try {
    const { data } = await api.removeItemCart(cartData)
    return data;
  } catch (error) {
  }
}
export const updateItemQuantity = (user, quantity, cartID) => async (dispatch) => {
  try {
    const cartData = {
      id: user,
      cartId: cartID,
      quantity: quantity
    }
    const { data } = await api.updateItemQuantity(cartData)
    return data;
  } catch (error) {
  }
}
export const deleteCart = (cartID) => async (dispatch) => {
  try {
    const { data } = await api.deleteCart({ cartID })
    return data;
  } catch (error) {
  }
}
