import axios from 'axios';
const API = axios.create({ baseURL: 'https://backend.lingeriefidele.com' });

API.interceptors.request.use((req) => {
  if (localStorage.getItem('User')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('User')).token}`;
  }

  return req;
});
export const contactUs = (formData) => API.post('/api/v1/contactUs', formData);
export const addItemToCart = (id) => API.get(`/api/v1/products/${id}`);
export const saveCart = (cartData) => API.post('/api/v1/cart/new', cartData);
export const getUserCart = (id) => API.get(`/api/v1/cart/${id}`)
export const updateItemQuantity = (cartData) => API.patch(`/api/v1/updateQuantity`, cartData)
export const removeItemCart = (cartData) => API.patch(`/api/v1/RemoveCart`, cartData)
export const deleteCart = ({ cartID }) => API.post(`/api/v1/deleteCart`, { cartID });
export const createCategory = (category) => API.post(`/api/v1/admin/category/new`, category);
export const getCategories = () => API.get(`/api/v1/categories`);
export const deleteCategory = (id) => API.delete(`/api/v1/admin/categories/${id}`);
export const getSubCategories = ({ name }) => API.put(`/api/v1/subcategories`, { name });
export const crOrder = (order, user) => API.post(`/api/v1/order/new/${user._id}`, order);
// export const myOrders= ()=>API.get('/api/v1/orders/me')
export const getOrderDetails = (id) => API.get(`/api/v1/order/${id}`);
export const getMyOrders = (id) => API.get(`/api/v1/orders/me/${id}`);
export const allOrders = () => API.get(`/api/v1/admin/orders`);
export const updateOrder = (id, orderData) => API.put(`/api/v1/admin/order/${id}`, orderData);
export const deleteOrder = (id) => API.delete(`/api/v1/admin/order/${id}`)
export const updateQuantityProduct = (orderItems) => API.patch(`/api/v1/admin/orders/updateProducts`, orderItems)
export const delOption = (optionId, optionID) => API.patch(`/api/v1/admin/productOption/${optionId._id}`, optionID)

//fi get products abel
export const getAllColor = () => API.get('/api/v1/getAllProduct')
export const getProduct = (link) => API.get(link)
export const newProduct = (productData, id) => API.post(`/api/v1/admin/product/new/${id}`, productData)
export const getHomePageProducts = () => API.get(`/api/v1/homePageProducts`)
// export const newProduct=(productData)=>API.post(`/api/v1/admin/product/new`, productData)
export const getProductDetails = (id) => API.get(`/api/v1/products/${id}`)
export const deleteProduct = (id) => API.delete(`/api/v1/admin/products/${id}`)
export const updateProduct = (id, productData) => API.put(`/api/v1/admin/products/${id}`, productData)
// export const newReview=(reviewData)=>API.put(`/api/v1/review`, reviewData)
export const getAdminProducts = () => API.get(`/api/v1/admin/products`)
export const PaymentStripe = (paymentData) => API.post('/api/v1/payment/process', paymentData)
export const PaymentDollarStripe = (paymentData) => API.post('/api/v1/payment/processdollar', paymentData)

export const PaymentStripeGet = () => API.get('/api/v1/stripeapi')

export const getProductReviews = (id) => API.get(`/api/v1/reviews?id=${id}`)
export const nReview = (id, name, reviewData) => API.put(`/api/v1/review/${id}/${name}`, reviewData)
export const deleteReview = (id, productId) => API.delete(`/api/v1/reviews?id=${id}&productId=${productId}`)
export const AddToWishlist = (id, formData) => API.post(`/api/v1/favorite/${id}`, formData)
export const delWishlist = (userId, postId) => API.delete(`/api/v1/favorite/${userId}/${postId}`)
export const gWishlist = (id) => API.get(`/api/v1/favorite/${id}`)
//delete option?
export const Login = (email, password) => API.post('/api/v1/login', { email, password })
export const Register = (userData) => API.post('/api/v1/register', userData) //THIS ONE IS WORKING BAS MA3AM TKEBNE LOGIN MAZBOUTA
// export const loUser= (user) =>API.get(`/api/v1/me/${user._id}`)
export const upProfile = (userData, user) => API.put(`/api/v1/me/update/${user._id}`, userData)
export const upPassword = (passwords, user) => API.put(`/api/v1/password/update/${user._id}`, passwords)
export const forPassword = (email) => API.post('/api/v1/password/forgot', email)
export const resPassword = (token, passwords) => API.put(`/api/v1/password/reset/${token}`, passwords)
// export const Logout= ()=>API.get('/api/v1/logout')
export const allUsers = () => API.get('/api/v1/admin/users')
export const deleteUser = (id) => API.delete(`/api/v1/admin/user/${id}`)

//DOLLAR RATE
export const getDollarRate = () => API.get('/api/v1/admin/dollarRate')
export const inserUpdateDollarRate = (value) => API.post(`/api/v1/admin/dollarRate`, value)
export const inserUpdateDeliveryRate = (value) => API.post(`/api/v1/admin/deliveryRate`, value)
export const UpdateAllProductPrice = (value) => API.post(`/api/v1/admin/updateAllPrices`, value)