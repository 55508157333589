import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import { Sidebar } from './Sidebar'
import './style.css'
import axios from 'axios'
import { getAdminProducts, getDollarRate, inserUpdateDollarRate, insertOrUpdateDeliveryRate } from '../../actions/productActions'
import { useDispatch, useSelector } from 'react-redux'
import { allOrders } from "../../actions/orderActions";
import { allUsers } from "../../actions/userActions";
import OrderTotalPrice from './orderTotalPrice'
import './dashborad.css';
import numeral from 'numeral';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { products } = useSelector(state => state.products)
    const { orders } = useSelector(state => state.allOrders)
    const { users } = useSelector(state => state.allUsers);
    const [dolarRate, setDollarRate] = useState(0);
    const [deliveryRate, setDeliveryRate] = useState(0);
    const [originalDolarRate, setOriginalDollarRate] = useState(0);
    const [originalDeliveryRate, setOriginalDeliveryRate] = useState(0);
    const fNumber = (number) => {
        return numeral(number).format();
    }
    const fCurrency = (number) => {
        return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
    }
    const fDCurrency = (number) => {
        return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
    }
    useEffect(() => {
        dispatch(getAdminProducts())
        //dispatch(allOrders());
        dispatch(allUsers());
        getOriginalDollarRate();
        getOriginalDeliveryRate();
    }, [dispatch])
    const getOriginalDollarRate = async () => {
        try {
            const response = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/dollarRate`)
            setOriginalDollarRate(response.data.dr[0].value);
            setDollarRate(response.data.dr[0].value);
        } catch (error) {
            console.error(`Error: ${error}`)
        }
    }
    const getOriginalDeliveryRate = async () => {
        try {
            const response = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/deliveryRate`)
            setOriginalDeliveryRate(response.data.dr[0].value);
            setDeliveryRate(response.data.dr[0].value);
        } catch (error) {
            console.error(`Error: ${error}`)
        }
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        if (dolarRate.toString().trim() === '') {
            return;
        }
        else {
            const form = {
                value: dolarRate
            }
            await dispatch(inserUpdateDollarRate(form));
            let value = {
                value: dolarRate
            }
            await axios.post(`https://backend.lingeriefidele.com/api/v1/admin/updateAllPrices`, value);
            getOriginalDollarRate();
        }
    }
    const submitHandlerDelivery = async (e) => {
        e.preventDefault();
        if (deliveryRate.toString().trim() === '') {
            return;
        }
        else {
            const form = {
                value: deliveryRate
            }
            await dispatch(insertOrUpdateDeliveryRate(form));
            getOriginalDeliveryRate();
        }
    }
    let outofstock = 0;
    products.forEach(product => {
        if (product.stock === 0) {
            outofstock += 1;
        }
    })
    return (
        <div >
            <div className="row">
                {/* <div className="col-12 col-md-2">
                    <Sidebar />
                </div> */}
                <div className="col-12">
                    <h1 className="my-4 titlecms" >Dashboard</h1>
                    {/* <div className="row pr-4">
                        <div className="col-xl-12 col-sm-12 mb-3">
                            <div className="card text-white bg-primary o-hidden h-100">
                                <div className="card-body">
                                    <OrderTotalPrice order={orders} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row pr-4 ml-1">
                        <div className="col-xl-4 col-sm-6 mb-3">
                            <div className="card text-white bg-success o-hidden h-100">
                                <div className="card-body">
                                    <div className="text-center card-font-size">Products<br /><br /> <b >{products && fNumber(products.length)}</b></div>
                                </div>
                                {/* <Link className="card-footer text-white clearfix medium z-1" to="/admin/products">
                                    <span className="float-left">View Details</span>
                                    <span className="float-right">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </Link> */}
                            </div>
                        </div>
                        {/* <div className="col-xl-4 col-sm-6 mb-3">
                            <div className="card text-white bg-danger o-hidden h-100">
                                <div className="card-body">
                                    <div className="text-center card-font-size">Orders<br /><br /> <b>{orders && fNumber(orders.length)}</b></div>
                                </div>
                                <Link className="card-footer text-white clearfix medium z-1" to="/admin/orders">
                                    <span className="float-left">View Details</span>
                                    <span className="float-right">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </Link>
                            </div>
                        </div> */}
                        <div className="col-xl-4 col-sm-6 mb-3">
                            <div className="card text-white bg-info o-hidden h-100">
                                <div className="card-body">
                                    <div className="text-center card-font-size">Users<br /><br /> <b>{users && fNumber(users.length)}</b></div>
                                </div>
                                {/* <Link className="card-footer text-white clearfix medium z-1" to="/admin/users">
                                    <span className="float-left">View Details</span>
                                    <span className="float-right">
                                        <i className="fa fa-angle-right"></i>
                                    </span>
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-4 mb-3">
                            <div className="card text-white o-hidden h-100" style={{ backgroundColor: "#6B8068" }}>
                                <div className="card-body">
                                    <div className="text-center card-font-size">
                                        <div className="row">
                                            <div className="col">
                                                Dollar Rate
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <b>LBP {fCurrency(originalDolarRate)}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={submitHandler}>
                                    <div className='form-group ml-1 mr-1'>
                                        <label htmlFor='categoryName_field'>Enter $ Rate</label>
                                        <input
                                            type='number'
                                            min='0'
                                            id='categoryName_field'
                                            className='form-control'
                                            placeholder="Dollar Rate"
                                            value={dolarRate}
                                            onChange={(e) => setDollarRate(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        id='search_button'
                                        type='submit'
                                        className='btn btn-raised shadow my-button w-xs dollarColor'
                                    >
                                        Update
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row pr-4 ml-1">
                        <div className="col-xl-4 col-sm-4 mb-3">
                            <div className="card text-white o-hidden h-100" style={{ backgroundColor: "#610220" }}>
                                <div className="card-body">
                                    <div className="text-center card-font-size">
                                        <div className="row">
                                            <div className="col">
                                                Delivery Cost
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <b>LBP {fCurrency(originalDeliveryRate)}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={submitHandlerDelivery}>
                                    <div className='form-group ml-1 mr-1'>
                                        <label htmlFor='categoryName_field'>Enter Delivery Cost</label>
                                        <input
                                            type='number'
                                            min='0'
                                            id='categoryName_field'
                                            className='form-control'
                                            placeholder="Delivery Cost"
                                            value={deliveryRate}
                                            onChange={(e) => setDeliveryRate(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        id='search_button'
                                        type='submit'
                                        className='btn btn-raised shadow my-button w-xs dollarColor'
                                    >
                                        Update
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard
