import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { register, clearErrors } from '../../actions/userActions'
import axios from 'axios';
const Register = ({ history }) => {
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
    })
    const { name, email, password } = user;
    const alert = useAlert();
    const dispatch = useDispatch();
    const { isAuthenticated, error, loading } = useSelector(state => state.auth);
    const [openSnack, setSnackOpen] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/')
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, alert, isAuthenticated, error, history])
    const submitHandler = async (e) => {
        e.preventDefault();
        if (name.trim() === '' && email.trim() === '' && password.trim() === '') {
            setMessage('Please enter your information')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() === '' && email.trim() === '' && password.trim() !== '') {
            setMessage('Please enter name & email')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() === '' && email.trim() !== '' && password.trim() === '') {
            setMessage('Please enter name & password')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() !== '' && email.trim() === '' && password.trim() === '') {
            setMessage('Please enter email & password')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() === '' && email.trim() !== '' && password.trim() !== '') {
            setMessage('Please enter name')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() !== '' && email.trim() !== '' && password.trim() === '') {
            setMessage('Please enter a password')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() !== '' && email.trim() === '' && password.trim() !== '') {
            setMessage('Please enter email')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() !== '' && email.trim() !== '' && password.trim() !== '' && password.length < 6) {
            setMessage('Your password must be longer than 6 characters')
            setSuccess(false);
            setSnackOpen(true);
        }
        else if (name.trim() !== '' && email.trim() !== '' && password.trim() !== '' && name.length > 30) {
            setMessage('Your name cannot exceed 30 characters')
            setSuccess(false);
            setSnackOpen(true);
        }
        else {
            const formData = new FormData();
            formData.set('name', name);
            formData.set('email', email);
            formData.set('password', password);
            const res = await axios.post(`https://backend.lingeriefidele.com/api/v1/register`, formData);
            if (!res.data.success) {
                setMessage('Email already exist')
                setSuccess(false);
                setSnackOpen(true);
            }
            else {
                setSuccess(true);
                setSnackOpen(true);
                localStorage.setItem('User', JSON.stringify({ ...res?.data }));
                window.location.reload(false);
            }
            // const formData = new FormData();
            // formData.set('name', name);
            // formData.set('email', email);
            // formData.set('password', password);
            // const data = await dispatch(register(formData))
            // if (data) {
            //     setSuccess(true);
            //     setSnackOpen(true);
            //     window.location.href = "/";
            // }
            // if (!data) {
            //     setMessage('Email already exist')
            //     setSuccess(false);
            //     setSnackOpen(true);
            //     // alert.error('Email already exist');
            // }
        }
    }
    const onChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    return (
        <Fragment>
            <MetaData title={'Register User'} />
            <div className="row wrapper">
                <div className="col-10 col-lg-5">
                    <form className="shadow-lg" onSubmit={submitHandler} encType='multipart/form-data'>
                        <h1 className="mb-3">Register</h1>
                        <div className="form-group">
                            <label htmlFor="email_field">Name</label>
                            <input
                                type="name"
                                id="name_field"
                                className="form-control"
                                name='name'
                                value={name}
                                onChange={onChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email_field">Email</label>
                            <input
                                type="email"
                                id="email_field"
                                className="form-control"
                                name='email'
                                value={email}
                                onChange={onChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password_field">Password</label>
                            <input
                                type="password"
                                id="password_field"
                                className="form-control"
                                name='password'
                                value={password}
                                onChange={onChange}
                            />
                        </div>
                        {/* <div className='form-group'>
                            <label htmlFor='avatar_upload'>Avatar</label>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <figure className='avatar mr-3 item-rtl'>
                                        <img
                                            src={avatarPreview}
                                            className='rounded-circle'
                                            alt='Avatar Preview'
                                        />
                                    </figure>
                                </div>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='avatar'
                                        className='custom-file-input'
                                        id='customFile'
                                        accept="iamges/*"
                                        onChange={onChange}
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        Choose Avatar
                                    </label>
                                </div>
                            </div>
                        </div> */}
                        <button
                            id="register_button"
                            type="submit"
                            className="btn btn-block py-3"
                            disabled={loading ? true : false}
                        >
                            REGISTER
                        </button>
                    </form>
                </div>
            </div>
            {
                success !== undefined ? (
                    success ? (
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert onClose={handleSnackClose} severity="success">
                                Successfully registered
                            </Alert>
                        </Snackbar>
                    ) : (
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={handleSnackClose} severity="error">
                                {message}
                            </Alert>
                        </Snackbar>
                    )) : null
            }
        </Fragment>
    )
}
export default Register