import React, { Fragment, useState, useEffect } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useDispatch, useSelector } from 'react-redux'
import { getAllColor } from "../actions/productActions";
import '../components/product/style.css'
import '../components/product/style.scss'

const Size = ({ setSize, category }) => {
    // const [size, setsize] = useState([])
    // let ColorArr = []
    // const dispatch = useDispatch();
    // useEffect(async () => {
    //     const data = await dispatch(getAllColor())
    //     setsize(data)
    // }, [])
    // size.products && size.products.map((option) => {
    //     option.options.map((options) => {
    //         if (options !== undefined && options !== null) {
    //             if (options.color !== undefined && options.color !== '') {
    //                 ColorArr.push(options.size)
    //             }
    //         }
    //     })
    // })
    const womenMen = [
        { value: 'One Size', label: 'One Size' },
        { value: 'XS', label: 'XS' },
        { value: 'S', label: 'S' },
        { value: 'M', label: 'M' },
        { value: 'L', label: 'L' },
        { value: 'XL', label: 'XL' },
        { value: '2XL', label: '2XL' },
        { value: '3XL', label: '3XL' },
        { value: '4XL', label: '4XL' },
        { value: '5XL', label: '5XL' }
    ]
    const kids = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '1/2', label: '1/2' },
        { value: '3/4', label: '3/4' },
        { value: '5/6', label: '5/6' },
        { value: '7/8', label: '7/8' },
        { value: '9/10', label: '9/10' },
        { value: '11/12', label: '11/12' },
        { value: '13/14', label: '13/14' },
        { value: '15/16', label: '15/16' }
    ]
    const babies = [
        { value: 'NB', label: 'NB' },
        { value: '0', label: '0' },
        { value: '3', label: '3' },
        { value: '6', label: '6' },
        { value: '9', label: '9' },
        { value: '12', label: '12' },
        { value: '18', label: '18' },
        { value: '24', label: '24' },
        { value: '30', label: '30' },
        { value: '36', label: '36' },
        { value: 'One Size', label: 'One Size' }
    ]
    const other = [
        { value: 'Single', label: 'Single' },
        { value: 'Double', label: 'Double' },
        { value: 'King', label: 'King' }
    ]
    // let uniqueChars = [...new Set(ColorArr)];
    return (
        <>
            <div className="swatch clearfix" data-option-index="0">
                {
                    category === 'Babies' ? (
                        babies.map((size) => (
                            <div data-value={size.value} onClick={() => setSize(size.value)} className="swatch-element plain m available">
                                <input id={`${size.value}`} type="radio" name="size-1" value={size.value} />
                                <label htmlFor={`${size.value}`}>
                                    {size.value}
                                </label>
                            </div>

                        ))
                    ) : category === 'Kids' ? (
                        kids.map((size) => (
                            <div data-value={size.value} onClick={() => setSize(size.value)} className="swatch-element plain m available">
                                <input id={`${size.value}`} type="radio" name="size-1" value={size.value} />
                                <label htmlFor={`${size.value}`}>
                                    {size.value}
                                </label>
                            </div>

                        ))
                    ) : category === 'Men' || category === 'Women' ? (
                        womenMen.map((size) => (
                            <div data-value={size.value} onClick={() => setSize(size.value)} className="swatch-element plain m available">
                                <input id={`${size.value}`} type="radio" name="size-1" value={size.value} />
                                <label htmlFor={`${size.value}`}>
                                    {size.value}
                                </label>
                            </div>

                        ))
                    ) : category === 'Bed Linen' ? (
                        other.map((size) => (
                            <div data-value={size.value} onClick={() => setSize(size.value)} className="swatch-element plain m available">
                                <input id={`${size.value}`} type="radio" name="size-1" value={size.value} />
                                <label htmlFor={`${size.value}`}>
                                    {size.value}
                                </label>
                            </div>
                        ))
                    ) : null
                }

            </div>
            {/* , , ,  */}
            {/* <div className="swatch clearfix" data-option-index="0">
                {uniqueChars && uniqueChars.map((size) => (
                    <div data-value={size} onClick={() => setSize(size)} className="swatch-element plain m available">
                        <input id={`${size}`} type="radio" name="size-1" value={size} />
                        <label htmlFor={`${size}`}>
                            {size}
                        </label>
                    </div>

                ))}
            </div> */}
        </>
    )
}
export default Size;