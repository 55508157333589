import axios from 'axios'
import * as api from '../api/index.js';

export const contactUs = (formData) => async (dispatch) => {
    try {
        await api.contactUs(formData)
        return true
    } catch (error) {
        return false
    }

}