import React, { Fragment, useState, useEffect } from 'react';
import MetaData from '../layout/MetaData';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProfile,
  loadUser,
  clearErrors,
} from '../../actions/userActions';
import { UPDATE_PROFILE_RESET } from '../../constants/userConstants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import "./style.css"
import { Link } from 'react-router-dom'
const UpdateProfile = ({ history }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  // const [avatar, setAvatar] = useState('');
  // const [avatarPreview, setAvatarPreview] = useState(
  //   '/images/logo.png'
  // );
  const alert = useAlert();
  const dispatch = useDispatch();
  const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')));
  const user = User ? User.user : null
  const { error, isUpdated, loading } = useSelector((state) => state.user);
  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      // setAvatarPreview(user.avatar.url);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success('User updated successfully');
      dispatch(loadUser());
      history.push('/me/update');
      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, alert, error, history, isUpdated]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('name', name);
    formData.set('email', email);
    // formData.set('avatar', avatar);
    const data = await dispatch(updateProfile(formData, user));
    if (data.success) {
      window.location.reload()
    }
  };
  const onChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        // setAvatar(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <div style={{ marginTop: "180px" }}>
      <MetaData title={'Update Profile'} />
      <div className='row wrapper ' style={{ marginTop: '100px' }}>
        <div className='col-10 col-lg-5'>
          <form
            className='shadow-lg'
            onSubmit={submitHandler}
            encType='multipart/form-data'
          >
            <Typography variant="h4">
              Update Profile
            </Typography>
            <div className='form-group'>
              <label
                className="typo"
                htmlFor='email_field'>Name</label>
              <input
                type='name'
                id='name_field'
                className='form-control'
                name='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label
                className="typo"
                htmlFor='email_field'>Email</label>
              <input
                type='email'
                id='email_field'
                className='form-control'
                name='email'
                value={email}
                //onChange={(e) => setEmail(e.target.value)}
                readOnly
              />
            </div>
            {/* <div className='form-group'>
              <label htmlFor='avatar_upload'>Avatar</label>
              <div className='d-flex align-items-center'>
                <div>
                  <figure className='avatar mr-3 item-rtl'>
                    <img
                      src={avatarPreview}
                      className='rounded-circle'
                      alt='Avatar Preview'
                    />
                  </figure>
                </div>
                <div className='custom-file'>
                  <input
                    type='file'
                    name='avatar'
                    className='custom-file-input'
                    id='customFile'
                    accept='image/*'
                    onChange={onChange}
                  />
                  <label className='custom-file-label' htmlFor='customFile'>
                    Choose Avatar
                  </label>
                </div>
              </div>
            </div> */}
            {/* <button
              type='submit'
              className='btn update-btn btn-block mt-4 mb-3'
              disabled={loading ? true : false}
            >
              Update
            </button> */}
            <Button
              type="submit"
              disabled={loading ? true : false}
              variant="contained" id="updatePassbtn" className="btn btn-block py-3 "  >Update </Button>
            <Link to="/password/update" id="changePassbtn" className="btn btn-block py-3" >
              Change Password
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateProfile;