import React, { useEffect, useState } from 'react';
import './style.scss';
import './postAnimation';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import UseStyles from './styles';
import { Box, Fab, Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist, deleteWish, getWishlist } from "../../actions/productActions";
import DialogContent from "@material-ui/core/DialogContent";
import { AccountBox } from "../accountBox";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import numeral from 'numeral';
import {
    DialogActions,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
const Posts = ({ product, col, id }) => {
    const [openSignin, setOpenSignin] = React.useState(false);
    const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
    const handleCloseSignin = () => {
        setOpenSignin(false);
    };
    const handleClickOpenSignin = () => {
        setOpenSignin(true);
    };
    const classes = UseStyles();
    const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    const [selectedValue, setSelectedValue] = React.useState('color');
    const [value, setValue] = React.useState(2);
    const [buttonFavoriteIsClicked, isFavoriteClicked] = useState(false);
    const [buttonFavoriteIsClicked1, isFavoriteClicked1] = useState(true);
    const [wishlist, setWishlist] = useState([]);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    let ColorArr = []
    const Hover = (id) => {
        var products = document.getElementById(`product${id}`);
        var animates = document.getElementById(`make3D${id}`);
        var animate = animates;
        var product = products;
        product.style.zIndex = 20;
        animate.classList.add("animate");
    }
    const Leave = (id) => {
        var products = document.getElementById(`product${id}`);
        var animates = document.getElementById(`make3D${id}`);
        var animate = animates;
        var product = products;
        product.style.zIndex = 1;
        animate.classList.remove('animate');
    }
    const Click = () => {
    }
    product.options && product.options.map((color) => {
        if (color !== null) {
            ColorArr.push(color.color)
        }
    })
    const fCurrency = (number) => {
        return numeral((Math.trunc(number / 1000) * 1000)).format(Number.isInteger((Math.trunc(number / 1000) * 1000)) ? '0,0' : '0,0.00');
    }
    let uniqueChars = [...new Set(ColorArr)];
    const dispatch = useDispatch();
    const clickedFavoriteBtn = (idPost) => {
        const id = user._id;
        const formData = {
            product: idPost
        };
        isFavoriteClicked(true);
        if (id !== null) {
            dispatch(addToWishlist(id, formData));
        }
    }
    const unClickedFavoriteBtn = (idPost) => {
        const userId = user._id;
        // dispatch(getWishlist(userId));
        isFavoriteClicked(false);
        if (userId !== null) {
            dispatch(deleteWish(userId, idPost));
        }
    }
    const clickedFavoriteBtn1 = (idPost) => {
        const id = user._id;
        const formData = {
            product: idPost
        };
        isFavoriteClicked1(true);
        if (id !== null) {
            dispatch(addToWishlist(id, formData));
        }
    }
    const unClickedFavoriteBtn1 = (idPost) => {
        const userId = user._id;
        // dispatch(getWishlist(userId));
        isFavoriteClicked1(false);
        if (userId !== null) {
            dispatch(deleteWish(userId, idPost));
        }
    }

    useEffect(() => {
        const getData = async () => {
            if (user !== null && user !== undefined) {
                const id = user._id;
                const wishlist = await dispatch(getWishlist(id));
                setWishlist(wishlist);
            }
        }
        getData();
    }, [dispatch, user]);
    return (
        (user !== null) && (user !== undefined) ? (
            wishlist.length === 0 ? (
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div /*className={`col-sm-12 col-md-6 col-lg-${col} my-3`}*/>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                            <div className="product" id={`product${id}`}>
                                <div className="make3D" id={`make3D${id}`}
                                    onMouseOver={() => Hover(id)}
                                    onMouseLeave={() => Leave(id)}>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                    <div className="product-front">
                                        <div className="shadow"></div>
                                        <img
                                            className="card-img-top mx-auto"
                                            src={product.images.length > 0 ? product.images[0].url : null}
                                        />
                                        {/* <FavoriteBorderIcon id="favorite" className={classes.Favorite}/> */}
                                        <div className="image_overlay"></div>
                                        {/* <Link to="/cart" style={{textDecoration: 'none'}}>
                                <div className="add_to_cart">Add to cart</div>
                            </Link> */}
                                        {product.status === 'OUTOFSTOCK' ?
                                            (
                                                <IconButton aria-label="add to favorites" style={{ position: 'absolute' }}
                                                    className={classes.StatusOut} >
                                                    OUTOFSTOCK
                                                </IconButton>
                                            ) : null}
                                        {
                                            buttonFavoriteIsClicked ?
                                                (
                                                    <IconButton aria-label="add to favorites" style={{ position: 'absolute' }} onClick={() => unClickedFavoriteBtn(product._id)} className={classes.Favorite} >
                                                        <FavoriteIcon style={{ color: "#C9E4E7" }} />
                                                    </IconButton>
                                                ) :
                                                (
                                                    <IconButton aria-label="add to favorites" style={{ position: 'absolute' }} onClick={() => clickedFavoriteBtn(product._id)} className={classes.Favorite} >
                                                        <FavoriteIcon />
                                                    </IconButton>
                                                )
                                        }
                                        <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                                            <div className="view_gallery">View Details</div>
                                        </Link>
                                        {/* <Link to={`/product/${product._id}`} id="view_btn" className="btn btn-block">View Details</Link> */}
                                        <div className="stats">
                                            <div className="stats-container">
                                                <span style={{ fontFamily: 'Roboto,sans-serif' }} className="product_name"> <Link to={`/product/${product._id}`}>
                                                    {product.name}
                                                    {/* {product.name.length > 18 ? product.name.substring(0, 18) : } */}
                                                </Link></span>
                                                {/* <br />
                                                <span style={{ fontFamily: 'Roboto,sans-serif' }} className="subproduct_name">{product.category}</span> */}
                                                <br />
                                                <div style={{ display: "block" }}>
                                                    {
                                                        product.discountRate && product.discountRate > 0 ? (
                                                            <>
                                                                <span className="product_price" style={{ textDecoration: "line-through", color: '#808080', fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }}>LBP {fCurrency(product.price)}</span>
                                                                <span className="product_price" style={{ color: "#a2d7e9", fontFamily: 'Roboto,sans-serif', marginBottom: "10px", marginLeft: "10px" }}>LBP {fCurrency(product.price - (product.price * product.discountRate / 100))}</span>
                                                            </>
                                                        ) : (
                                                            <span style={{ fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }} className="product_price">LBP {fCurrency(product.price)}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="product-options">
                                                    <div className="colors">
                                                        {uniqueChars && uniqueChars.map((color) => (
                                                            color && color !== '' ? (
                                                                color === 'White' ?
                                                                    (<IconButton size="small" style={{ color: '#FFF', border: '1px solid #000', width: '1.1rem', height: '1.1rem' }}
                                                                        aria-label="favorite">
                                                                        <FiberManualRecordIcon />
                                                                    </IconButton>) :
                                                                    color === 'Emerald Green' ?
                                                                        (<IconButton size="small" style={{ color: '#009A76' }}
                                                                            aria-label="favorite">
                                                                            <FiberManualRecordIcon />
                                                                        </IconButton>) :
                                                                        color === 'Brick Red' ?
                                                                            (<IconButton size="small" style={{ color: '#9E4429' }}
                                                                                aria-label="favorite">
                                                                                <FiberManualRecordIcon />
                                                                            </IconButton>) :
                                                                            color === 'Mustard Yellow' ?
                                                                                (<IconButton size="small" style={{ color: '#EAAA00' }}
                                                                                    aria-label="favorite">
                                                                                    <FiberManualRecordIcon />
                                                                                </IconButton>) :
                                                                                color === 'Light Bois De Rose' ?
                                                                                    (<IconButton size="small" style={{ color: '#CD9892' }}
                                                                                        aria-label="favorite">
                                                                                        <FiberManualRecordIcon />
                                                                                    </IconButton>) :
                                                                                    color === 'Brown' ?
                                                                                        (<IconButton size="small" style={{ color: '#5C4033' }}
                                                                                            aria-label="favorite">
                                                                                            <FiberManualRecordIcon />
                                                                                        </IconButton>) :
                                                                                        color === 'Dark Green' ?
                                                                                            (<IconButton size="small" style={{ color: '#313E34' }}
                                                                                                aria-label="favorite">
                                                                                                <FiberManualRecordIcon />
                                                                                            </IconButton>) :
                                                                                            color === 'Light Navy' ?
                                                                                                (<IconButton size="small" style={{ color: '#010080' }}
                                                                                                    aria-label="favorite">
                                                                                                    <FiberManualRecordIcon />
                                                                                                </IconButton>) :
                                                                                                color === 'Neon Fuschia' ?
                                                                                                    (<IconButton size="small" style={{ color: '#FE4164' }}
                                                                                                        aria-label="favorite">
                                                                                                        <FiberManualRecordIcon />
                                                                                                    </IconButton>) :
                                                                                                    color === 'Burgundy' ?
                                                                                                        (<IconButton size="small" style={{ color: '#610220' }}
                                                                                                            aria-label="favorite">
                                                                                                            <FiberManualRecordIcon />
                                                                                                        </IconButton>) :
                                                                                                        color === 'Pistachio' ?
                                                                                                            (<IconButton size="small" style={{ color: '#BDFBBE' }}
                                                                                                                aria-label="favorite">
                                                                                                                <FiberManualRecordIcon />
                                                                                                            </IconButton>) :
                                                                                                            color === 'Cherry' ?
                                                                                                                (<IconButton size="small" style={{ color: '#A5003D' }}
                                                                                                                    aria-label="favorite">
                                                                                                                    <FiberManualRecordIcon />
                                                                                                                </IconButton>) :
                                                                                                                color === 'Off White' ?
                                                                                                                    (<IconButton size="small" style={{ color: '#F8F0E3' }}
                                                                                                                        aria-label="favorite">
                                                                                                                        <FiberManualRecordIcon />
                                                                                                                    </IconButton>) :
                                                                                                                    color === 'Pink' ?
                                                                                                                        (<IconButton size="small" style={{ color: '#FEBAD3' }}
                                                                                                                            aria-label="favorite">
                                                                                                                            <FiberManualRecordIcon />
                                                                                                                        </IconButton>) :
                                                                                                                        color === 'Purple' ?
                                                                                                                            (<IconButton size="small" style={{ color: '#C99AF8' }}
                                                                                                                                aria-label="favorite">
                                                                                                                                <FiberManualRecordIcon />
                                                                                                                            </IconButton>) :
                                                                                                                            color === 'Dark Purple' ?
                                                                                                                                (<IconButton size="small" style={{ color: '#81007F' }}
                                                                                                                                    aria-label="favorite">
                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                </IconButton>) :
                                                                                                                                color === 'Red' ?
                                                                                                                                    (<IconButton size="small" style={{ color: '#FE0500' }}
                                                                                                                                        aria-label="favorite">
                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                    </IconButton>) :
                                                                                                                                    color === 'Salmon' ?
                                                                                                                                        (<IconButton size="small" style={{ color: '#FA7E72' }}
                                                                                                                                            aria-label="favorite">
                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                        </IconButton>) :
                                                                                                                                        color === 'Turquoise' ?
                                                                                                                                            (<IconButton size="small" style={{ color: '#00FFEF' }}
                                                                                                                                                aria-label="favorite">
                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                            </IconButton>) :
                                                                                                                                            color === 'Yellow' ?
                                                                                                                                                (<IconButton size="small" style={{ color: '#FDFC35' }}
                                                                                                                                                    aria-label="favorite">
                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                </IconButton>) :
                                                                                                                                                color === 'Navy' ?
                                                                                                                                                    (<IconButton size="small" style={{ color: '#1C1E47' }}
                                                                                                                                                        aria-label="favorite">
                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                    </IconButton>) :
                                                                                                                                                    color === 'Green' ?
                                                                                                                                                        (<IconButton size="small" style={{ color: '#008540' }}
                                                                                                                                                            aria-label="favorite">
                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                        </IconButton>) :
                                                                                                                                                        color === 'Beige' ?
                                                                                                                                                            (<IconButton size="small" style={{ color: '#BF9E7F' }}
                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                            </IconButton>) :
                                                                                                                                                            color === 'Blue' ?
                                                                                                                                                                (<IconButton size="small" style={{ color: '#8BCDF0' }}
                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                </IconButton>) :
                                                                                                                                                                color === 'Orange' ?
                                                                                                                                                                    (<IconButton size="small" style={{ color: '#FE6023' }}
                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                    color === 'Lightgray' ?
                                                                                                                                                                        (<IconButton size="small" style={{ color: '#B2B2B2' }}
                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                        color === 'Darkgray' ?
                                                                                                                                                                            (<IconButton size="small" style={{ color: '#404040' }}
                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                            color === 'Fuchsia' ?
                                                                                                                                                                                (<IconButton size="small" style={{ color: '#E60081' }}
                                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                                </IconButton>) :
                                                                                                                                                                                color === 'Blue Grey' ?
                                                                                                                                                                                    (<IconButton size="small" style={{ color: '#B5C9E1' }}
                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                                    color === 'Bois De Rose' ?
                                                                                                                                                                                        (<IconButton size="small" style={{ color: '#864650' }}
                                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                                        color === 'Blue Petrol' ?
                                                                                                                                                                                            (<IconButton size="small" style={{ color: '#0F4B63' }}
                                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                                            (<IconButton size="small" style={{ color: `${color}` }}
                                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                                            </IconButton>)
                                                            ) : null))}
                                                    </div>
                                                    {/* <div className="ratings mt-auto">
                                                        <div className="rating-outer">
                                                            <div className="rating-inner" style={{
                                                                width: `${(product.ratings / 5) * 100}% `
                                                            }}></div>
                                                        </div>
                                                        <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            ) :
                (
                    JSON.stringify(wishlist).includes(product._id) ? (
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div /*className={`col-sm-12 col-md-6 col-lg-${col} my-3`}*/>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                    <div className="product" id={`product${id}`}>
                                        <div className="make3D" id={`make3D${id}`}
                                            onMouseOver={() => Hover(id)}
                                            onMouseLeave={() => Leave(id)}>
                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                            <div className="product-front">
                                                <div className="shadow"></div>
                                                <img
                                                    className="card-img-top mx-auto"
                                                    src={product.images.length > 0 ? product.images[0].url : null}
                                                />
                                                {/* <FavoriteBorderIcon id="favorite" className={classes.Favorite}/> */}
                                                <div className="image_overlay"></div>
                                                {/* <Link to="/cart" style={{textDecoration: 'none'}}>
                                        <div className="add_to_cart">Add to cart</div>
                                    </Link> */}
                                                {product.status === 'OUTOFSTOCK' ?
                                                    (
                                                        <IconButton aria-label="add to favorites" style={{ position: 'absolute' }}
                                                            className={classes.StatusOut} >
                                                            OUTOFSTOCK
                                                        </IconButton>
                                                    ) : null}
                                                {
                                                    buttonFavoriteIsClicked1 ?
                                                        (
                                                            <IconButton aria-label="add to favorites" style={{ position: 'absolute' }} onClick={() => unClickedFavoriteBtn1(product._id)} className={classes.Favorite} >
                                                                <FavoriteIcon style={{ color: "#C9E4E7" }} />
                                                            </IconButton>
                                                        ) :
                                                        (
                                                            <IconButton aria-label="add to favorites" style={{ position: 'absolute' }} onClick={() => clickedFavoriteBtn1(product._id)} className={classes.Favorite} >
                                                                <FavoriteIcon />
                                                            </IconButton>
                                                        )
                                                }
                                                <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="view_gallery">View Details</div>
                                                </Link>
                                                {/* <Link to={`/product/${product._id}`} id="view_btn" className="btn btn-block">View Details</Link> */}
                                                <div className="stats">
                                                    <div className="stats-container">
                                                        <span style={{ fontFamily: 'Roboto,sans-serif' }} className="product_name"> <Link to={`/product/${product._id}`}>
                                                            {product.name}
                                                            {/* {product.name.length > 18 ? product.name.substring(0, 18) : product.name} */}
                                                        </Link></span>
                                                        {/* <br /> */}
                                                        {/* <span style={{ fontFamily: 'Roboto,sans-serif' }} className="subproduct_name">{product.category}</span> */}
                                                        <br />
                                                        <div style={{ display: "block" }}>
                                                            {
                                                                product.discountRate && product.discountRate > 0 ? (
                                                                    <>
                                                                        <span className="product_price" style={{ textDecoration: "line-through", color: '#808080', fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }}>LBP {fCurrency(product.price)}</span>
                                                                        <span className="product_price" style={{ color: "#a2d7e9", fontFamily: 'Roboto,sans-serif', marginBottom: "10px", marginLeft: "10px" }}>LBP {fCurrency(product.price - (product.price * product.discountRate / 100))}</span>
                                                                    </>
                                                                ) : (
                                                                    <span style={{ fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }} className="product_price">LBP {fCurrency(product.price)}</span>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="product-options">
                                                            <div className="colors">
                                                                {uniqueChars && uniqueChars.map((color) => (
                                                                    color && color !== '' ? (
                                                                        color === 'White' ?
                                                                            (<IconButton size="small" style={{ color: '#FFF', border: '1px solid #000', width: '1.1rem', height: '1.1rem' }}
                                                                                aria-label="favorite">
                                                                                <FiberManualRecordIcon />
                                                                            </IconButton>) :
                                                                            color === 'Emerald Green' ?
                                                                                (<IconButton size="small" style={{ color: '#009A76' }}
                                                                                    aria-label="favorite">
                                                                                    <FiberManualRecordIcon />
                                                                                </IconButton>) :
                                                                                color === 'Brick Red' ?
                                                                                    (<IconButton size="small" style={{ color: '#9E4429' }}
                                                                                        aria-label="favorite">
                                                                                        <FiberManualRecordIcon />
                                                                                    </IconButton>) :
                                                                                    color === 'Mustard Yellow' ?
                                                                                        (<IconButton size="small" style={{ color: '#EAAA00' }}
                                                                                            aria-label="favorite">
                                                                                            <FiberManualRecordIcon />
                                                                                        </IconButton>) :
                                                                                        color === 'Light Bois De Rose' ?
                                                                                            (<IconButton size="small" style={{ color: '#CD9892' }}
                                                                                                aria-label="favorite">
                                                                                                <FiberManualRecordIcon />
                                                                                            </IconButton>) :
                                                                                            color === 'Brown' ?
                                                                                                (<IconButton size="small" style={{ color: '#5C4033' }}
                                                                                                    aria-label="favorite">
                                                                                                    <FiberManualRecordIcon />
                                                                                                </IconButton>) :
                                                                                                color === 'Dark Green' ?
                                                                                                    (<IconButton size="small" style={{ color: '#313E34' }}
                                                                                                        aria-label="favorite">
                                                                                                        <FiberManualRecordIcon />
                                                                                                    </IconButton>) :
                                                                                                    color === 'Light Navy' ?
                                                                                                        (<IconButton size="small" style={{ color: '#010080' }}
                                                                                                            aria-label="favorite">
                                                                                                            <FiberManualRecordIcon />
                                                                                                        </IconButton>) :
                                                                                                        color === 'Neon Fuschia' ?
                                                                                                            (<IconButton size="small" style={{ color: '#FE4164' }}
                                                                                                                aria-label="favorite">
                                                                                                                <FiberManualRecordIcon />
                                                                                                            </IconButton>) :
                                                                                                            color === 'Burgundy' ?
                                                                                                                (<IconButton size="small" style={{ color: '#610220' }}
                                                                                                                    aria-label="favorite">
                                                                                                                    <FiberManualRecordIcon />
                                                                                                                </IconButton>) :
                                                                                                                color === 'Pistachio' ?
                                                                                                                    (<IconButton size="small" style={{ color: '#BDFBBE' }}
                                                                                                                        aria-label="favorite">
                                                                                                                        <FiberManualRecordIcon />
                                                                                                                    </IconButton>) :
                                                                                                                    color === 'Cherry' ?
                                                                                                                        (<IconButton size="small" style={{ color: '#A5003D' }}
                                                                                                                            aria-label="favorite">
                                                                                                                            <FiberManualRecordIcon />
                                                                                                                        </IconButton>) :
                                                                                                                        color === 'Off White' ?
                                                                                                                            (<IconButton size="small" style={{ color: '#F8F0E3' }}
                                                                                                                                aria-label="favorite">
                                                                                                                                <FiberManualRecordIcon />
                                                                                                                            </IconButton>) :
                                                                                                                            color === 'Pink' ?
                                                                                                                                (<IconButton size="small" style={{ color: '#FEBAD3' }}
                                                                                                                                    aria-label="favorite">
                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                </IconButton>) :
                                                                                                                                color === 'Purple' ?
                                                                                                                                    (<IconButton size="small" style={{ color: '#C99AF8' }}
                                                                                                                                        aria-label="favorite">
                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                    </IconButton>) :
                                                                                                                                    color === 'Dark Purple' ?
                                                                                                                                        (<IconButton size="small" style={{ color: '#81007F' }}
                                                                                                                                            aria-label="favorite">
                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                        </IconButton>) :
                                                                                                                                        color === 'Red' ?
                                                                                                                                            (<IconButton size="small" style={{ color: '#FE0500' }}
                                                                                                                                                aria-label="favorite">
                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                            </IconButton>) :
                                                                                                                                            color === 'Salmon' ?
                                                                                                                                                (<IconButton size="small" style={{ color: '#FA7E72' }}
                                                                                                                                                    aria-label="favorite">
                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                </IconButton>) :
                                                                                                                                                color === 'Turquoise' ?
                                                                                                                                                    (<IconButton size="small" style={{ color: '#00FFEF' }}
                                                                                                                                                        aria-label="favorite">
                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                    </IconButton>) :
                                                                                                                                                    color === 'Yellow' ?
                                                                                                                                                        (<IconButton size="small" style={{ color: '#FDFC35' }}
                                                                                                                                                            aria-label="favorite">
                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                        </IconButton>) :
                                                                                                                                                        color === 'Navy' ?
                                                                                                                                                            (<IconButton size="small" style={{ color: '#1C1E47' }}
                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                            </IconButton>) :
                                                                                                                                                            color === 'Green' ?
                                                                                                                                                                (<IconButton size="small" style={{ color: '#008540' }}
                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                </IconButton>) :
                                                                                                                                                                color === 'Beige' ?
                                                                                                                                                                    (<IconButton size="small" style={{ color: '#BF9E7F' }}
                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                    color === 'Blue' ?
                                                                                                                                                                        (<IconButton size="small" style={{ color: '#8BCDF0' }}
                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                        color === 'Orange' ?
                                                                                                                                                                            (<IconButton size="small" style={{ color: '#FE6023' }}
                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                            color === 'Lightgray' ?
                                                                                                                                                                                (<IconButton size="small" style={{ color: '#B2B2B2' }}
                                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                                </IconButton>) :
                                                                                                                                                                                color === 'Darkgray' ?
                                                                                                                                                                                    (<IconButton size="small" style={{ color: '#404040' }}
                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                                    color === 'Fuchsia' ?
                                                                                                                                                                                        (<IconButton size="small" style={{ color: '#E60081' }}
                                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                                        color === 'Blue Grey' ?
                                                                                                                                                                                            (<IconButton size="small" style={{ color: '#B5C9E1' }}
                                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                                            color === 'Bois De Rose' ?
                                                                                                                                                                                                (<IconButton size="small" style={{ color: '#864650' }}
                                                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                                                </IconButton>) :
                                                                                                                                                                                                color === 'Blue Petrol' ?
                                                                                                                                                                                                    (<IconButton size="small" style={{ color: '#0F4B63' }}
                                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                                                    (<IconButton size="small" style={{ color: `${color}` }}
                                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                                    </IconButton>)
                                                                    ) : null))}
                                                            </div>
                                                            {/* <div className="ratings mt-auto">
                                                                <div className="rating-outer">
                                                                    <div className="rating-inner" style={{
                                                                        width: `${(product.ratings / 5) * 100}% `
                                                                    }}></div>
                                                                </div>
                                                                <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div /*className={`col-sm-12 col-md-6 col-lg-${col} my-3`}*/>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                    <div className="product" id={`product${id}`}>
                                        <div className="make3D" id={`make3D${id}`}
                                            onMouseOver={() => Hover(id)}
                                            onMouseLeave={() => Leave(id)}>
                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                            <div className="product-front">
                                                <div className="shadow"></div>
                                                <img
                                                    className="card-img-top mx-auto"
                                                    src={product.images.length > 0 ? product.images[0].url : null}
                                                />
                                                {/* <FavoriteBorderIcon id="favorite" className={classes.Favorite}/> */}
                                                <div className="image_overlay"></div>
                                                {/* <Link to="/cart" style={{textDecoration: 'none'}}>
                                        <div className="add_to_cart">Add to cart</div>
                                    </Link> */}
                                                {product.status === 'OUTOFSTOCK' ?
                                                    (
                                                        <IconButton aria-label="add to favorites" style={{ position: 'absolute' }}
                                                            className={classes.StatusOut} >
                                                            OUTOFSTOCK
                                                        </IconButton>

                                                    ) : null}
                                                {
                                                    buttonFavoriteIsClicked ?
                                                        (
                                                            <IconButton aria-label="add to favorites" style={{ position: 'absolute' }} onClick={() => unClickedFavoriteBtn(product._id)} className={classes.Favorite} >
                                                                <FavoriteIcon style={{ color: "#C9E4E7" }} />
                                                            </IconButton>
                                                        ) :
                                                        (
                                                            <IconButton aria-label="add to favorites" style={{ position: 'absolute' }} onClick={() => clickedFavoriteBtn(product._id)} className={classes.Favorite} >
                                                                <FavoriteIcon />
                                                            </IconButton>
                                                        )
                                                }
                                                <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="view_gallery">View Details</div>
                                                </Link>
                                                {/* <Link to={`/product/${product._id}`} id="view_btn" className="btn btn-block">View Details</Link> */}
                                                <div className="stats">
                                                    <div className="stats-container">
                                                        <span style={{ fontFamily: 'Roboto,sans-serif' }} className="product_name"> <Link to={`/product/${product._id}`}>
                                                            {product.name}
                                                            {/* {product.name.length > 18 ? product.name.substring(0, 18) : product.name} */}
                                                        </Link></span>
                                                        {/* <br /> */}
                                                        {/* <span style={{ fontFamily: 'Roboto,sans-serif' }} className="subproduct_name">{product.category}</span>  */}
                                                        <br />
                                                        <div style={{ display: "block" }}>
                                                            {
                                                                product.discountRate && product.discountRate > 0 ? (
                                                                    <>
                                                                        <span className="product_price" style={{ textDecoration: "line-through", color: '#808080', fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }}>LBP {fCurrency(product.price)}</span>
                                                                        <span className="product_price" style={{ color: "#a2d7e9", fontFamily: 'Roboto,sans-serif', marginBottom: "10px", marginLeft: "10px" }}>LBP {fCurrency(product.price - (product.price * product.discountRate / 100))}</span>
                                                                    </>
                                                                ) : (
                                                                    <span style={{ fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }} className="product_price">LBP {fCurrency(product.price)}</span>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="product-options">
                                                            <div className="colors">
                                                                {uniqueChars && uniqueChars.map((color) => (
                                                                    color && color !== '' ? (
                                                                        color === 'White' ?
                                                                            (<IconButton size="small" style={{ color: '#FFF', border: '1px solid #000', width: '1.1rem', height: '1.1rem' }}
                                                                                aria-label="favorite">
                                                                                <FiberManualRecordIcon />
                                                                            </IconButton>) :
                                                                            color === 'Emerald Green' ?
                                                                                (<IconButton size="small" style={{ color: '#009A76' }}
                                                                                    aria-label="favorite">
                                                                                    <FiberManualRecordIcon />
                                                                                </IconButton>) :
                                                                                color === 'Brick Red' ?
                                                                                    (<IconButton size="small" style={{ color: '#9E4429' }}
                                                                                        aria-label="favorite">
                                                                                        <FiberManualRecordIcon />
                                                                                    </IconButton>) :
                                                                                    color === 'Mustard Yellow' ?
                                                                                        (<IconButton size="small" style={{ color: '#EAAA00' }}
                                                                                            aria-label="favorite">
                                                                                            <FiberManualRecordIcon />
                                                                                        </IconButton>) :
                                                                                        color === 'Light Bois De Rose' ?
                                                                                            (<IconButton size="small" style={{ color: '#CD9892' }}
                                                                                                aria-label="favorite">
                                                                                                <FiberManualRecordIcon />
                                                                                            </IconButton>) :
                                                                                            color === 'Brown' ?
                                                                                                (<IconButton size="small" style={{ color: '#5C4033' }}
                                                                                                    aria-label="favorite">
                                                                                                    <FiberManualRecordIcon />
                                                                                                </IconButton>) :
                                                                                                color === 'Dark Green' ?
                                                                                                    (<IconButton size="small" style={{ color: '#313E34' }}
                                                                                                        aria-label="favorite">
                                                                                                        <FiberManualRecordIcon />
                                                                                                    </IconButton>) :
                                                                                                    color === 'Light Navy' ?
                                                                                                        (<IconButton size="small" style={{ color: '#010080' }}
                                                                                                            aria-label="favorite">
                                                                                                            <FiberManualRecordIcon />
                                                                                                        </IconButton>) :
                                                                                                        color === 'Neon Fuschia' ?
                                                                                                            (<IconButton size="small" style={{ color: '#FE4164' }}
                                                                                                                aria-label="favorite">
                                                                                                                <FiberManualRecordIcon />
                                                                                                            </IconButton>) :
                                                                                                            color === 'Burgundy' ?
                                                                                                                (<IconButton size="small" style={{ color: '#610220' }}
                                                                                                                    aria-label="favorite">
                                                                                                                    <FiberManualRecordIcon />
                                                                                                                </IconButton>) :
                                                                                                                color === 'Pistachio' ?
                                                                                                                    (<IconButton size="small" style={{ color: '#BDFBBE' }}
                                                                                                                        aria-label="favorite">
                                                                                                                        <FiberManualRecordIcon />
                                                                                                                    </IconButton>) :
                                                                                                                    color === 'Cherry' ?
                                                                                                                        (<IconButton size="small" style={{ color: '#A5003D' }}
                                                                                                                            aria-label="favorite">
                                                                                                                            <FiberManualRecordIcon />
                                                                                                                        </IconButton>) :
                                                                                                                        color === 'Off White' ?
                                                                                                                            (<IconButton size="small" style={{ color: '#F8F0E3' }}
                                                                                                                                aria-label="favorite">
                                                                                                                                <FiberManualRecordIcon />
                                                                                                                            </IconButton>) :
                                                                                                                            color === 'Pink' ?
                                                                                                                                (<IconButton size="small" style={{ color: '#FEBAD3' }}
                                                                                                                                    aria-label="favorite">
                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                </IconButton>) :
                                                                                                                                color === 'Purple' ?
                                                                                                                                    (<IconButton size="small" style={{ color: '#C99AF8' }}
                                                                                                                                        aria-label="favorite">
                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                    </IconButton>) :
                                                                                                                                    color === 'Dark Purple' ?
                                                                                                                                        (<IconButton size="small" style={{ color: '#81007F' }}
                                                                                                                                            aria-label="favorite">
                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                        </IconButton>) :
                                                                                                                                        color === 'Red' ?
                                                                                                                                            (<IconButton size="small" style={{ color: '#FE0500' }}
                                                                                                                                                aria-label="favorite">
                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                            </IconButton>) :
                                                                                                                                            color === 'Salmon' ?
                                                                                                                                                (<IconButton size="small" style={{ color: '#FA7E72' }}
                                                                                                                                                    aria-label="favorite">
                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                </IconButton>) :
                                                                                                                                                color === 'Turquoise' ?
                                                                                                                                                    (<IconButton size="small" style={{ color: '#00FFEF' }}
                                                                                                                                                        aria-label="favorite">
                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                    </IconButton>) :
                                                                                                                                                    color === 'Yellow' ?
                                                                                                                                                        (<IconButton size="small" style={{ color: '#FDFC35' }}
                                                                                                                                                            aria-label="favorite">
                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                        </IconButton>) :
                                                                                                                                                        color === 'Navy' ?
                                                                                                                                                            (<IconButton size="small" style={{ color: '#1C1E47' }}
                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                            </IconButton>) :
                                                                                                                                                            color === 'Green' ?
                                                                                                                                                                (<IconButton size="small" style={{ color: '#008540' }}
                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                </IconButton>) :
                                                                                                                                                                color === 'Beige' ?
                                                                                                                                                                    (<IconButton size="small" style={{ color: '#BF9E7F' }}
                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                    color === 'Blue' ?
                                                                                                                                                                        (<IconButton size="small" style={{ color: '#8BCDF0' }}
                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                        color === 'Orange' ?
                                                                                                                                                                            (<IconButton size="small" style={{ color: '#FE6023' }}
                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                            color === 'Lightgray' ?
                                                                                                                                                                                (<IconButton size="small" style={{ color: '#B2B2B2' }}
                                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                                </IconButton>) :
                                                                                                                                                                                color === 'Darkgray' ?
                                                                                                                                                                                    (<IconButton size="small" style={{ color: '#404040' }}
                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                                    color === 'Fuchsia' ?
                                                                                                                                                                                        (<IconButton size="small" style={{ color: '#E60081' }}
                                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                                        color === 'Blue Grey' ?
                                                                                                                                                                                            (<IconButton size="small" style={{ color: '#B5C9E1' }}
                                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                                            color === 'Bois De Rose' ?
                                                                                                                                                                                                (<IconButton size="small" style={{ color: '#864650' }}
                                                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                                                </IconButton>) :
                                                                                                                                                                                                color === 'Blue Petrol' ?
                                                                                                                                                                                                    (<IconButton size="small" style={{ color: '#0F4B63' }}
                                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                                                    (<IconButton size="small" style={{ color: `${color}` }}
                                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                                    </IconButton>)
                                                                    ) : null))}
                                                            </div>
                                                            {/* <div className="ratings mt-auto">
                                                                <div className="rating-outer">
                                                                    <div className="rating-inner" style={{
                                                                        width: `${(product.ratings / 5) * 100}% `
                                                                    }}></div>
                                                                </div>
                                                                <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )
                )
        ) :
            (
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div /*className={`col-sm-12 col-md-6 col-lg-${col} my-3`}*/>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                            <div className="product" id={`product${id}`}>
                                <div className="make3D" id={`make3D${id}`}
                                    onMouseOver={() => Hover(id)}
                                    onMouseLeave={() => Leave(id)}>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                                    <div className="product-front">
                                        <div className="shadow"></div>
                                        <img
                                            className="card-img-top mx-auto"
                                            src={product.images.length > 0 ? product.images[0].url : null}
                                        />
                                        {/* <FavoriteBorderIcon id="favorite" className={classes.Favorite}/> */}
                                        <div className="image_overlay"></div>
                                        {product.status === 'OUTOFSTOCK' ?
                                            (
                                                <IconButton aria-label="add to favorites" style={{ position: 'absolute' }}
                                                    className={classes.StatusOut} >
                                                    OUTOFSTOCK
                                                </IconButton>
                                            ) : null}
                                        <IconButton aria-label="add to favorites" style={{ position: 'absolute' }}
                                            onClick={handleClickOpenSignin} className={classes.Favorite} >
                                            <FavoriteIcon />
                                        </IconButton>
                                        {/* <Link to="/cart" style={{textDecoration: 'none'}}>
                                <div className="add_to_cart">Add to cart</div>
                            </Link> */}
                                        <Link to={`/product/${product._id}`} style={{ textDecoration: 'none' }}>
                                            <div className="view_gallery">View Details</div>
                                        </Link>
                                        {/* <Link to={`/product/${product._id}`} id="view_btn" className="btn btn-block">View Details</Link> */}
                                        <div className="stats">
                                            <div className="stats-container">
                                                <span style={{ fontFamily: 'Roboto,sans-serif' }} className="product_name"> <Link to={`/product/${product._id}`}>
                                                    {product.name}
                                                    {/* {product.name.length > 18 ? product.name.substring(0, 18) : product.name} */}
                                                </Link></span>
                                                {/* <br /> */}
                                                {/* <span style={{ fontFamily: 'Roboto,sans-serif' }} className="subproduct_name">{product.category}</span> */}
                                                <br />
                                                <div style={{ display: "block" }}>
                                                    {
                                                        product.discountRate && product.discountRate > 0 ? (
                                                            <>
                                                                <span className="product_price" style={{ textDecoration: "line-through", color: '#808080', fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }}>LBP {fCurrency(product.price)}</span>
                                                                <span className="product_price" style={{ color: "#a2d7e9", fontFamily: 'Roboto,sans-serif', marginBottom: "10px", marginLeft: "10px" }}>LBP {fCurrency(product.price - (product.price * product.discountRate / 100))}</span>
                                                            </>
                                                        ) : (
                                                            <span style={{ fontFamily: 'Roboto,sans-serif', marginBottom: "10px" }} className="product_price">LBP {fCurrency(product.price)}</span>
                                                        )
                                                    }
                                                </div>
                                                <div className="product-options">
                                                    <div className="colors">
                                                        {uniqueChars && uniqueChars.map((color) => (
                                                            color && color !== '' ? (
                                                                color === 'White' ?
                                                                    (<IconButton size="small" style={{ color: '#FFF', border: '1px solid #000', width: '1.1rem', height: '1.1rem' }}
                                                                        aria-label="favorite">
                                                                        <FiberManualRecordIcon />
                                                                    </IconButton>) :
                                                                    color === 'Emerald Green' ?
                                                                        (<IconButton size="small" style={{ color: '#009A76' }}
                                                                            aria-label="favorite">
                                                                            <FiberManualRecordIcon />
                                                                        </IconButton>) :
                                                                        color === 'Brick Red' ?
                                                                            (<IconButton size="small" style={{ color: '#9E4429' }}
                                                                                aria-label="favorite">
                                                                                <FiberManualRecordIcon />
                                                                            </IconButton>) :
                                                                            color === 'Mustard Yellow' ?
                                                                                (<IconButton size="small" style={{ color: '#EAAA00' }}
                                                                                    aria-label="favorite">
                                                                                    <FiberManualRecordIcon />
                                                                                </IconButton>) :
                                                                                color === 'Light Bois De Rose' ?
                                                                                    (<IconButton size="small" style={{ color: '#CD9892' }}
                                                                                        aria-label="favorite">
                                                                                        <FiberManualRecordIcon />
                                                                                    </IconButton>) :
                                                                                    color === 'Brown' ?
                                                                                        (<IconButton size="small" style={{ color: '#5C4033' }}
                                                                                            aria-label="favorite">
                                                                                            <FiberManualRecordIcon />
                                                                                        </IconButton>) :
                                                                                        color === 'Dark Green' ?
                                                                                            (<IconButton size="small" style={{ color: '#313E34' }}
                                                                                                aria-label="favorite">
                                                                                                <FiberManualRecordIcon />
                                                                                            </IconButton>) :
                                                                                            color === 'Light Navy' ?
                                                                                                (<IconButton size="small" style={{ color: '#010080' }}
                                                                                                    aria-label="favorite">
                                                                                                    <FiberManualRecordIcon />
                                                                                                </IconButton>) :
                                                                                                color === 'Neon Fuschia' ?
                                                                                                    (<IconButton size="small" style={{ color: '#FE4164' }}
                                                                                                        aria-label="favorite">
                                                                                                        <FiberManualRecordIcon />
                                                                                                    </IconButton>) :
                                                                                                    color === 'Burgundy' ?
                                                                                                        (<IconButton size="small" style={{ color: '#610220' }}
                                                                                                            aria-label="favorite">
                                                                                                            <FiberManualRecordIcon />
                                                                                                        </IconButton>) :
                                                                                                        color === 'Pistachio' ?
                                                                                                            (<IconButton size="small" style={{ color: '#BDFBBE' }}
                                                                                                                aria-label="favorite">
                                                                                                                <FiberManualRecordIcon />
                                                                                                            </IconButton>) :
                                                                                                            color === 'Cherry' ?
                                                                                                                (<IconButton size="small" style={{ color: '#A5003D' }}
                                                                                                                    aria-label="favorite">
                                                                                                                    <FiberManualRecordIcon />
                                                                                                                </IconButton>) :
                                                                                                                color === 'Off White' ?
                                                                                                                    (<IconButton size="small" style={{ color: '#F8F0E3' }}
                                                                                                                        aria-label="favorite">
                                                                                                                        <FiberManualRecordIcon />
                                                                                                                    </IconButton>) :
                                                                                                                    color === 'Pink' ?
                                                                                                                        (<IconButton size="small" style={{ color: '#FEBAD3' }}
                                                                                                                            aria-label="favorite">
                                                                                                                            <FiberManualRecordIcon />
                                                                                                                        </IconButton>) :
                                                                                                                        color === 'Purple' ?
                                                                                                                            (<IconButton size="small" style={{ color: '#C99AF8' }}
                                                                                                                                aria-label="favorite">
                                                                                                                                <FiberManualRecordIcon />
                                                                                                                            </IconButton>) :
                                                                                                                            color === 'Dark Purple' ?
                                                                                                                                (<IconButton size="small" style={{ color: '#81007F' }}
                                                                                                                                    aria-label="favorite">
                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                </IconButton>) :
                                                                                                                                color === 'Red' ?
                                                                                                                                    (<IconButton size="small" style={{ color: '#FE0500' }}
                                                                                                                                        aria-label="favorite">
                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                    </IconButton>) :
                                                                                                                                    color === 'Salmon' ?
                                                                                                                                        (<IconButton size="small" style={{ color: '#FA7E72' }}
                                                                                                                                            aria-label="favorite">
                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                        </IconButton>) :
                                                                                                                                        color === 'Turquoise' ?
                                                                                                                                            (<IconButton size="small" style={{ color: '#00FFEF' }}
                                                                                                                                                aria-label="favorite">
                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                            </IconButton>) :
                                                                                                                                            color === 'Yellow' ?
                                                                                                                                                (<IconButton size="small" style={{ color: '#FDFC35' }}
                                                                                                                                                    aria-label="favorite">
                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                </IconButton>) :
                                                                                                                                                color === 'Navy' ?
                                                                                                                                                    (<IconButton size="small" style={{ color: '#1C1E47' }}
                                                                                                                                                        aria-label="favorite">
                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                    </IconButton>) :
                                                                                                                                                    color === 'Green' ?
                                                                                                                                                        (<IconButton size="small" style={{ color: '#008540' }}
                                                                                                                                                            aria-label="favorite">
                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                        </IconButton>) :
                                                                                                                                                        color === 'Beige' ?
                                                                                                                                                            (<IconButton size="small" style={{ color: '#BF9E7F' }}
                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                            </IconButton>) :
                                                                                                                                                            color === 'Blue' ?
                                                                                                                                                                (<IconButton size="small" style={{ color: '#8BCDF0' }}
                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                </IconButton>) :
                                                                                                                                                                color === 'Orange' ?
                                                                                                                                                                    (<IconButton size="small" style={{ color: '#FE6023' }}
                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                    color === 'Lightgray' ?
                                                                                                                                                                        (<IconButton size="small" style={{ color: '#B2B2B2' }}
                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                        color === 'Darkgray' ?
                                                                                                                                                                            (<IconButton size="small" style={{ color: '#404040' }}
                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                            color === 'Fuchsia' ?
                                                                                                                                                                                (<IconButton size="small" style={{ color: '#E60081' }}
                                                                                                                                                                                    aria-label="favorite">
                                                                                                                                                                                    <FiberManualRecordIcon />
                                                                                                                                                                                </IconButton>) :
                                                                                                                                                                                color === 'Blue Grey' ?
                                                                                                                                                                                    (<IconButton size="small" style={{ color: '#B5C9E1' }}
                                                                                                                                                                                        aria-label="favorite">
                                                                                                                                                                                        <FiberManualRecordIcon />
                                                                                                                                                                                    </IconButton>) :
                                                                                                                                                                                    color === 'Bois De Rose' ?
                                                                                                                                                                                        (<IconButton size="small" style={{ color: '#864650' }}
                                                                                                                                                                                            aria-label="favorite">
                                                                                                                                                                                            <FiberManualRecordIcon />
                                                                                                                                                                                        </IconButton>) :
                                                                                                                                                                                        color === 'Blue Petrol' ?
                                                                                                                                                                                            (<IconButton size="small" style={{ color: '#0F4B63' }}
                                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                                            </IconButton>) :
                                                                                                                                                                                            (<IconButton size="small" style={{ color: `${color}` }}
                                                                                                                                                                                                aria-label="favorite">
                                                                                                                                                                                                <FiberManualRecordIcon />
                                                                                                                                                                                            </IconButton>)
                                                            ) : null))}
                                                    </div>
                                                    {/* <div className="ratings">
                                                        <div className="rating-outer">
                                                            <div className="rating-inner" style={{
                                                                width: `${(product.ratings / 5) * 100}% `
                                                            }}></div>
                                                        </div>
                                                        <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Dialog
                        color="black"
                        open={openSignin}
                        keepMounted
                        onClose={handleCloseSignin}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle className="dialogheart" id="alert-dialog-slide-title">{"Add To Favorites?"}</DialogTitle>
                        <DialogContent className="dialogheart" >
                            <DialogContentText id="alert-dialog-slide-description">
                                <div style={{ padding: 20, color: 'black' }}>
                                    Please Login to your account to enable add to favorites button
                                </div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="dialogheart">
                            <Button onClick={handleCloseSignin} color="black">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            )
    )
}
export default Posts;
