import React, { Fragment, useState, useEffect } from 'react';
import MetaData from '../layout/MetaData';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePassword,
  clearErrors,
} from '../../actions/userActions';
import { UPDATE_PASSWORD_RESET } from '../../constants/userConstants';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
const UpdatePassword = ({ history }) => {
  const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')));
  const user = User ? User.user : null
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const alert = useAlert();
  const dispatch = useDispatch();
  const { error, isUpdated, loading } = useSelector((state) => state.user);
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error, history, isUpdated]);
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('oldPassword', oldPassword);
    formData.set('password', password);
    const data = await dispatch(updatePassword(formData, user));
    if (data.success) {
      alert.success('Password updated successfully');
      history.push('/me/update');
      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    } else {
      alert.error(data.message);
    }
  };
  return (
    <div style={{ marginTop: "180px" }}>
      <MetaData title={'Change Password'} />
      <div className="row wrapper" style={{ marginTop: '100px' }}>
        <div className="col-10 col-lg-5">
          <form className="shadow-lg" onSubmit={submitHandler}>
            <Typography variant="h4">
              New Password
            </Typography>
            <div className="form-group">
              <label
                className="typo"
                for="old_password_field">Old Password</label>
              <input
                type="password"
                id="old_password_field"
                className="form-control"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label
                className="typo"
                for="new_password_field">New Password</label>
              <input
                type="password"
                id="new_password_field"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {/* <button type="submit" className="btn update-btn btn-block mt-4 mb-3" disabled={loading ? true : false} >Update Password</button> */}
            <Button
              type="submit"
              disabled={loading ? true : false}
              variant="contained" className="btn btn-block py-3"  >Update Password</Button>
          </form>
        </div>
      </div>
    </div>
  )
}
export default UpdatePassword