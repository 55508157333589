import React, { Fragment, useState, useEffect } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range)
const Ranges = ({ price, setPrice, maxPrice }) => {
    const [mPrice, setMPrice] = useState(-1);
    if (mPrice === -1) {
        if (maxPrice % 1 === 0) {
            setMPrice(maxPrice);
        } else {

        }
    }
    return (
        <>
            <Range
                min={0}
                max={mPrice}
                step={1}
                defaultValue={[1, mPrice]}
                tipFormatter={value => `${value}`}
                tipProps={{
                    placement: "top",
                    visible: true
                }}
                value={price}
                onChange={price => setPrice(price)}
            />
        </>
    )
}
export default Ranges;
