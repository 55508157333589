import {
  ADD_TO_CART,
  REMOVE_ITEM_CART,
  SAVE_SHIPPING_INFO,
  EMPTY_CART,
} from '../constants/cartConstants';

export const cartReducer = (
  state = { cartItems: [], shippingInfo: {} },
  action
) => {
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      const isItemExist = state.cartItems.find(
        (i) => i.product === item.product
      );
      if (isItemExist) {
        if (state.cartItems.some(cart => cart.color === item.color) && state.cartItems.some(cart => cart.size === item.size)) {
          return {
            ...state,
            cartItems: state.cartItems.map((i) =>
              i.product === item.product && i.color === item.color && i.size === item.size ? item : i
            ),
          }
        }
        else {
          return {
            ...state,
            cartItems: [...state.cartItems, item],
          }
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      }
    case REMOVE_ITEM_CART:
      return {
        ...state,
        //here action.payload is the id of the product i want tp delete
        cartItems: state.cartItems.filter((i) => i.color !== action.payload.color || i.product !== action.payload.id),
      };
    case SAVE_SHIPPING_INFO:
      return {
        ...state,
        shippingInfo: action.payload,
      };
    case EMPTY_CART:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};
