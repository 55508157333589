import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useLocation, useSearchParams } from "react-router-dom";
import Posts from "../Posts/Posts";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormGroup from "@material-ui/core/FormGroup";
import useStyles from "./styles";
import Pagination from "react-js-pagination";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import "./productsStyle.css";
import { getProducts } from "../../actions/productActions";
import { useAlert } from "react-alert";
import Check from "../Checkbox";
import CategoryCheck from "../CategoryCheckbox";
import Color from "../ColorsFilters";
import Size from "../SizeFilters";
import CollectionTypes from "../CollectionFilters";
import Ranges from "../Range/Range";
import Loader from "../layout/Loader";
import { OverlayTrigger } from "react-bootstrap";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const Products = ({ match }) => {
  const history = useHistory();
  const location = useLocation();

  let query = useQuery();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const {
    loading,
    products,
    error,
    productsCount,
    maxPrice,
    resPerPage,
    filteredProductCount,
  } = useSelector((state) => state.products);

  const [currentPage, setCurrentPage] = useState(query.get("currentPage") || 1);
  const [category, setCategory] = useState(match.params.category || "");
  const [subcategory, setSubcategory] = useState(
    query.get("subcategory") || ""
  );
  const [subsubcategory, setSubsubcategory] = useState(
    query.get("subsubcategory") || ""
  );
  const [rating, setRating] = useState(query.get("rating") || 0);
  const [initialState, setInitialState] = useState(true);
  const [color, setColor] = useState(query.get("color") || "");
  const [size, setSize] = useState(query.get("size") || "");
  const [collectiontype, setCollectiontype] = useState(
    query.get("collectiontype") || ""
  );
  const [mPrice, setMPrice] = useState(-1);
  let priceArray = query.get("price")
    ? query.get("price").split(",")
    : [0, 2000000000];
  const [price, setPrice] = useState([priceArray[0], priceArray[1]]);

  const alert = useAlert();
  const dispatch = useDispatch();
  const keyword = match.params.keyword;

  let path = `${location.pathname}?initialState=${initialState}&subcategory=${subcategory}&category=${category}&subsubcategory=${subsubcategory}&currentPage=${currentPage}&price=${price}&category=${category}&subcategory=${subcategory}&rating=${rating}&color=${color}&size=${size}&collectiontype=${collectiontype}`;

  useEffect(() => {
    if (error) {
      return alert.error(error);
    }
    history.push(path);
    dispatch(
      getProducts(
        initialState,
        keyword,
        currentPage,
        price,
        category,
        subcategory,
        subsubcategory,
        rating,
        color,
        size,
        collectiontype
      )
    );
    if (products) {
      if (mPrice !== -1) {
        products.forEach((p) => {
          if (p.price > mPrice) {
            setMPrice(p.price);
          }
        });
        setPrice([0, mPrice]);
      }
    }
  }, [
    dispatch,
    keyword,
    alert,
    error,
    currentPage,
    price,
    category,
    rating,
    subcategory,
    subsubcategory,
    color,
    size,
    collectiontype,
  ]);
  function setCurrentpageNo(pageNumber) {
    setCurrentPage(pageNumber);
  }
  let count = productsCount;
  if (keyword) {
    count = filteredProductCount;
  }
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root} style={{ marginTop: 120 }}>
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={3}
            style={{ padding: 20, marginRight: 10 }}
            className={classes.filter}
            justify="center"
          >
            <Typography className={classes.heading}>Price</Typography>
            <br />
            <br />
            <Ranges price={price} setPrice={setPrice} maxPrice={maxPrice} />
            <br />
            {/* {CATEGORY ? null :
                            <Accordion elevation={0}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Category</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup column>
                                        <CategoryCheck setCategory={setCategory} setCat={setCat} category={category} />
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        } */}
            {category ? (
              <Accordion elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{ fontFamily: "Roboto,sans-serif" }}
                    className={classes.heading}
                  >
                    Sub Category
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup column>
                    <Check
                      category={category}
                      setSubCategory={setSubcategory}
                      subCategory={subcategory}
                      setSubsubcategory={setSubsubcategory}
                      subsubcategory={subsubcategory}
                    />
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            ) : null}
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Collection</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup row>
                  <CollectionTypes setCollectiontype={setCollectiontype} />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Colors</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup row>
                  <Color setColor={setColor} />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Size</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup row>
                  <Size setSize={setSize} category={category} />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Ratings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup row>
                  <ul className="pl-0">
                    {[5, 4, 3, 2, 1].map((star) => (
                      <li
                        style={{
                          cursor: "pointer",
                          listStyleType: "none",
                        }}
                        key={star}
                        onClick={() => {
                          setRating(star);
                          setInitialState(false);
                        }}
                        className={rating === star ? "ratingChecked" : null}
                      >
                        <div className={"rating-outer"}>
                          <div
                            className={"rating-inner"}
                            style={{ width: `${star * 20}%` }}
                          ></div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid
            container
            sm={12}
            md={9}
            spacing={3}
            id="productContainer"
            className={classes.productContainer}
            style={{ marginTop: 20 }}
          >
            {loading ? (
              <Loader />
            ) : products.length > 0 ? (
              products &&
              products.map((product, index) => (
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <Posts key={product._id} product={product} id={index} />
                </Grid>
              ))
            ) : (
              <Typography
                className="noproductfound"
                component="h6"
                variant="h6"
                align="center"
              >
                Sorry, no product was found
              </Typography>
            )}
          </Grid>
          {/* </Grid> */}
        </Grid>
      </Container>
      {products.length > 0
        ? resPerPage < count && (
            <div className="d-flex justify-content-center mt-5">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resPerPage}
                totalItemsCount={filteredProductCount}
                onChange={setCurrentpageNo}
                nextPageText={"Next"}
                prevPageText={"Prev"}
                firstPageText={"First"}
                lastPageText={"Last"}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )
        : null}
    </>
  );
};
export default Products;
