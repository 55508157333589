import React, { useEffect, useState } from 'react';
import { Grid } from "@material-ui/core";
import './favor.css'
import { useDispatch, useSelector } from "react-redux";
import { getWishlist } from "../../actions/productActions";
import Typography from "@material-ui/core/Typography";
import FavoriteCard from "./favoriteCard";
const Fav = ({ user }) => {
    const [wishlist, setWishlist] = useState([]);
    let wishlistItems = []
    const dispatch = useDispatch();
    wishlist && wishlist.map((item) => {
        item && item.favoritesItems.map(wish => {
            wishlistItems.push(wish)
        })
    })
    useEffect(async () => {
        const wishlist = await dispatch(getWishlist(user && user._id));
        setWishlist(wishlist);
    }, [dispatch, user]);
    return (
        <>
            <Typography className="Favtitle" component="h3" variant="h3" align="center" style={{ marginTop: '150px' }}>
                Saved Products
            </Typography>
            {
                wishlistItems.length === 0 ?
                    <Typography component="h6" variant="h6" align="center" style={{ marginTop: '1%' }}>
                        No Saved Products
                    </Typography> :
                    <Grid container spacing={3} justifyContent="center" style={{ marginTop: '3%', padding: 20 }} align="center">
                        {
                            wishlist && wishlist.map((wish) => (
                                wish.favoritesItems.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <FavoriteCard postId={item.product} id={index} />
                                    </Grid>
                                ))
                            ))
                        }
                    </Grid>
            }
        </>
    )
}
export default Fav;
