import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Carousel } from 'react-bootstrap';
import Slider1 from '../../images/1.jpg'
import Slider2 from '../../images/2.jpg'
import Slider3 from '../../images/3.jpg'
import L1 from './images/L1.jpg'
import T1 from './images/T1.jpg'
import P1 from './images/P1.jpg'
import L2 from './images/L2.jpg'
import T2 from './images/T2.jpg'
import P2 from './images/P2.jpg'
import L3 from './images/L3.jpg'
import T3 from './images/T3.jpg'
import P3 from './images/P3.jpg'

import './Carousel.css'
const CarouselContainer = () => {
  const isMobileScreen = useMediaQuery('(max-width:425px)');
  const isTabletScreen = useMediaQuery('(max-width:800px)');
  const isLaptopScreen = useMediaQuery('(min-width:801px');
  const image = isMobileScreen ? P1 : isTabletScreen ? T1 : L1;
  const image1 = isMobileScreen ? P2 : isTabletScreen ? T2 : L2;
  const image2 = isMobileScreen ? P3 : isTabletScreen ? T3 : L3;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <Carousel className="carousel" align="center" activeIndex={index} onSelect={handleSelect} fade variant="dark">
        <Carousel.Item interval="3000" fade>
          <img
            className="d-block w-100 carouselImage"
            src={image}
            alt="First slider Lingerie Fidele"
          />
        </Carousel.Item>
        <Carousel.Item interval="3000" fade>
          <img
            className="d-block w-100 carouselImage"
            src={image1}
            alt="Second slider Lingerie Fidele"
          />
        </Carousel.Item>
        <Carousel.Item interval="3000" fade>
          <img
            className="d-block w-100 carouselImage"
            src={image2}
            alt="Third slider Lingerie Fidele"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  )
}
export default CarouselContainer;