import React, { Fragment, useState, useEffect } from "react";
import "rc-slider/assets/index.css";
import { useDispatch } from "react-redux";
import { getSubCategories } from "../actions/categoryActions";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const Check = ({
  category,
  setSubCategory,
  subCategory,
  SUBCATEGORY,
  setSubsubcategory,
  CATEGORY,
  SUBSUBCATEGORY,
  subsubcategory,
}) => {
  const allsubsubW = ["Jacket", "Tracksuit", "Legging", "Top", "Pants"];
  const allsubsubM = ["Jacket", "Tracksuit", "Top", "Pants"];
  const allsubsubKT = ["Bath", "Baptism", "Face"];
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const data = await dispatch(getSubCategories(category));
    setCategories(data);
  }, [category, dispatch]);

  const handleChange = (event) => {
    //setSubCategory(event.target.innerHTML)
    setSubCategory(event);
    setSubsubcategory("");
  };

  return (
    <>
      <ToggleButtonGroup
        orientation="vertical"
        value={
          SUBCATEGORY !== undefined && subCategory === ""
            ? SUBCATEGORY
            : SUBCATEGORY === undefined && subCategory !== ""
            ? subCategory
            : subCategory
        }
        size="small"
      >
        {categories.data !== undefined
          ? categories.data.map((category) =>
              category.subcategories.map((sub) => {
                return (
                  <>
                    <ToggleButton
                      value={sub}
                      aria-label={sub}
                      onClick={(e) => handleChange(sub)}
                    >
                      {sub}
                    </ToggleButton>
                    {subCategory &&
                    subCategory === "Sport Wear" &&
                    sub &&
                    sub === "Sport Wear" &&
                    CATEGORY &&
                    CATEGORY === "Women" ? (
                      <ToggleButtonGroup
                        orientation="vertical"
                        style={{
                          backgroundColor: "#FFCA9F",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                        }}
                        value={
                          SUBSUBCATEGORY !== undefined && subsubcategory === ""
                            ? SUBSUBCATEGORY
                            : SUBSUBCATEGORY === undefined &&
                              subsubcategory !== ""
                            ? subsubcategory
                            : subsubcategory
                        }
                        size="small"
                      >
                        {allsubsubW.map((sub) => {
                          return (
                            <>
                              <ToggleButton
                                style={{ color: "white" }}
                                value={sub}
                                aria-label={sub}
                                onClick={(e) => {
                                  setSubsubcategory(sub);
                                  setSubCategory("Sport Wear");
                                }}
                              >
                                {sub}
                              </ToggleButton>
                            </>
                          );
                        })}
                      </ToggleButtonGroup>
                    ) : subCategory &&
                      subCategory === "Sport Wear" &&
                      sub &&
                      sub === "Sport Wear" &&
                      CATEGORY &&
                      CATEGORY === "Men" ? (
                      <ToggleButtonGroup
                        orientation="vertical"
                        style={{
                          backgroundColor: "#FFCA9F",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                        }}
                        value={
                          SUBSUBCATEGORY !== undefined && subsubcategory === ""
                            ? SUBSUBCATEGORY
                            : SUBSUBCATEGORY === undefined &&
                              subsubcategory !== ""
                            ? subsubcategory
                            : subsubcategory
                        }
                        size="small"
                      >
                        {allsubsubM.map((sub) => {
                          return (
                            <>
                              <ToggleButton
                                style={{ color: "white" }}
                                value={sub}
                                aria-label={sub}
                                onClick={(e) => {
                                  setSubsubcategory(sub);
                                  setSubCategory("Sport Wear");
                                }}
                              >
                                {sub}
                              </ToggleButton>
                            </>
                          );
                        })}
                      </ToggleButtonGroup>
                    ) : subCategory &&
                      subCategory === "Towels" &&
                      sub &&
                      sub === "Towels" &&
                      CATEGORY &&
                      CATEGORY === "Babies" ? (
                      <ToggleButtonGroup
                        orientation="vertical"
                        style={{
                          backgroundColor: "#FFCA9F",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                        }}
                        value={
                          SUBSUBCATEGORY !== undefined && subsubcategory === ""
                            ? SUBSUBCATEGORY
                            : SUBSUBCATEGORY === undefined &&
                              subsubcategory !== ""
                            ? subsubcategory
                            : subsubcategory
                        }
                        size="small"
                      >
                        {allsubsubKT.map((sub) => {
                          return (
                            <>
                              <ToggleButton
                                style={{ color: "white" }}
                                value={sub}
                                aria-label={sub}
                                onClick={(e) => {
                                  setSubsubcategory(sub);
                                  setSubCategory("Towels");
                                }}
                              >
                                {sub}
                              </ToggleButton>
                            </>
                          );
                        })}
                      </ToggleButtonGroup>
                    ) : null}
                  </>
                );
              })
            )
          : null}
      </ToggleButtonGroup>
    </>
  );
};
export default Check;
