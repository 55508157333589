import axios from "axios";
import { DELETE_ORDER_FAIL } from "../constants/orderConstants";
import * as api from "../api/index.js";
import {
  ALL_PRODUCTS_REQUEST,
  ALL_PRODUCTS_SUCCESS,
  ALL_PRODUCTS_FAIL,
  ADMIN_PRODUCTS_REQUEST,
  ADMIN_PRODUCTS_SUCCESS,
  ADMIN_PRODUCTS_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_RESET,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_FAIL,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAIL,
  CLEAR_ERRORS,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  ADMIN_DOLLARRATE_REQUEST,
  ADMIN_DOLLARRATE_SUCCESS,
  ADMIN_DOLLARRATE_FAIL,
} from "../constants/productConstants";
import { delWishlist } from "../api/index.js";

export const getProductsSearcNav =
  (keyword = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: ALL_PRODUCTS_REQUEST,
      });
      let link = `/api/v1/products?keyword=${keyword}`;
      const { data } = await api.getProduct(link);
      dispatch({
        type: ALL_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: "error",
        //payload: error.response.data.message
      });
    }
  };
export const getProducts11 =
  (
    initialState,
    SUBCATEGORY,
    keyword = "",
    currenPage = 1,
    price,
    rating = 0,
    color,
    size,
    collectiontype
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ALL_PRODUCTS_REQUEST,
      });
      let link =
        price[0] !== undefined && price[1] !== undefined
          ? `/api/v1/products?keyword=${keyword}&page=${currenPage}&price[lte]=${price[1]}&price[gte]=${price[0]}`
          : `/api/v1/products?keyword=${keyword}&page=${currenPage}`;
      if (initialState) {
        link = link + `&ratings[gte]=${rating}`;
      } else {
        if (rating === 5) {
          link = link + `&ratings[gte]=${rating}`;
        } else {
          link = link + `&ratings[gte]=${rating}&ratings[lte]=${rating + 1}`;
        }
      }
      if (SUBCATEGORY !== undefined) {
        link = link + `&subcategory=${SUBCATEGORY}`;
      }
      if (collectiontype) {
        link = link + `&collectiontype=${collectiontype}`;
      }
      if (color) {
        link = link + `&options[$elemMatch][color]=${color}`;
      }
      const { data } = await api.getProduct(link);
      dispatch({
        type: ALL_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
export const getProducts =
  (
    initialState,
    keyword = "",
    currenPage = 1,
    price,
    category,
    subcategory,
    subsubcategory,
    rating = 0,
    color,
    size,
    collectiontype
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ALL_PRODUCTS_REQUEST,
      });
      let link =
        price[0] !== undefined && price[1] !== undefined
          ? `/api/v1/products?keyword=${keyword}&page=${currenPage}&price[lte]=${price[1]}&price[gte]=${price[0]}`
          : `/api/v1/products?keyword=${keyword}&page=${currenPage}`;
      if (initialState) {
        link = link + `&ratings[gte]=${rating}`;
      } else {
        if (rating === 5) {
          link = link + `&ratings[gte]=${rating}`;
        } else {
          link = link + `&ratings[gte]=${rating}&ratings[lte]=${rating + 1}`;
        }
      }
      if (category) {
        link = link + `&category=${category}`;
      }
      // if (subcategory !== undefined && category === "") {
      //   link = link + `&subcategory=${subcategory}`;
      // }
      // if (
      //   subsubcategory !== undefined &&
      //   subcategory !== undefined &&
      //   category === ""
      // ) {
      //   link = link + `&subsubcategory=${subsubcategory}`;
      // }
      if (category !== "" && subcategory === undefined) {
        link = link + `&category=${category}`;
      }
      if (category !== "" && subcategory !== undefined) {
        link = link + `&category=${category}`;
      }
      if (subcategory) {
        link = link + `&subcategory=${subcategory}`;
      }
      if (subsubcategory) {
        link = link + `&subsubcategory=${subsubcategory}`;
      }
      if (color) {
        link = link + `&options[$elemMatch][color]=${color}`;
      }
      if (size) {
        link = link + `&options[$elemMatch][size]=${size}`;
      }
      if (collectiontype) {
        link = link + `&collectiontype=${collectiontype}`;
      }
      const { data } = await api.getProduct(link);
      dispatch({
        type: ALL_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };
export const getAllColor = () => async (dispatch) => {
  try {
    const { data } = await api.getAllColor();
    return data;
  } catch (error) {}
};
export const getHomePageProducts = () => async (dispatch) => {
  try {
    const { data } = await api.getHomePageProducts();
    return data;
  } catch (error) {
    dispatch({
      type: ALL_PRODUCTS_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const newProduct = (productData, id) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });
    const { data } = await api.newProduct(productData, id);
    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_DETAILS_REQUEST,
    });
    const { data } = await api.getProductDetails(id);
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product,
    });
    return data.product;
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};
// Delete product (Admin)
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    const { data } = await api.deleteProduct(id);
    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};
//Update product (admin)
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });
    const { data } = await api.updateProduct(id, productData);
    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const newReview = (user, reviewData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });
    const { data } = await api.nReview(user._id, user.name, reviewData);
    dispatch({
      type: NEW_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: NEW_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const getAdminProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PRODUCTS_REQUEST,
    });
    const { data } = await api.getAdminProducts();
    dispatch({
      type: ADMIN_PRODUCTS_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCTS_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const getDollarRate = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_DOLLARRATE_REQUEST,
    });
    const { data } = await api.getDollarRate();
    //return data;
    dispatch({
      type: ADMIN_DOLLARRATE_SUCCESS,
      payload: data.dr,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DOLLARRATE_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const inserUpdateDollarRate = (value) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_DOLLARRATE_REQUEST,
    });
    const { data } = await api.inserUpdateDollarRate(value);
    return data;
  } catch (error) {
    dispatch({
      type: ADMIN_DOLLARRATE_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const insertOrUpdateDeliveryRate = (value) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_DOLLARRATE_REQUEST,
    });
    const { data } = await api.inserUpdateDeliveryRate(value);
    return data;
  } catch (error) {
    dispatch({
      type: ADMIN_DOLLARRATE_FAIL,
      payload: error.response.data.message,
    });
  }
};
// Get product reviews
export const getProductReviews = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_REVIEWS_REQUEST });
    const { data } = await api.getProductReviews(id);
    dispatch({
      type: GET_REVIEWS_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: GET_REVIEWS_FAIL,
      payload: error.response.data.message,
    });
  }
};
// Delete product review
export const deleteReview = (id, productId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_REVIEW_REQUEST });
    const { data } = await api.deleteReview(id, productId);
    dispatch({
      type: DELETE_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};
//clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
//will postpone it
export const deleteOption = (optionId) => async (dispatch) => {
  try {
    const optionID = {
      optionId: optionId.optionId,
    };
    const { data } = await api.delOption(optionId, optionID);
    return data;
  } catch (error) {}
};
export const addToWishlist = (id, formData) => async (dispatch) => {
  try {
    const { data } = await api.AddToWishlist(id, formData);
    return data;
  } catch (error) {}
};
export const deleteWish = (userId, postId) => async (dispatch) => {
  try {
    const { data } = await api.delWishlist(userId, postId);
    return data;
  } catch (error) {}
};
export const getWishlist = (id) => async (dispatch) => {
  try {
    const { data } = await api.gWishlist(id);
    return data;
  } catch (error) {}
};
