import React, { useEffect, useState } from 'react';
import './style.scss'
import { Button, Checkbox, TextField } from "@material-ui/core";
// import TextField from '@mui/material/TextField';
import Grid from "@material-ui/core/Grid";
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { getUserCart } from "../../../actions/cartActions";


const AddressCart = ({ next, user, product }) => {
    const [productData, setProductData] = useState({ firstName: product.firstName, lastName: product.lastName, phoneNumber: product.phoneNumber, city: product.city, street: product.street, building: product.building, Floor: product.Floor, Payment: 'LBP' });
    const methods = useForm();
    const dispatch = useDispatch();
    const { cartItems } = useSelector(state => state.cart)
    const [userCart, setUserCart] = useState([]);
    const [paymentMEthod, setPaymentMEthod] = useState(false);

    let result = userCart.filter(o1 => cartItems.some(o2 => o1.product === o2.product && o1.color === o2.color && o1.size === o2.size))

    result && result.map((item) => {
        const index = cartItems.findIndex(key => key.product === item.product && key.color === item.color && key.size === item.size);
        cartItems.splice(index, 1);
    })

    const itemsPrice = cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
        : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
            : userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.price - (item.price * item.discountRate / 100)) : item.quantity * item.price), 0)
    const totalPrice = itemsPrice.toFixed(2)


    const dollarItemsPrice = cartItems.length !== 0 && userCart.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0) + userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
        : cartItems.length !== 0 ? cartItems.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
            : userCart.reduce((acc, item) => acc + (item.discountRate && item.isChecked ? item.quantity * (item.dollarPrice - (item.dollarPrice * item.discountRate / 100)) : item.quantity * item.dollarPrice), 0)
    const dollarTotalPrice = dollarItemsPrice.toFixed(2)

    const handleNext = (productData) => {
        const data = {
            itemsPrice: itemsPrice.toFixed(2),
            totalPrice,
            dollarItemsPrice: dollarItemsPrice.toFixed(2),
            dollarTotalPrice,
        }
        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        next(productData)
    }
    const funcGet = async () => {
        const data = await dispatch(getUserCart(user));
        if (data) {
            if (data.cart !== null && data.cart !== undefined) {
                setUserCart(data.cart.cartItems);
            }
        }
    }
    useEffect(() => {
        funcGet();
    }, [user]);
    
    return (
        <>
            <span className="addtitle" style={{ fontFamily: 'Roboto, sans-serif', fontSize: 25, fontWeight: 700 }}>Address</span>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((data) => handleNext(productData))}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <TextField
                                required
                                id="outlined-required"
                                label="First Name"
                                variant="outlined"
                                style={{ width: '100%', marginLeft: '0.3em', backgroundColor: "#fff1e7" }}
                                value={productData.firstName}
                                helperText="Required"
                                onChange={(e) => setProductData({ ...productData, firstName: e.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={1} lg={1} />

                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Last Name"
                                variant="outlined"
                                style={{ width: '100%', backgroundColor: "#fff1e7" }}
                                helperText="Required"
                                value={productData.lastName}
                                name='lastName'
                                onChange={(e) => setProductData({ ...productData, lastName: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                style={{ width: '100%', backgroundColor: "#fff1e7" }}
                                required
                                id="outlined-required"
                                label="Phone Number"
                                variant="outlined"
                                helperText="Required"
                                value={productData.phoneNumber}
                                name='phoneNumber'
                                onChange={(e) => setProductData({ ...productData, phoneNumber: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                style={{ width: '100%', backgroundColor: "#fff1e7" }}
                                required
                                id="outlined-required"
                                label="City"
                                variant="outlined"
                                helperText="Required"
                                value={productData.city}
                                name='city'
                                onChange={(e) => setProductData({ ...productData, city: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                style={{ width: '100%', backgroundColor: "#fff1e7" }}
                                required
                                id="outlined-required"
                                label="Street"
                                variant="outlined"
                                helperText="Required"
                                value={productData.street}
                                name='street'
                                onChange={(e) => setProductData({ ...productData, street: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={5}>
                            <TextField
                                required
                                id="outlined-required"
                                label="building"
                                variant="outlined"
                                style={{ width: '100%', backgroundColor: "#fff1e7" }}
                                helperText="Required"
                                value={productData.building}
                                name='building'
                                onChange={(e) => setProductData({ ...productData, building: e.target.value })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={1} />

                        <Grid item xs={12} sm={12} md={6}>
                            <TextField
                                style={{ width: "100%", backgroundColor: "#fff1e7" }}
                                id="outlined-required"
                                variant="outlined"
                                label="Floor"
                                value={productData.Floor}
                                name='Floor'
                                onChange={(e) => setProductData({ ...productData, Floor: e.target.value })}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} style={{ marginTop: "15px" }}>
                        <span style={{ fontSize: "15px" }}> Payment: </span>
                        <select style={{ fontSize: "14px" }} onChange={(e) => setProductData({ ...productData, Payment: e.target.value })} required>
                            <option key={'LBP'} value={'LBP'} >LBP</option>
                            <option key={'USD'} value={'USD'} >USD</option>
                        </select>
                    </Grid> */}
                    <Grid container xs={12} sm={12} md={12} display="flex" justifyContent="center" style={{ border: "0px solid green" }} alignItems="flex-end">
                        <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center" style={{ border: "0px solid green" }} alignItems="flex-end">
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display="flex" justifyContent="center" style={{ border: "0px solid green" }} alignItems="flex-end">

                            <Button type="submit" style={{ border: "0px solid green" }} className="Next">NEXT</Button>
                        </Grid>  </Grid>
                    {/* <Grid item xs={12} sm={12} md={6}>
                        <Button type="submit" className="Next">NEXT</Button>
                    </Grid> */}
                </form>
            </FormProvider>
        </>
    )
}

export default AddressCart;
