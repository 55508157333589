import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {productReducer,newProductReducer,productsReducer, productDetailsReducer,newReviewReducer,productReviewsReducer,reviewReducer} from './reducers/productReducers'
import { authReducer, userReducer, forgotPasswordReducer,allUsersReducer } from './reducers/userReducers';
import { cartReducer } from './reducers/cartReducers';
import {orderReducer, newOrderReducer,myOrdersReducer,orderDetailsReducer, allOrdersReducer } from './reducers/orderReducers';

const reducer = combineReducers({
    products: productsReducer,
    productDetails: productDetailsReducer,
    newProduct:newProductReducer,
    product:productReducer,
    auth: authReducer,
    user: userReducer,
    forgotPassword:forgotPasswordReducer,
    cart:cartReducer,
    newOrder:newOrderReducer,
    myOrders:myOrdersReducer,
    orderDetails:orderDetailsReducer,
    allOrders:allOrdersReducer,
    newReview:newReviewReducer,
    review:reviewReducer,
    productReviews:productReviewsReducer,
    allUsers:allUsersReducer,
    order:orderReducer
    
})



let initialState = {
    cart: {
        cartItems: localStorage.getItem('cartItems')
            ? JSON.parse(localStorage.getItem('cartItems'))
            : [],
            shippingInfo:localStorage.getItem('shippingInfo')
            ? JSON.parse(localStorage.getItem('shippingInfo'))
            : {}
    }
}

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...
    middleware)))

export default store;