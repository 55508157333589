import React , { Fragment, useState, useEffect }  from 'react'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import { useDispatch, useSelector } from 'react-redux'
import {getUserCart} from "../actions/cartActions";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import {Link} from "react-router-dom";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";


const IconButtonCart = ({userId, cartItems}) => {

    const [userCart, setUserCart] = useState([]);

    const dispatch = useDispatch();

    useEffect(async () => {
        const data = await dispatch(getUserCart(userId));
        if(data)
        {
            if(data.cart !==null && data.cart !==undefined)
            {
                setUserCart(data.cart.cartItems);
            }
        }


    }, [userId]);

    let result = userCart.filter(o1 => cartItems.some(o2 => o1.product === o2.product && o1.color === o2.color && o1.size === o2.size))

    result && result.map((item)=>{
        const index = cartItems.findIndex(key => key.product === item.product && key.color === item.color && key.size === item.size);
        cartItems.splice(index,1);
    })

    return (

        <>
            <IconButton aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={userCart  && userCart.length>0?cartItems.length + userCart.length: cartItems.length } color="secondary">
                    <Link to="/cartFidele">
                        <ShoppingBasketIcon />
                    </Link>
                </Badge>
            </IconButton>
        </>

    )
}

export default IconButtonCart;