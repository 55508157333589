import React, { useEffect, useState } from 'react';
import './Stripestyle.scss'
import { ArrowLeft } from 'react-feather';
import {
    Elements,
    CardElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { createOrder } from "../../../actions/orderActions";
import { useAlert } from "react-alert";
import { Button, CircularProgress } from "@material-ui/core";
import { deleteCart, getUserCart } from "../../../actions/cartActions";
import * as api from '../../../api/index';
import ReactTooltip from "react-tooltip";
import numeral from 'numeral';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            'color': '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

const Stripe = ({ backStep, product, resetStepper, history, next, nextOrder }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [paymentType, setPaymentType] = useState('Cash On Deliver')
    const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    const [orderInfo, setInfo] = useState(JSON.parse(sessionStorage.getItem('orderInfo')));
    const { cartItems } = useSelector(state => state.cart);
    const [userCart, setUserCart] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cartId, setCartId] = useState('')

    let USERCART = [];

    const fCurrency = (number) => {
        return numeral((Math.trunc(number / 1000) * 1000)).format(Number.isInteger((Math.trunc(number / 1000) * 1000)) ? '0,0' : '0,0.00');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        getOriginalDeliveryRate();
        const data = await dispatch(getUserCart(user._id));
        if (data) {
            if (data.cart !== null && data.cart !== undefined) {
                setUserCart(data.cart.cartItems);
                setCartId(data.cart._id)
            }
        }
        let tooltip = document.querySelectorAll(`[data-tip][data-for=errorLebanoon]`)[0];
        ReactTooltip.show(tooltip);
    }, [user]);

    const hover = () => {
        let tooltip = document.querySelectorAll(`[data-tip][data-for=errorLebanoon]`)[0];
        ReactTooltip.show(tooltip);
    }

    userCart && userCart.map((cartItems) => (
        USERCART.push(cartItems)
    ))
    const stripe = useStripe();
    const elements = useElements();
    let MergedCartItems = [...USERCART, ...cartItems]
    const order = {
        orderItems: cartItems.length !== 0 && userCart.length !== 0 ? MergedCartItems : cartItems.length === 0 && userCart.length !== 0 ? userCart : cartItems,
        shippingInfo: {
            address: `${product.street}, ${product.building}, ${product.Floor}`,
            city: product.city,
            phoneNo: product.phoneNumber,
            country: 'Lebanon'
        },
        paymentType: paymentType === 'Cash On Deliver' ? 'Cash On Deliver' : 'Stripe',
    }

    if (orderInfo) {
        if (product.Payment === 'LBP') {
            order.itemsPrice = orderInfo.itemsPrice
            order.totalPrice = orderInfo.totalPrice
        }
        else {
            order.itemsPrice = orderInfo.dollarItemsPrice
            order.totalPrice = orderInfo.dollarTotalPrice
        }
    }

    const paymentData = {
        amount: Math.round(orderInfo.totalPrice * 100)
    }
    const submitStripe = async (e) => {
        setLoading(true)
        let res;
        try {
            if (product.Payment === 'LBP') {
                res = await api.PaymentStripe(paymentData)
                const clientSecret = res.data.client_secret;
                if (!stripe || !elements) {
                    return;
                }
                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: product.firstName + ' ' + product.lastName,
                            phone: product.phoneNumber,
                            email: user.email
                        }
                    }
                });
                if (result.error) {
                    setLoading(false)
                    alert.error(res.error.message)
                    return;
                } else {
                    // the payment is processed or not
                    if (result.paymentIntent.status === 'succeeded') {
                        order.paymentInfo = {
                            id: result.paymentIntent.id,
                            status: result.paymentIntent.status
                        }
                        order.typePayment = 'LBP'

                        dispatch(createOrder(order, user))
                        if (cartId !== '' && cartId !== undefined) {
                            const success = await dispatch(deleteCart(cartId))

                            if (success.success) {
                                setLoading(false)
                                nextOrder()
                                resetStepper()
                            }
                        }
                        setLoading(false)
                        nextOrder()
                        resetStepper()
                    }
                    else {
                        alert.error('There is some issue while payment processing')
                    }
                }
            }
            else {
                res = await api.PaymentDollarStripe(paymentData)
                const clientSecret = res.data.client_secret;
                if (!stripe || !elements) {
                    return;
                }
                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: elements.getElement(CardElement),
                        billing_details: {
                            name: product.firstName + ' ' + product.lastName,
                            phone: product.phoneNumber,
                            email: user.email
                        }
                    }
                });
                if (result.error) {
                    setLoading(false)
                    window.alert.error(res.error.message)
                    return;
                } else {
                    // the payment is processed or not
                    if (result.paymentIntent.status === 'succeeded') {
                        order.paymentInfo = {
                            id: result.paymentIntent.id,
                            status: result.paymentIntent.status
                        }
                        order.typePayment = 'USD'
                        dispatch(createOrder(order, user))
                        if (cartId !== '' && cartId !== undefined) {
                            const success = await dispatch(deleteCart(cartId))

                            if (success.success) {
                                setLoading(false)
                                nextOrder()
                                resetStepper()
                            }
                        }
                        setLoading(false)
                        nextOrder()
                        resetStepper()
                    }
                    else {
                        alert.error('There is some issue while payment processing')
                    }
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const submitOnDelivery = async (e) => {
        setLoading(true)
        if (product.Payment === 'LBP') {
            order.typePayment = 'LBP'
        } else {
            order.typePayment = 'USD'
        }
        dispatch(createOrder(order, user))
        if (cartId !== '' && cartId !== undefined) {
            const success = await dispatch(deleteCart(cartId))
            if (success.success) {
                setLoading(false)
                nextOrder()
                resetStepper()
            }
        }
        setLoading(false)
        nextOrder()
        resetStepper()
    }
    const [deliveryRate, setDeliveryRate] = useState(0);
    const getOriginalDeliveryRate = async () => {
        try {
            const response = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/deliveryRate`)
            setDeliveryRate(response.data.dr[0].value);
        } catch (error) {
            console.error(`Error: ${error}`)
        }
    }

    const submitOnDeliveryByWhatsapp = async (e) => {
        setLoading(true)
        var str = '';
        str += 'Name:' + product.firstName + ' ' + product.lastName + '%0A';
        str += 'Country:' + order.shippingInfo.country + '%0A';
        str += 'City:' + order.shippingInfo.city + '%0A';
        str += 'Address:' + order.shippingInfo.address + '%0A';
        str += 'Products:%0A';
        str += '%0A';
        order.orderItems.forEach(p => {
            if (p.optCode && p.optCode !== '') {
                str += 'System Id: ' + p.product + ' Product Id: ' + p.optCode + '%0AName: ' + p.name + '%0APrice: ' + fCurrency(p.price) + '%0AQuantity: ' + p.quantity + '%0ASize: ' + p.size + '%0AColor: ' + p.color + '%0AAmount: ' + fCurrency(p.quantity * p.price) + '%0A';
            } else {
                str += 'System Id: ' + p.product + ' Product Id: ' + p.productId + '%0AName: ' + p.name + '%0APrice: ' + fCurrency(p.price) + '%0AQuantity: ' + p.quantity + '%0ASize: ' + p.size + '%0AColor: ' + p.color + '%0AAmount: ' + fCurrency(p.quantity * p.price) + '%0A';
            }
            str += '%0A';
        });
        str += 'Amount: ' + fCurrency(order.totalPrice) + '%0A';
        str += 'Delivery Cost: ' + deliveryRate + '%0A';
        var totAm = Number(order.totalPrice) + Number(deliveryRate);
        str += 'Total Amount: ' + fCurrency(totAm);
        window.open(`https://api.whatsapp.com/send?phone=96171566998&text=${str}`);
        if (cartId !== '' && cartId !== undefined) {
            const success = await dispatch(deleteCart(cartId))
            if (success.success) {
                setLoading(false)
                nextOrder()
                resetStepper()
            }
        }
        setLoading(false)
        nextOrder()
        resetStepper()
    }
    return (
        <>
            <ArrowLeft className="arrowLeft" onClick={backStep} />
            <span className="addtitle" style={{ fontFamily: 'Montserrat', fontSize: 25, fontWeight: 700 }}>Payment</span>
            {
                paymentType === 'Stripe' ? (
                    <>
                        <Grid container direction="row" xs={12} sm={12} md={12} display="flex" justifyContent="center" alignItems="center"
                            style={{ marginTop: 12, }}>

                            <Grid item xs={12} sm={12} md={12} direction="row" display="flex" justifyContent="center" alignItems="center"
                                style={{ border: "0px solid red" }} >
                                <span className="cashdelivery" s
                                    tyle={{
                                        fontSize: 20, color: 'black', width: "100%", opacity: "0.5", textAlign: "left", display: "inline-block",
                                        border: "0px solid blue"
                                    }} onClick={() => setPaymentType('Cash On Delivery')}>
                                    CASH ON DELIVERY
                                </span>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} direction="row" display="flex" justifyContent="center" alignItems="flex-start"
                                style={{ border: "0px solid red", marginTop: "20px" }}
                                data-tip data-for="errorLebanoon"
                                onMouseEnter={hover}
                                onMouseOver={hover}
                            >
                                <span className="stripe" style={{
                                    fontSize: 21, textDecoration: 'underline', textAlign: "left", display: "inline-block",
                                    border: "0px solid green"
                                }}>
                                    {/* onClick={() => setPaymentType('Stripe')} */}
                                    STRIPE
                                </span>
                            </Grid>
                            <ReactTooltip id="errorLebanoon" place="bottom" effect="solid" type="error">
                                Due to the Lebanese circumstance, online payment is not available now
                            </ReactTooltip>
                        </Grid>

                    </>
                ) : (
                    <>
                        <Grid container direction="row" xs={12} sm={12} md={12} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 12 }}>
                            <Grid item xs={12} sm={12} md={12} direction="row" display="flex" justifyContent="center" alignItems="center"
                                style={{ border: "0px solid green" }} >
                                <span className="cashdelivery" style={{
                                    fontSize: 21, textDecoration: 'underline', width: "100%", color: 'black',
                                    textAlign: "left", display: "inline-block", border: "0px solid blue"
                                }} onClick={() => setPaymentType('Cash On Delivery')}>
                                    CASH ON DELIVERY
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} direction="row" display="flex" justifyContent="center" alignItems="flex-start"
                                style={{ border: "0px solid red", marginTop: "20px" }}
                                onMouseEnter={hover}
                                onMouseOver={hover}
                                data-tip data-for="errorLebanoon"
                            >
                                <span className="stripe" style={{
                                    fontSize: 20, opacity: "0.5", textAlign: "left", display: "inline-block",
                                    border: "0px solid green"
                                }}>
                                    {/*  onClick={() => setPaymentType('Stripe')} */}
                                    STRIPE
                                </span>
                            </Grid>
                            <ReactTooltip style={{ width: "auto" }} id="errorLebanoon" place="bottom" effect="solid" type="error">
                                Due to the Lebanese circumstance,<br /> online payment is not available now
                            </ReactTooltip>
                        </Grid>
                    </>
                )
            }
            <br />
            <br />
            {
                paymentType === 'Stripe' ? <CardElement options={CARD_ELEMENT_OPTIONS} /> : null
            }
            {
                loading ? <CircularProgress /> :
                    < Button className="Next" onClick={(e) => submitOnDeliveryByWhatsapp(e)} >CHECKOUT {`${orderInfo && orderInfo.totalPrice}`}</Button>
                // paymentType === 'Stripe' ?
                //     product.Payment === 'LBP' ?
                //         < Button className="Next" onClick={(e) => submitStripe(e)} >CHECKOUT {`${orderInfo && orderInfo.totalPrice}`}</Button>
                //         :
                //         < Button className="Next" onClick={(e) => submitStripe(e)} >CHECKOUT {`${orderInfo && orderInfo.dollarTotalPrice}`}</Button>
                //     :
                //     product.Payment === 'LBP' ?
                //         < Button className="Next" onClick={(e) => submitOnDelivery(e)} >CHECKOUT {`${orderInfo && orderInfo.totalPrice}`}</Button>
                //         :
                //         < Button className="Next" onClick={(e) => submitOnDelivery(e)} >CHECKOUT {`${orderInfo && orderInfo.dollarTotalPrice}`}</Button>
                // <Button className="Next" onClick={(e) => submitOnDelivery(e)} >CHECKOUT {`${orderInfo && orderInfo.totalPrice}`}</Button>
            }


        </>
    )
}

export default Stripe;
