import React, { Fragment, useState, useEffect } from 'react'
import 'rc-slider/assets/index.css'
import { useDispatch } from 'react-redux'
import '../components/product/style.css'
import '../components/product/style.scss'
import Grid from "@material-ui/core/Grid";
import { getAllColor } from "../actions/productActions";
const Color = ({ setColor }) => {
    const [colors, setColors] = useState([])
    let ColorArr = []
    const dispatch = useDispatch();
    useEffect(async () => {
        const data = await dispatch(getAllColor())
        setColors(data)
    }, [])
    colors.products && colors.products.map((option) => {
        option.options.map((options) => {
            if (options !== undefined && options !== null) {
                if (options.color !== undefined && options.color !== '') {
                    ColorArr.push(options.color)
                }
            }
        })
    }
    )
    let uniqueChars = [...new Set(ColorArr)];
    return (
        <>
            {uniqueChars && uniqueChars.map((color) => (
                <Grid lg={6}>
                    <div className="swatch clearfix" data-option-index="1">
                        <div data-value="Red" onClick={() => setColor(color)} className="swatch-element color available">
                            <div className="tooltip">{color}</div>
                            <input quickbeam="color" id={`${color}`} type="radio" name="option-1" value={color} />
                            <label htmlFor={`${color}`}>
                                {
                                    color && color !== '' ? (
                                        color === 'White' ? (<span style={{ backgroundColor: '#FFF' }} />) :
                                            color === 'Emerald Green' ? (<span style={{ backgroundColor: '#009A76' }} />) :
                                                color === 'Brick Red' ? (<span style={{ backgroundColor: '#9E4429' }} />) :
                                                    color === 'Mustard Yellow' ? (<span style={{ backgroundColor: '#EAAA00' }} />) :
                                                        color === 'Light Bois De Rose' ? (<span style={{ backgroundColor: '#CD9892' }} />) :
                                                            color === 'Brown' ? (<span style={{ backgroundColor: '#5C4033' }} />) :
                                                                color === 'Dark Green' ? (<span style={{ backgroundColor: '#313E34' }} />) :
                                                                    color === 'Light Navy' ? (<span style={{ backgroundColor: '#010080' }} />) :
                                                                        color === 'Neon Fuschia' ? (<span style={{ backgroundColor: '#FE4164' }} />) :
                                                                            color === 'Burgundy' ? (<span style={{ backgroundColor: '#610220' }} />) :
                                                                                color === 'Pistachio' ? (<span style={{ backgroundColor: '#BDFBBE' }} />) :
                                                                                    color === 'Cherry' ? (<span style={{ backgroundColor: '#A5003D' }} />) :
                                                                                        color === 'Off White' ? (<span style={{ backgroundColor: '#F8F0E3' }} />) :
                                                                                            color === 'Pink' ? (<span style={{ backgroundColor: '#FEBAD3' }} />) :
                                                                                                color === 'Purple' ? (<span style={{ backgroundColor: '#C99AF8' }} />) :
                                                                                                    color === 'Dark Purple' ? (<span style={{ backgroundColor: '#81007F' }} />) :
                                                                                                        color === 'Red' ? (<span style={{ backgroundColor: '#FE0500' }} />) :
                                                                                                            color === 'Salmon' ? (<span style={{ backgroundColor: '#FA7E72' }} />) :
                                                                                                                color === 'Turquoise' ? (<span style={{ backgroundColor: '#00FFEF' }} />) :
                                                                                                                    color === 'Yellow' ? (<span style={{ backgroundColor: '#FDFC35' }} />) :
                                                                                                                        color === 'Navy' ? (<span style={{ backgroundColor: '#1C1E47' }} />) :
                                                                                                                            color === 'Green' ? (<span style={{ backgroundColor: '#008540' }} />) :
                                                                                                                                color === 'Beige' ? (<span style={{ backgroundColor: '#BF9E7F' }} />) :
                                                                                                                                    color === 'Blue' ? (<span style={{ backgroundColor: '#8BCDF0' }} />) :
                                                                                                                                        color === 'Orange' ? (<span style={{ backgroundColor: '#FE6023' }} />) :
                                                                                                                                            color === 'Darkgray' ? (<span style={{ backgroundColor: '#404040' }} />) :
                                                                                                                                                color === 'Lightgray' ? (<span style={{ backgroundColor: '#B2B2B2' }} />) :
                                                                                                                                                    color === 'Fuchsia' ? (<span style={{ backgroundColor: '#E60081' }} />) :
                                                                                                                                                        color === 'Blue Grey' ? (<span style={{ backgroundColor: '#B5C9E1' }} />) :
                                                                                                                                                            color === 'Bois De Rose' ? (<span style={{ backgroundColor: '#864650' }} />) :
                                                                                                                                                                color === 'Blue Petrol' ? (<span style={{ backgroundColor: '#0F4B63' }} />) :
                                                                                                                                                                    (<span style={{ backgroundColor: `${color}` }} />)
                                    ) : null
                                }
                                {/* <span style={{ backgroundColor: `${color}` }} /> */}
                            </label>
                        </div>
                    </div>
                </Grid>
            ))
            }
        </>
    )
}
export default Color;
