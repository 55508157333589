import React, { Fragment, useState, useEffect } from 'react'
import './style.css'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { useDispatch, useSelector } from 'react-redux'
import withStyles from "@material-ui/styles/withStyles";
import { getCategories, getSubCategories } from "../../actions/categoryActions";
import { Checkbox, FormControlLabel } from "@material-ui/core";
const BlueCheckbox = withStyles({
    root: {
        color: '#C9E4E7',
        '&$checked': {
            color: '#C9E4E7',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const SubCatOpt = ({ category, product }) => {
    const [categories, setCategories] = useState([])
    const dispatch = useDispatch();
    useEffect(async () => {
        const data = await dispatch(getSubCategories(category))
        setCategories(data)
    }, [category])
    return (
        <>
            <option key='' value='please select' >select a subCategory</option>
            {
                categories.data !== undefined ? categories.data.map(category => (
                    category.subcategories.map(subcategory => (
                        <option key={subcategory} value={subcategory} >{subcategory}</option>
                    ))
                )) : null
            }
        </>
    )
}
export default SubCatOpt;
