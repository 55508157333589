import { makeStyles } from '@material-ui/core/styles';
const UseStyles = makeStyles(() => ({
    trash: {
        transition: "all 0.5s ease",
        cursor: "pointer",
        '&:hover': {
            color: "#ef6da6"
        }
    },
}))
export default UseStyles;