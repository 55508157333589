import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProductsSearcNav } from '../../actions/productActions';
import './SearcchBar.css'
const Search = ({ history }) => {
    const dispatch = useDispatch();
    let { loading, products, error, productsCount, maxPrice, resPerPage, filteredProductCount } = useSelector(state => state.products)
    const [value, setValue] = useState('');
    const [prods, setProds] = useState([]);
    const [results, setResults] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const { data } = await axios.get(`https://backend.lingeriefidele.com/api/v1/getAllProduct`);
        setProds(data.products);
        if (error) {
            return alert.error(error)
        }
    }, [dispatch])
    const getResults = (value) => {
        if (value !== '') {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;
            //setResults(prods.filter(prod => prod.name.toLowerCase().slice(0, inputLength) === inputValue));
            setResults(prods.filter(function (str) {
                return (str.name.toLowerCase().includes(inputValue) ||
                    str._id.toLowerCase().includes(inputValue));
            }));
            //return inputLength === 0 ? [] : products.filter(prod => prod.name.toLowerCase().slice(0, inputLength) === inputValue);
        } else {
            setResults([]);
        }
    }

    const toItem = async (name) => {
        setResults([]);
        setValue('');
        const { data } = await axios.get(`https://backend.lingeriefidele.com/api/v1/getAllProduct`);
        setProds(data.products);
        history.push(`/product/${name}`)
    }

    const toSerach = async (name) => {
        setResults([]);
        setValue('');
        const { data } = await axios.get(`https://backend.lingeriefidele.com/api/v1/getAllProduct`);
        setProds(data.products);
        history.push(`/search/${name}`)
    }

    const res = results.map(c => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <a className="list-group-item list-group-item-action hoveredItem" aria-current="true" onClick={() => toItem(c._id)}>
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{c.name}</h5>
                <small className="ml-1">{c.category}</small>
            </div>
            <div className="d-flex w-100 justify-content-between">
                {/* <img src={c.images[0].url} alt="fidele" width={50} height={50} /> */}
                <small className="ml-1">{c.subcategory}</small>
            </div>
        </a>
    });


    return (
        <div className="searchBarWidth">
            <div className="input-group rounded">
                <input
                    id="search-box"
                    type="search"
                    autoComplete="off"
                    className="form-control roundedd"
                    placeholder="Search product"
                    aria-label="Search"
                    aria-describedby="search-box"
                    value={value}
                    onChange={(e) => { setValue(e.target.value); getResults(e.target.value) }}
                    onKeyDown={(e) => { if (e.key && e.key === 'Enter') { toSerach(e.target.value) } }}
                />
                <span className="input-group-text roundedd1" id="search-box"
                    onClick={(e) => { if (value !== '') { toSerach(value) } }}>
                    <i className="fa fa-search"></i>
                </span>
            </div>
            {
                results && results.length > 0 ? (
                    <div className="panel-body" style={{ zIndex: "300" }}>
                        <div className="listGroup roundedd2" style={{ position: 'absolute' }}>
                            {res}
                        </div>
                    </div>
                ) : (
                    value !== '' ? (
                        <div className="listGroup roundedd2" style={{ position: 'absolute', zIndex: "300" }}>
                            <a href='#' className="list-group-item list-group-item-action" aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5>No result found for {value}</h5>
                                </div>
                            </a>
                        </div>
                    ) : null
                )
            }
        </div>
    )
}
export default Search