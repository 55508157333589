import React, { useState } from 'react';
import '../ButtonStyle.scss'
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'
import Stripe from "../Stripe/Stripe";

const stripePromise = loadStripe('pk_test_51JRxB6BSZdquJVICJjRExozJf8EdUdQgu8h9buLlC9I6D8oQIv3piJ94GVFuZOAQd4QdVsraeGSnkQxMEqiUdycE00E93sqMAb')

const PaymentCart = ({ backStep, product, next, resetStepper, nextOrder }) => {
    return (
        <>
            <Elements stripe={stripePromise}>
                <Stripe backStep={backStep} product={product} next={next} resetStepper={resetStepper} nextOrder={nextOrder} />
            </Elements>
        </>
    )
}

export default PaymentCart;