import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { myOrders, clearErrors } from '../../actions/orderActions'
const ListOrders = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const [User, setUser] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    const { loading, error, orders } = useSelector(state => state.myOrders);
    useEffect(() => {
        dispatch(myOrders(user && user._id));
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error, user])
    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'Order ID',
                    field: 'id',
                    sort: 'asc'
                },
                {
                    label: 'Num of Items',
                    field: 'numOfItems',
                    sort: 'asc'
                },
                {
                    label: 'Amount',
                    field: 'amount',
                    sort: 'asc'
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                },
            ],
            rows: []
        }
        orders.forEach(order => {
            data.rows.push({
                id: order._id,
                numOfItems: order.orderItems.length,
                amount: `LBP ${order.totalPrice}`,
                status: order.orderStatus && String(order.orderStatus).includes('Delivered')
                    ? <p style={{ color: 'green' }}>{order.orderStatus}</p>
                    : <p style={{ color: 'red' }}>{order.orderStatus}</p>,
                actions:
                    <Link to={`/order/${order._id}`} className="btn  eyeui">
                        <i className="fa fa-eye"></i>
                    </Link>
            })
        })
        return data;
    }
    return (
        <div style={{ marginTop: 150 }}>
            <MetaData title={'My Orders'} />
            <div className="container" style={{ marginTop: 105 }}>
                <h1 className="titleh1" style={{ marginBottom: 20 }}>My Orders</h1>
                {loading ? <Loader /> : (
                    <MDBDataTable
                        data={setOrders()}
                        className="px-3"
                        bordered
                        striped
                        hover
                    />
                )}
            </div>
        </div>
    )
}
export default ListOrders
