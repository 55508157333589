import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";
import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderDetails, clearErrors } from '../../actions/orderActions'
const OrderDetails = ({ match }) => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, order = {} } = useSelector(state => state.orderDetails)
    const { shippingInfo, orderItems, paymentInfo, user, totalPrice, orderStatus } = order
    useEffect(() => {
        dispatch(getOrderDetails(match.params.id));
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error, match.params.id])
    const shippingDetails = shippingInfo && `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.postalCode}, ${shippingInfo.country}`
    const isPaid = paymentInfo && paymentInfo.status === 'succeeded' ? true : false
    return (
        <div style={{ marginTop: 80 }}>
            <MetaData title={'Order Details'} />
            <div className="container">
                {loading ? <Loader /> : (
                    <Fragment>
                        <div className="row d-flex justify-content-between">
                            <div style={{ fontFamily: "'Roboto" }} className="col-12 col-lg-8 mt-5 order-details">
                                <h1 className="my-5 titleh1">Order # {order._id}</h1>
                                <h4 className="mb-4 titleh1">Shipping Info</h4>
                                <p className=" titleh2 mt-1"><b>Name:</b> {user && user.name}</p>
                                <p className=" titleh2  mt-1"><b>Phone:</b> {shippingInfo && shippingInfo.phoneNo}</p>
                                <p className=" titleh2  mt-1"><b>Address:</b>{shippingDetails}</p>
                                <p className=" titleh2  mt-1"><b>Amount:</b> LBP {totalPrice}</p>
                                <hr />
                                <h4 className="my-2 titleh1">Payment Type</h4>
                                <p className=" titleh2 greenColor"><b>{order.paymentType}</b></p>
                                {
                                    order.status === 'Delivered' ? (
                                        <>
                                            <h4 className="my-4">Payment</h4>
                                            <p className="greenColor"><b>PAID</b></p>
                                        </>
                                    ) : (
                                        <>
                                            <h4 className="my-4">Payment</h4>
                                            <p className={isPaid ? "greenColor" : "redColor"}><b>{isPaid ? "PAID" : "NOT PAID"}</b></p>
                                        </>
                                    )
                                }
                                <h4 className="my-4 titleh1">Order Status:</h4>
                                <p className={order.orderStatus && String(order.orderStatus).includes('Delivered') ? "greenColor" : "redColor"} ><b>{orderStatus}</b></p>
                                <h4 className="my-4 titleh1">Order Items:</h4>
                                <hr />
                                <div className="cart-item my-1">
                                    {orderItems && orderItems.map(item => (
                                        <div key={item.product} className="row my-5">
                                            <div className="col-3 col-lg-3">
                                                <img src={item.image} alt={item.name} height="100" width="80" />
                                            </div>
                                            <div className="col-2 col-lg-2">
                                                <Link to={`/products/${item.product}`}>{item.name}</Link>
                                                {/*<p>{item.size}</p>*/}
                                            </div>
                                            <div className="col-2 col-lg-2">
                                                {
                                                    item.discountRate && item.isChecked ? (
                                                        <>
                                                            <p>LBP {(item.price - (item.price * item.discountRate / 100)) * item.quantity} ({item.discountRate}%)</p>
                                                        </>
                                                    ) : (
                                                        <p>LBP {item.price}</p>
                                                    )
                                                }
                                            </div>
                                            <div className="col-2 col-lg-2">
                                                <p>{item.quantity} Piece(s)</p>
                                            </div>
                                            <div className="col-1 col-lg-1 mr-2">
                                                <p>{item.size}</p>
                                            </div>
                                            <div className="col-1 col-lg-1 ">
                                                <IconButton size="small" style={{ color: `${item.color}` }} aria-label="favorite">
                                                    <FiberManualRecordIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <hr />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    )
}
export default OrderDetails
