import React, { Fragment, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
const ProtectedRoute = ({ isAdmin, component: Component, ...rest }) => {

    const { isAuthenticated, loading } = useSelector(state => state.auth);
    const [User] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    const role = User ? JSON.parse(atob(User.token.split('.')[1])).role : null

    return (
        <Fragment>
            <Route
                //hone bhal case l rest hie exact wel path (men app.js)
                {...rest}
                render={props => {
                    if (user === null) {
                        // navigate('/login')
                        return <Redirect to='/login' />
                    }

                    if (isAdmin === true && (role && role !== 'admin')) {
                        // navigate('/')
                        return <Redirect to='/' />
                    }

                    return <Component {...props} />
                }} />
        </Fragment>
    )
}

export default ProtectedRoute