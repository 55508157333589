import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminProducts, deleteProduct, clearErrors } from '../../actions/productActions'
import { DELETE_PRODUCT_RESET } from '../../constants/productConstants'
import numeral from 'numeral';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './table.css'
import axios from 'axios';

const DataTableFilterDemo = ({ history }) => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, products } = useSelector(state => state.products);
    const { error: deleteError, isDeleted } = useSelector(state => state.product);
    const fCurrency = (number) => {
        return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
    }
    const fDCurrency = (number) => {
        return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
    }
    const deleteProductHandler = (id) => {
        dispatch(deleteProduct(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        dispatch(getAdminProducts());
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors())
        }
        if (isDeleted) {
            alert.success('Product deleted successfully');
            dispatch({ type: DELETE_PRODUCT_RESET })
        }
        initFilters1();
    }, [dispatch, alert, error, deleteError, isDeleted, history])

    const [filters1, setFilters1] = useState(null);

    const [globalFilterValue1, setGlobalFilterValue1] = useState('');


    const clearFilter1 = () => {
        initFilters1();
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['_id'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            '_id': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'productId': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'price': { value: null, matchMode: FilterMatchMode.EQUALS },
            'dollarPrice': { value: null, matchMode: FilterMatchMode.EQUALS },
            'discountRate': { value: null, matchMode: FilterMatchMode.EQUALS },
            'category': { value: null, matchMode: FilterMatchMode.EQUALS },
            'subcategory': { value: null, matchMode: FilterMatchMode.EQUALS },
            'collectiontype': { value: null, matchMode: FilterMatchMode.EQUALS }
        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }


    const lbpBodyTemplate = (rowData) => {
        return <span className='customer-badge'>LBP{fCurrency(rowData.price)}</span>;
    }

    const dollarBodyTemplate = (rowData) => {
        return <span className='customer-badge'>{fDCurrency(rowData.dollarPrice)}</span>;
    }

    const imageBodyTemplate = (rowData) => {
        return <img src={`${rowData.images[0].url}`} width={100}
            onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
            alt='Product images' className="product-image" />;
    }

    const updateStatus = async (p) => {
        if (p.status === 'INSTOCK') {
            p.status = 'OUTOFSTOCK'
        } else {
            p.status = 'INSTOCK'
        };
        const res = await axios.post(`https://backend.lingeriefidele.com/api/v1/admin/updateStatis/${p._id}`, p)
        dispatch(getAdminProducts());
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => window.open(`/admin/product/${rowData._id}`)} />
                <Button icon="pi pi-stop-circle" className="p-button-rounded p-button-warning mr-2"
                    onClick={() => updateStatus(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => deleteProductHandler(rowData._id)} />
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return <span className={`product-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
    }

    const drBodyTemplate = (rowData) => {
        let dr = 0;
        if (rowData.discountRate) {
            dr = rowData.discountRate;
        }
        return <span className={`product-badge`}>{dr}%</span>;
    }

    const padrBodyTemplate = (rowData) => {
        let dr = 0;
        if (rowData.discountRate) {
            dr = rowData.discountRate;
        }
        let p = rowData.price * ((100 - dr) / 100)
        return <span className={`product-badge`}>LBP{fCurrency(p)}</span>;
    }

    const header1 = renderHeader1();

    return (
        <div className="datatable-filter-demo">
            <div className="row">
                <div className="col">
                    <h1 className="my-5 titlecms">All Products</h1>
                </div>
                <div className="col offset-md-6">
                    <div className="my-5 titlecms1">
                        <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2" onClick={() => window.open(`/admin/product`)} />
                    </div>
                </div>
            </div>
            <div className="card">
                <DataTable value={products} paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rows={10} rowsPerPageOptions={[10, 20, 50, 100]}
                    className="p-datatable-customers" showGridlines
                    dataKey="_id" filters={filters1} filterDisplay="row" loading={loading} responsiveLayout="scroll"
                    globalFilterFields={['_id', 'productId', 'name', 'price', 'dollarPrice', 'discountRate', 'category', 'subcategory']} header={header1}
                    emptyMessage="No product found.">
                    <Column field="_id" header="System Id" filter filterPlaceholder="Search by System ID" style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column field="productId" header="Product Id" filter filterPlaceholder="Search by Product ID" style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column field="collectiontype" header="Collection" filter filterPlaceholder="Search by Collection Type" style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column field="name" header="Name" filter filterPlaceholder="Search by Product Name " style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column field="price" header="LBP Price" filter filterPlaceholder="Search by Price" style={{ flexGrow: 1, flexBasis: '200px' }}
                        body={lbpBodyTemplate} />
                    <Column field="dollarPrice" filter filterPlaceholder="Search by Dollar Price" header="Dollar Price" style={{ flexGrow: 1, flexBasis: '200px' }}
                        body={dollarBodyTemplate} />
                    <Column field="discountRate" body={drBodyTemplate} header="Discount Rate" filter filterPlaceholder="Search by Discount Rate " style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column header="Price After Discount" body={padrBodyTemplate} style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column field="category" header="Category" filter filterPlaceholder="Search by Category" style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column field="subcategory" header="Sub-Category" filter filterPlaceholder="Search by Sub Category" style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column header="Image" body={imageBodyTemplate} style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column header="Status" body={statusBodyTemplate} style={{ flexGrow: 1, flexBasis: '200px' }} />
                    <Column body={actionBodyTemplate} exportable={false} style={{ flexGrow: 1, flexBasis: '250px' }} />
                </DataTable>
            </div>
        </div>
    );
}
export default DataTableFilterDemo