import React, { useState, useEffect } from "react";
import "./style.scss";
import { ShoppingBag } from "react-feather";
import { alpha, makeStyles, useTheme } from "@material-ui/core/styles";
import ItemCartBox from "../../components/ItemCartBox/ItemCartBox";
import AddressCart from "../../components/cart/AddressCart/AddressCart";
import {
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Grid,
} from "@material-ui/core";
import PaymentCart from "../../components/cart/PaymentCart/Paymentcart";
import { useSelector, useDispatch } from "react-redux";
import ConfirmOrderFidele from "./ConfirmOrder/ConfirmOrder";
import Success from "./Success";
import { AccountBox } from "../accountBox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./cartStyle.css";
import styled from "styled-components";
import {
    addItemToCart,
    getUserCart,
    removeItemFromCart,
} from "../../actions/cartActions";
import OrderSuccess from "./OrderSuccess";
import { OverlayTrigger } from "react-bootstrap";
const steps = ["Shipping", "Billing"];
const Cart = ({ history }) => {
    const dispatch = useDispatch();
    const useStyles = makeStyles((theme) => ({
        grow: {
            flexGrow: 1,
        },
        // menuButton: {
        //     marginRight: theme.spacing(2),
        // },
        title: {
            display: "none",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
        },
    }));
    const classes = useStyles();
    const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;
    const [open, setOpen] = React.useState(false);
    const [openSignin, setOpenSignin] = React.useState(false);
    const handleClickOpenSignin = () => {
        setOpenSignin(true);
    };
    const handleCloseSignin = () => {
        setOpenSignin(false);
    };
    const [User, setUser] = useState(JSON.parse(localStorage.getItem("User")));
    const user = User ? User.user : null;
    const [activeStep, setActiveStep] = useState(0);
    const { cartItems } = useSelector((state) => state.cart);
    const [shippingData, setShippingData] = useState({});
    const [productData, setProductData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        city: "",
        street: "",
        building: "",
        Floor: "",
        Payment: "",
    });
    const [userCart, setUserCart] = useState([]);
    const [show, setShow] = useState(true);
    useEffect(async () => {
        if (show) {
            setTimeout(() => { setShow(false) }, 5000);
        }
        const data = await dispatch(getUserCart(user && user._id));
        if (data) {
            if (data.cart !== null && data.cart !== undefined) {
                setUserCart(data.cart.cartItems);
            }
        }
    }, [user]);
    const handleChange = (input) => (e) => {
        setProductData({ ...productData, [input]: e.target.value });
    };
    const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const next = (data) => {
        setProductData({
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
            city: data.city,
            street: data.street,
            building: data.building,
            Floor: data.Floor,
            Payment: data.Payment,
        });
        nextStep();
    };
    const resetStepper = () => {
        localStorage.removeItem("cartItems");
        setProductData({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            city: "",
            street: "",
            building: "",
            Floor: "",
            Payment: "",
        });
        window.location.reload(false);
    };
    const Confirmation = () => <div>confirmation</div>;
    const Form = () =>
        activeStep === 0 ? (
            <AddressCart
                next={next}
                user={user && user._id}
                product={productData}
                setProduct={setProductData}
                handleChange={handleChange}
            />
        ) : (
            <PaymentCart
                resetStepper={resetStepper}
                backStep={backStep}
                product={productData}
                next={next}
                nextOrder={nextStep}
            />
        );
    return (
        <>
            {activeStep !== 0 && activeStep !== 1 ? (
                history.push("/success")
            ) : (
                <>
                    <Grid container>
                        <Grid className="fideleCart" item xs={12} sm={12} md={12} lg={12}>
                            <Grid className="fideleCart" item xs={12} sm={12} md={10} lg={7}>
                                <div className="cart_wrapper">
                                    <div className="cart_lists">
                                        <div className="cart_title">
                                            <ShoppingBag />
                                            <span>
                                                Your Shopping Cart
                                            </span>
                                        </div>
                                        {activeStep === 0 ? (
                                            <ItemCartBox user={user && user._id} />
                                        ) : (
                                            <ConfirmOrderFidele
                                                user={user && user._id}
                                                next={next}
                                                product={productData}
                                                setProduct={setProductData}
                                                backStep={backStep}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid className="fideleCart" item xs={10} sm={10} md={8} lg={4}>
                                {user === null || user === undefined ? (
                                    <>
                                        <section className="address" style={{ borderRadius: "10px" }} >
                                            <br />
                                            <button className="Nextbtnaddress">
                                                You need to login in order to continue
                                            </button>
                                        </section>
                                    </>
                                ) : cartItems.length > 0 || userCart.length > 0 ? (
                                    <section className="address" style={{ backgroundColor: "#fff1e7", borderRadius: "10px" }}>
                                        <Stepper
                                            activeStep={activeStep}
                                            style={{
                                                backgroundColor: "transparent",
                                                transform: "translateY(-1.5em)",
                                            }}
                                        >
                                            {steps.map((step) => (
                                                <Step key={step}>
                                                    <StepLabel>{step}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        {<Form />}
                                    </section>
                                ) : null}
                            </Grid>
                            <Grid item xs={0} sm={1} md={1} lg={1} />
                        </Grid>
                    </Grid>
                    {/* </section> */}
                </>
            )}
        </>
    );
};
export default Cart;