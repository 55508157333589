import React, { Fragment, useState, useEffect } from 'react'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import { useDispatch, useSelector } from 'react-redux'


import withStyles from "@material-ui/styles/withStyles";
import { getCategories } from "../actions/categoryActions";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { RadioButtonCheckedOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import './innerCategoryNavbar.css'
import { useHistory } from "react-router-dom";



const InnerNavbar = ({ categories, setCategory, cat, setCategoryForSub, open, setOpen }) => {
    let history = useHistory();

    const handleCheckChange = (event) => {
        setCategory(event.target.name)
    };

    return (
        <>
            <ul style={{ marginRight: "20px", position: 'relative' }}>
                <div className="navItems" style={{ paddingBottom: '21px', paddingTop: '19px' }}>
                    <li className="font" style={cat !== categories.name ? {
                        cursor: 'pointer',
                        listStyleType: 'none',
                        // fontFamily: 'Open Sans',
                        fontSize: "20px",
                        // marginBottom: '10px'
                    } : {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        textDecorationColor: "#C9E4E7",
                        textDecorationThickness: '0.2em',
                        // fontFamily: 'Open Sans',
                        fontSize: "20px",
                        // marginBottom: '10px'
                    }

                    }
                        key={categories.name}
                        onClick={() => { window.location.href = (`/productCat/${categories.name}`); setCategory(categories.name); setOpen(false); }}
                        onMouseEnter={() => setCategoryForSub(categories.name)}
                    >{categories.name}</li>
                </div>
            </ul>
        </>
    )
}

export default InnerNavbar;
