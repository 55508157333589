import React, { useEffect, useState } from "react";
import Carousel from "../../components/Carousel/Carousel";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import UseStyles from "./style";
import CardMedia from "@material-ui/core/CardMedia";
import Man from "../../images/menInPj1.jpg";
import Kid from "../../images/kidInPj1.jpg";
import Baby from "../../images/baby1.jpg";
import full from "../../images/full.jpeg";
import Hidden from "@material-ui/core/Hidden";
import NewItemsSlider from "../../components/NewItemsSlider/NewItemsSlider";
import { Input } from "../../components/accountBox/common";
import Button from "@material-ui/core/Button";
import ButtonHover from "../../components/buttonHover/ButtonHover";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import "./homepage.css";
import "./illustrations.css";
import "./floating_buttons.css";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { getHomePageProducts } from "../../actions/productActions";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import ButtonShopNow from "../buttonHover/ButtonShopNow";
import NewSlider from "../NewItemsSlider/newSlider/NewSlider";
const { createSliderWithTooltip } = Slider;
///////////////////////////////////////
const HomePage = ({ match }) => {
  // const [show, setShow] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([1, 10000]);
  const [category, setCategory] = useState("");
  const [rating, setRating] = useState(0);
  const [showAnim, setAnim] = useState(false);
  const categoriesUpper = [
    { className: "Pyjamas", name: "Pyjamas" },
    { className: "Sport-Wear", name: "Sport Wear" },
    { className: "Towels", name: "Towels" },
    { className: "Robe", name: "Robe" },
    { className: "Slipper", name: "Slipper" },
    { className: "Overall", name: "Overall" },
  ];
  const categoriesLower = [
    { className: "Gloves", name: "Gloves" },
    { className: "Underwear", name: "Underwear" },
    { className: "Pillows", name: "Pillows" },
    { className: "Blankets", name: "Blankets" },
    { className: "Crib-Set", name: "Crib Set" },
    { className: "Bibs", name: "Bibs" },
  ];
  const categoriesUpper1 = [
    { className: "Pyjamas", name: "Pyjamas" },
    { className: "Sport-Wear", name: "Sport Wear" },
    { className: "Towels", name: "Towels" },
    { className: "Robe", name: "Robe" },
  ];
  const categoriesMiddle1 = [
    { className: "Slipper", name: "Slipper" },
    { className: "Overall", name: "Overall" },
    { className: "Gloves", name: "Gloves" },
    { className: "Underwear", name: "Underwear" },
  ];
  const categoriesLower1 = [
    { className: "Pillows", name: "Pillows" },
    { className: "Blankets", name: "Blankets" },
    { className: "Crib-Set", name: "Crib Set" },
    { className: "Bibs", name: "Bibs" },
  ];
  const categoriesUpper2 = [
    { className: "Pyjamas", name: "Pyjamas" },
    { className: "Sport-Wear", name: "Sport Wear" },
    { className: "Towels", name: "Towels" },
  ];
  const categoriesMiddle21 = [
    { className: "Robe", name: "Robe" },
    { className: "Slipper", name: "Slipper" },
    { className: "Overall", name: "Overall" },
  ];
  const categoriesMiddle22 = [
    { className: "Gloves", name: "Gloves" },
    { className: "Underwear", name: "Underwear" },
    { className: "Pillows", name: "Pillows" },
  ];
  const categoriesLower2 = [
    { className: "Blankets", name: "Blankets" },
    { className: "Crib-Set", name: "Crib Set" },
    { className: "Bibs", name: "Bibs" },
  ];
  const catsUpper = categoriesUpper.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={3}
        md={2}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsLower = categoriesLower.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={3}
        md={2}
        direction="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsUpper1 = categoriesUpper1.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsMiddle1 = categoriesMiddle1.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsLower1 = categoriesLower1.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsUpper2 = categoriesUpper2.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsMiddle21 = categoriesMiddle21.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsMiddle22 = categoriesMiddle22.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsLower2 = categoriesLower2.map((c) => {
    return (
      <Grid
        item
        xs={3}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsUpper3 = categoriesUpper2.map((c) => {
    return (
      <Grid
        item
        xs={4}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsMiddle31 = categoriesMiddle21.map((c) => {
    return (
      <Grid
        item
        xs={4}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsMiddle32 = categoriesMiddle22.map((c) => {
    return (
      <Grid
        item
        xs={4}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const catsLower3 = categoriesLower2.map((c) => {
    return (
      <Grid
        item
        xs={4}
        sm={4}
        md={3}
        direction="row"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <li>
          <div
            class="ch-item"
            onClick={() => {
              window.location.href = `/productSubCats/${c.name}`;
            }}
          >
            <div class="ch-info-wrap">
              <div class="ch-info">
                <div class={`ch-info-front ch-img-${c.className}`}></div>
                <div class="ch-info-back">
                  <h3>{c.name}</h3>
                </div>
              </div>
            </div>
          </div>
        </li>
      </Grid>
    );
  });
  const alert = useAlert();
  const dispatch = useDispatch();
  const {
    loading,
    products,
    error,
    productsCount,
    resPerPage,
    filteredProductCount,
  } = useSelector((state) => state.products);
  const keyword = match.params.keyword;
  function toMenSection(type) {
    if (type === "men") {
      window.location = "/productCat/Men";
    } else if (type === "women") {
      window.location = "/productCat/Women";
    } else if (type === "kids") {
      window.location = "/productCat/Kids";
    } else {
      window.location = "/productCat/Babies";
    }
  }
  useEffect(() => {
    if (error) {
      return alert.error(error);
    }
    dispatch(
      getHomePageProducts(keyword, currentPage, price, category, rating)
    );
    if (sessionStorage.getItem("keyAnim") === null) {
      setAnim(true);
      sessionStorage.setItem("keyAnim", "false");
    } else {
      setAnim(false);
    }
    // if (show) {
    //     setTimeout(() => { setShow(false) }, 5000);
    // }
  }, [dispatch, alert, error, keyword, currentPage, price, category, rating]);
  function setCurrentpageNo(pageNumber) {
    setCurrentPage(pageNumber);
  }
  let count = productsCount;
  if (keyword) {
    count = filteredProductCount;
  }
  const classes = UseStyles();
  const theme = createMuiTheme();
  theme.typography.h3 = {
    fontSize: "1.2rem",
    "@media (min-width:600px)": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: -10,
      behavior: "smooth",
    });
  };

  //window.addEventListener('scroll', toggleVisible);
  return (
    <>
      <div className="homeContainer">
        <Grid container className={classes.instaStories}></Grid>
        <Grid container>
          <Grid item sm={12} xs={12} md={12} lg={12}>
            <Carousel />
            <div
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateY(-10px)",
                zIndex: "200",
              }}
            >
              <div style={{ position: "relative", left: "-50%" }}>
                <ButtonShopNow />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={1}></Grid>
          <Grid item sm={4} xs={12}>
            {showAnim ? (
              <Fade bottom>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h2"
                    className="categoryTitle"
                    style={{
                      fontFamily: "Dancing Script, cursive",
                      fontSize: "2.75rem",
                      marginTop: "40px",
                      marginLeft: "15px",
                    }}
                  >
                    New Arrivals
                  </Typography>
                </ThemeProvider>
              </Fade>
            ) : (
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h2"
                  className="categoryTitle"
                  style={{
                    fontFamily: "Dancing Script, cursive",
                    fontSize: "2.75rem",
                    marginTop: "40px",
                    marginLeft: "15px",
                  }}
                >
                  New Arrivals
                </Typography>
              </ThemeProvider>
            )}
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: "20px", paddingTop: "20px" }}>
          <Grid item xs={12}>
            <NewItemsSlider />
            {/* <NewSlider /> */}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={1}></Grid>
          <Grid item sm={8} xs={12}>
            {showAnim ? (
              <Fade bottom>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h2"
                    align="left"
                    className="categoryTitle"
                    style={{
                      fontFamily: "Dancing Script, cursive",
                      paddingBottom: "20px",
                      fontSize: "2.75rem",
                      marginLeft: "15px",
                    }}
                  >
                    Shop by Gender and Age
                  </Typography>
                </ThemeProvider>
              </Fade>
            ) : (
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h2"
                  align="left"
                  className="categoryTitle"
                  style={{
                    fontFamily: "Dancing Script, cursive",
                    paddingBottom: "20px",
                    fontSize: "2.75rem",
                    marginLeft: "15px",
                  }}
                >
                  Shop by Gender and Age
                </Typography>
              </ThemeProvider>
            )}
          </Grid>
        </Grid>
        <Grid container align="center">
          <Grid item sm={3} />
          <Grid item sm={6} xs={12} align="center" className={classes.luxury}>
            {showAnim ? <Flip bottom></Flip> : null}
          </Grid>
          <Grid item sm={3} />
        </Grid>
        <Grid
          className="catContainer"
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ display: "flex", marginTop: "20px" }}
        >
          <Grid item xs={12} sm={4} md={1} lg={1} />
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            style={{
              position: "relative",
              marginLeft: "25px",
              marginRight: "25px",
            }}
            onClick={() => toMenSection("men")}
          >
            {showAnim ? (
              <a href="#" style={{ cursor: "pointer" }}>
                <Fade left>
                  <CardMedia
                    component="img"
                    image={Man}
                    className="imagesMen"
                  />
                  <Button
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "1rem",
                      position: "absolute",
                      marginBottom: "30px",
                    }}
                    className="sectionsBtns"
                    onClick={() => toMenSection("men")}
                  >
                    Men's Section
                  </Button>
                </Fade>
              </a>
            ) : (
              <a href="#" style={{ cursor: "pointer" }}>
                <CardMedia component="img" image={Man} className="imagesMen" />
                <Button
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "1rem",
                    position: "absolute",
                    marginBottom: "30px",
                  }}
                  className="sectionsBtns"
                  onClick={() => toMenSection("men")}
                >
                  Men's Section
                </Button>
              </a>
            )}
          </Grid>
          <Grid
            style={{
              position: "relative",
              marginLeft: "25px",
              marginRight: "25px",
            }}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            onClick={() => toMenSection("women")}
          >
            {showAnim ? (
              <a href="#" style={{ cursor: "pointer" }}>
                <Fade bottom>
                  <CardMedia
                    component="img"
                    image={full}
                    className="imagesMen"
                  />
                  <Button
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "1rem",
                      position: "absolute",
                      marginBottom: "30px",
                    }}
                    className="sectionsBtns"
                    onClick={() => toMenSection("women")}
                  >
                    Women's Section
                  </Button>
                </Fade>
              </a>
            ) : (
              <a href="#" style={{ cursor: "pointer" }}>
                <CardMedia component="img" image={full} className="imagesMen" />
                <Button
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "1rem",
                    position: "absolute",
                    marginBottom: "30px",
                  }}
                  className="sectionsBtns"
                  onClick={() => toMenSection("women")}
                >
                  Women's Section
                </Button>
              </a>
            )}
          </Grid>
          <Grid
            style={{
              position: "relative",
              marginLeft: "25px",
              marginRight: "25px",
            }}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            onClick={() => toMenSection("kids")}
          >
            {showAnim ? (
              <a href="#" style={{ cursor: "pointer" }}>
                <Fade right>
                  <CardMedia
                    component="img"
                    image={Kid}
                    className="imagesMen"
                  />
                  <Button
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "1rem",
                      position: "absolute",
                      marginBottom: "30px",
                    }}
                    className="sectionsBtns"
                    onClick={() => toMenSection("kids")}
                  >
                    Kid's Section
                  </Button>
                </Fade>
              </a>
            ) : (
              <a href="#" style={{ cursor: "pointer" }}>
                <CardMedia component="img" image={Kid} className="imagesMen" />
                <Button
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "1rem",
                    position: "absolute",
                    marginBottom: "30px",
                  }}
                  className="sectionsBtns"
                  onClick={() => toMenSection("kids")}
                >
                  Kid's Section
                </Button>
              </a>
            )}
          </Grid>
          <Grid
            style={{
              position: "relative",
              marginLeft: "25px",
              marginRight: "25px",
            }}
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            onClick={() => toMenSection("babies")}
          >
            {showAnim ? (
              <a href="#" style={{ cursor: "pointer" }}>
                <Fade right>
                  <CardMedia
                    component="img"
                    image={Baby}
                    className="imagesMen"
                  />
                  <Button
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "1rem",
                      position: "absolute",
                      marginBottom: "30px",
                    }}
                    className="sectionsBtns"
                    onClick={() => toMenSection("babies")}
                  >
                    Baby's Section
                  </Button>
                </Fade>
              </a>
            ) : (
              <a href="#" style={{ cursor: "pointer" }}>
                <CardMedia component="img" image={Baby} className="imagesMen" />
                <Button
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "1rem",
                    position: "absolute",
                    marginBottom: "30px",
                  }}
                  className="sectionsBtns"
                  onClick={() => toMenSection("babies")}
                >
                  Baby's Section
                </Button>
              </a>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={1} lg={1} />
        </Grid>
        {showAnim ? (
          <Fade left>
            <Grid container className={classes.newsletter}>
              <Grid item sm={12} xs={12} align="center">
                <Typography variant="h2" className={classes.newsltr}>
                  Subscribe To Our Newsletter
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12} align="center">
                <ButtonHover />
              </Grid>
            </Grid>
          </Fade>
        ) : (
          <Grid container className={classes.newsletter}>
            <Grid item sm={12} xs={12} align="center">
              <Typography variant="h2" className={classes.newsltr}>
                Subscribe To Our Newsletter
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} align="center">
              <ButtonHover />
            </Grid>
          </Grid>
        )}
        <Grid container style={{ marginBottom: "30px" }}>
          <Grid item sm={1}></Grid>
          <Grid item sm={4} xs={12}>
            {showAnim ? (
              <Fade bottom>
                <ThemeProvider theme={theme}>
                  <Typography
                    variant="h2"
                    className="categoryTitle"
                    style={{
                      fontFamily: "Dancing Script, cursive",
                      fontSize: "2.75rem",
                      marginLeft: "15px",
                    }}
                  >
                    Shop by Category
                  </Typography>
                </ThemeProvider>
              </Fade>
            ) : (
              <ThemeProvider theme={theme}>
                <Typography
                  variant="h2"
                  className="categoryTitle"
                  style={{
                    fontFamily: "Dancing Script, cursive",
                    fontSize: "2.75rem",
                    marginLeft: "15px",
                  }}
                >
                  Shop by Category
                </Typography>
              </ThemeProvider>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          xs={12}
          sm={12}
          md={12}
          justifyContent="center"
          alignItems="center"
          style={{ border: "0px solid red" }}
        >
          {/* lg and xl */}
          <Hidden only={["xs", "sm", "md"]}>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 12,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsUpper}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsLower}
            </Grid>
          </Hidden>
          {/*md */}
          <Hidden only={["xs", "sm", "lg", "xl"]}>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 12,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsUpper1}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsMiddle1}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsLower1}
            </Grid>
          </Hidden>
          {/*sm */}
          <Hidden only={["xs", "md", "lg", "xl"]}>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 12,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsUpper2}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsMiddle21}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsMiddle22}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsLower2}
            </Grid>
          </Hidden>
          {/*xs */}
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 48,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsUpper3}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsMiddle31}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsMiddle32}
            </Grid>
            <Grid
              container
              direction="row"
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 50,
                marginRight: "30px",
                marginLeft: "30px",
                border: "0px solid red",
              }}
            >
              {catsLower3}
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </>
  );
};
export default HomePage;
