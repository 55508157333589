import NewSlider from "./newSlider/NewSlider";
import React, { useRef, useState, Fragment, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "./styles.css";
import bra11 from "../../images/bra11.jpg";
import bra12 from "../../images/bra12.jpg";
import robe1 from "../../images/robe1.jpg";
import bikinibottom from "../../images/bikinibottom.jpg";
import UseStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FavoriteIcon from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
////////////////////////////////////////////////////
import "rc-slider/assets/index.css";
import MetaData from "../layout/MetaData";
import Pagination from "react-js-pagination";
import Product from "../product/Product";
import Loader from "../layout/Loader";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import {
  getProducts,
  getHomePageProducts,
  addToWishlist,
  deleteWish,
  getWishlist,
} from "../../actions/productActions";
import { useAlert } from "react-alert";
import Posts from "../Posts/Posts";
import { useDispatch, useSelector } from "react-redux";
// import { getProducts } from '../actions/productActions'
// import { useAlert } from 'react-alert'
import Home from "../Home";
///////////////////////////////////////////////////
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SwiperComponent from "./Swiper";
// install Swiper modules
SwiperCore.use([Navigation]);
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
export default function App({ match }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [latestProducts, setLatestProducts] = useState([]);
  const [buttonFavoriteIsClicked, isFavoriteClicked] = useState(false);
  const [buttonFavoriteIsClicked1, isFavoriteClicked1] = useState(true);
  const [wishlist, setWishlist] = useState([]);
  const clickedFavoriteBtn = (idPost) => {
    const id = user._id;
    const formData = {
      product: idPost,
    };
    isFavoriteClicked(true);
    if (id !== null) {
      dispatch(addToWishlist(id, formData));
    }
  };
  const unClickedFavoriteBtn = (idPost) => {
    const userId = user._id;
    // dispatch(getWishlist(userId));
    isFavoriteClicked(false);
    if (userId !== null) {
      dispatch(deleteWish(userId, idPost));
    }
  };
  const clickedFavoriteBtn1 = (idPost) => {
    const id = user._id;
    const formData = {
      product: idPost,
    };
    isFavoriteClicked1(true);
    if (id !== null) {
      dispatch(addToWishlist(id, formData));
    }
  };
  const unClickedFavoriteBtn1 = (idPost) => {
    const userId = user._id;
    // dispatch(getWishlist(userId));
    isFavoriteClicked1(false);
    if (userId !== null) {
      dispatch(deleteWish(userId, idPost));
    }
  };
  // const alert = useAlert();
  useEffect(async () => {
    const data = await dispatch(getHomePageProducts());
    if (user !== null && user !== undefined) {
      const id = user._id;
      const wishlist = await dispatch(getWishlist(id));
      setWishlist(wishlist);
    }
    setLatestProducts(data);
  }, [dispatch]);
  const classes = UseStyles();
  return (
    <>
      <Swiper
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        className="mySwiper"
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },
          360: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },
          400: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },
          455: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 2,
          },

          600: {
            slidesPerView: 2,
            spaceBetween: 1,
            slidesPerGroup: 2,
          },
          900: {
            slidesPerView: 3,
            spaceBetween: 10,
            slidesPerGroup: 3,
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 12,
            slidesPerGroup: 3,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 14,
            slidesPerGroup: 4,
          },
          1500: {
            slidesPerView: 4,
            spaceBetween: 16,
            slidesPerGroup: 4,
          },
          1800: {
            slidesPerView: 5,
            spaceBetween: 17,
            slidesPerGroup: 5,
          },
          2200: {
            slidesPerView: 6,
            spaceBetween: 18,
            slidesPerGroup: 6,
          },
        }}
      >
        {/* {latestProducts.products &&
          latestProducts.products.map((product, index) => (
            <SwiperSlide className="swiperHeight">
              <SwiperComponent product={product} />
            </SwiperSlide>
          ))} */}
        {latestProducts.products && (
          <>
            <SwiperSlide>
              <NewSlider product={latestProducts.products[0]} />
            </SwiperSlide>
            <SwiperSlide>
              <NewSlider product={latestProducts.products[1]} />
            </SwiperSlide>
            <SwiperSlide>
              <NewSlider product={latestProducts.products[2]} />
            </SwiperSlide>
            <SwiperSlide>
              <NewSlider product={latestProducts.products[3]} />
            </SwiperSlide>
            <SwiperSlide>
              <NewSlider product={latestProducts.products[4]} />
            </SwiperSlide>
            <SwiperSlide>
              <NewSlider product={latestProducts.products[5]} />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </>
    // <>
    //   {latestProducts.products && (
    //     <Swiper
    //       style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
    //       navigation={true}
    //       // loop={true}
    //     >
    //       <SwiperSlide>
    //         <NewSlider product={latestProducts.products[0]} />
    //       </SwiperSlide>
    //       <SwiperSlide>
    //         <NewSlider product={latestProducts.products[1]} />
    //       </SwiperSlide>
    //       <SwiperSlide>
    //         <NewSlider product={latestProducts.products[2]} />
    //       </SwiperSlide>
    //       <SwiperSlide>
    //         <NewSlider product={latestProducts.products[3]} />
    //       </SwiperSlide>
    //       <SwiperSlide>
    //         <NewSlider product={latestProducts.products[4]} />
    //       </SwiperSlide>
    //       <SwiperSlide>
    //         <NewSlider product={latestProducts.products[5]} />
    //       </SwiperSlide>
    //     </Swiper>
    //   )}
    // </>
  );
}
