import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../layout/Loader'
import MetaData from '../layout/MetaData'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux'
import { login, clearErrors } from '../../actions/userActions'
import { useAlert } from 'react-alert'
import axios from 'axios'
const Login = ({ history, location }) => {
    const [openSnack, setSnackOpen] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const alert = useAlert();
    const dispatch = useDispatch();
    const { isAuthenticated, error } = useSelector(state => state.auth);
    const [User] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    const redirect = location.search ? location.search.split('=')[1] : '/'
    useEffect(() => {
        if (user) {
            history.push(redirect)
        }
        if (isAuthenticated) {
            history.push(redirect)
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, alert, isAuthenticated, error, history, user, redirect])
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        if (email.trim() === '' || password.trim() === '') {
            setSuccess(undefined);
            setSnackOpen(true);
        }
        else {
            let formData = {
                email: email,
                password: password
            }
            const res = await axios.post(`https://backend.lingeriefidele.com/api/v1/login`, formData);
            if (!res.data.success) {
                setSuccess(false);
                setSnackOpen(true);
            }
            else {
                setSuccess(true);
                setSnackOpen(true);
                localStorage.setItem('User', JSON.stringify({ ...res?.data }));
                window.location.reload(false);
            }
        }
    }
    return (
        <div>
            <Fragment>
                <MetaData title={'Login'} />
                <div className="row wrapper">
                    <div className="col-10 col-lg-5">
                        <form className="shadow-lg" onSubmit={submitHandler}>
                            <h1 className="mb-3">Login</h1>
                            <div className="form-group">
                                <label htmlFor="email_field">Email</label>
                                <input
                                    type="email"
                                    id="email_field"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password_field">Password</label>
                                <input
                                    type="password"
                                    id="password_field"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <Link to="/password/forgot" className="float-right mb-4">Forgot Password?</Link>
                            <button
                                id="login_button"
                                type="submit"
                                className="btn btn-block py-3"
                            >
                                LOGIN
                            </button>
                            <Link to="/register" className="float-right mt-3">New User?</Link>
                        </form>
                    </div>
                </div>
                {
                    success === true ? (
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert onClose={handleSnackClose} severity="success">
                                we will reach you back as fast as possible
                            </Alert>
                        </Snackbar>
                    ) : success === false ? (
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={handleSnackClose} severity="error">
                                Invalid Email or Password
                            </Alert>
                        </Snackbar>
                    ) : (
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={handleSnackClose} severity="error">
                                Please enter email & password
                            </Alert>
                        </Snackbar>
                    )
                }
            </Fragment>
        </div>
    )
}
export default Login
