import axios from 'axios';
import { NEW_CATEGORY_REQUEST, NEW_CATEGORY_SUCCESS, NEW_CATEGORY_FAIL, ALL_CATEGORIES_FETCH } from '../constants/categoryConstants';
import * as api from '../api/index.js';
export const createCategory = (category, history) => async (dispatch) => {
    try {
        const { data } = await api.createCategory(category)
        return data;
    } catch (error) {
        return false
    }
};
export const getCategories = () => async (dispatch) => {
    try {
        const { data } = await api.getCategories()
        return data;
    } catch (error) {
    }
}
// export const getSubCategories = (name) => async (dispatch) => {
//     try {
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }
//         const {data} = await axios.put(`/api/v1/subcategories`, {name}, config)
//         return data;
//     } catch (error) {
//     }
// }
export const deleteCategory = (id) => async (dispatch) => {
    try {
        const { data } = await api.deleteCategory(id)
        return data
    } catch (error) {
    }
}
export const getSubCategories = (name) => async (dispatch) => {
    try {
        const { data } = await api.getSubCategories({ name })
        return data;
    } catch (error) {
    }
}