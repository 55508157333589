import { Grid, Paper, TextField, Typography, Button, Snackbar, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import Paper from '@material-ui/core/Paper'
import Store from '../../images/store.png'
import './contactus.css'
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { contactUs } from "../../actions/contactUsAction";
import { Alert } from '@material-ui/lab';
import Hidden from '@material-ui/core/Hidden';
import { OverlayTrigger } from 'react-bootstrap';
const ContactUs = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true);
    useEffect(() => {
        if (show) {
            setTimeout(() => { setShow(false) }, 5000);
        }
    }, [])
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };
    const validationSchema = Yup.object({
        firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
        lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Last name required'),
        email: Yup.string()
            .email('Invalid email format')
            .required('Required!'),
        phoneNumber: Yup.string()
            .required('Phone required'),
        message: Yup.string()
            .required('Required!')
    })
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: ''
        },
        onSubmit: async (values) => {
            setLoading(true)
            const formData = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                message: values.message
            }
            const result = await dispatch(contactUs(formData))
            if (result) {
                setSuccess(true)
                setOpen(true)
                setLoading(false)
            }
            else {
                setSuccess(false)
                setOpen(true)
                setLoading(false)
            }
        },
        validationSchema,
    }
    )
    return (
        <>
            {/* <div style={{ zIndex: "5000" }}>
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
                <div
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}>
                    <OverlayTrigger
                        show={show}
                        placement='left'
                        overlay={
                            <div className="row" style={{ transition: 'bottom 0.5s' }}>
                                <div className="col">
                                    <div
                                        id="button-tooltip"
                                        style={{
                                            backgroundColor: '#25D3662a',
                                            padding: '2px 10px',
                                            color: 'Black',
                                            borderRadius: "16px",
                                            zIndex: "5000"
                                        }}
                                    >
                                        Contact fidele to ask about a products.
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <i class="fa fa-arrow-left" style={{ color: "black" }} variant="success"></i>
                                </div>
                            </div>
                        }
                    >
                        <a href="https://api.whatsapp.com/send?phone=96171566998&text=Hello%21%20I%20would%20like%20to%20get%20more%20information%20concerning%20a%20certain%20product%20please." alt="Whatsapp button" class="float" target="_blank" rel="noreferrer">
                            <i class="fa fa-whatsapp my-float" style={{ color: "white" }} variant="success"></i>
                        </a>
                    </OverlayTrigger>
                </div>
            </div> */}
            <form onSubmit={formik.handleSubmit}>
                <Grid container>
                    {/* <Grid item xs={12} md={12} sm={12} lg={12}> */}
                    < Grid container style={{ marginTop: '63px' }}>
                        <Grid item xs={12} md={1} sm={0} lg={1} />
                        <Grid item xs={12} md={12} sm={12} lg={4} style={{ marginBottom: 50 }}>
                            <Paper elevation={3}>
                                <div class="imageContainer">
                                    <img
                                        // component="img"
                                        src={Store}
                                    // style={{ width: "100%", maxWidth: "600px", position: "relative" }}
                                    // className={classes.images}
                                    />
                                    <div class="textContainer">
                                        <Typography class="contactText" >Contact Us</Typography>
                                    </div>
                                </div>
                                <Grid item sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                                    <Grid item xs={6} sm={6} md={6} direction="row" display="flex" justifyContent="center" alignItems="center">
                                        <div>
                                            <TextField name='firstName' value={formik.values.firstName} onBlur={formik.handleBlur}
                                                onChange={formik.handleChange} style={{ marginTop: 30, marginLeft: 30, width: '76%' }}
                                                id="outlined-basic" label="First Name" variant="outlined" />
                                            {formik.touched.firstName && formik.errors.firstName ? <div style={{ color: 'red', marginLeft: 30 }}>
                                                {formik.errors.firstName}</div> : null}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} direction="row" display="flex" justifyContent="center" alignItems="center">
                                        <div>
                                            <TextField name='lastName' value={formik.values.lastName} onBlur={formik.handleBlur}
                                                onChange={formik.handleChange} style={{ marginTop: 30, marginLeft: 30, marginRight: 30, width: '76%' }}
                                                id="outlined-basic" label="Last Name" variant="outlined" />
                                            {formik.touched.lastName && formik.errors.lastName ? <div style={{ color: 'red', marginLeft: 30 }}>
                                                {formik.errors.lastName}</div> : null}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} lg={12} >
                                    <div>
                                        <TextField name='email' value={formik.values.email} type='email' onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} style={{ marginTop: 30, marginLeft: 30, width: '88%' }}
                                            id="outlined-basic" label="Email" variant="outlined" />
                                        {formik.touched.email && formik.errors.email ? <div style={{ color: 'red', marginLeft: 30 }}>
                                            {formik.errors.email}</div> : null}
                                    </div>
                                </Grid>
                                <Grid item lg={12} >
                                    <div>
                                        <TextField name='phoneNumber' value={formik.values.phoneNumber} onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} style={{ marginTop: 30, marginLeft: 30, width: '88%' }}
                                            id="outlined-basic" label="Phone Number" variant="outlined" />
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? <div style={{ color: 'red', marginLeft: 30 }}>
                                            {formik.errors.phoneNumber}</div> : null}
                                    </div>
                                </Grid>
                                <Grid item lg={12}>
                                    <div>
                                        <TextField
                                            style={formik.touched.message && formik.errors.message ? { marginTop: 30, marginLeft: 30, width: '88%' } : { marginTop: 30, marginLeft: 30, marginBottom: 30, width: '88%' }}
                                            name='message'
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            id="outlined-multiline-static"
                                            label="Message"
                                            multiline
                                            rows={4}
                                            defaultValue="Default Value"
                                            variant="outlined"
                                        />
                                        {formik.touched.message && formik.errors.message ? <div style={{ color: 'red', marginBottom: 30, marginLeft: 30 }}>{formik.errors.message}</div> : null}
                                    </div>
                                </Grid>
                                {/* <Grid item lg={1} /> */}
                                <Grid item lg={12} align="center">
                                    {loading ? <CircularProgress /> :
                                        <Button type="submit" variant="contained" className='btnSubmit'>Submit</Button>
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        {/* <Grid item xs={12} md={6} sm={12} lg={1} /> */}
                        <Grid item xs={12} md={12} sm={12} lg={6}  >
                            <Hidden only={['xs', 'sm', 'md']}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.1772619180415!2d35.89075651516629!3d33.85932228065895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f35f6d1072043%3A0xf4eab94e22a65c43!2sLINGERIE%20FID%C3%88LE!5e0!3m2!1sen!2slb!4v1631105256172!5m2!1sen!2slb"
                                    allowfullscreen="" loading="lazy" style={{ width: "100%", height: "100%", paddingBottom: 50, paddingLeft: 30 }} className="mapmobile">
                                </iframe>
                            </Hidden>
                            <Hidden only={['xs', 'lg', 'xl']}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.1772619180415!2d35.89075651516629!3d33.85932228065895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f35f6d1072043%3A0xf4eab94e22a65c43!2sLINGERIE%20FID%C3%88LE!5e0!3m2!1sen!2slb!4v1631105256172!5m2!1sen!2slb"
                                    allowfullscreen="" loading="lazy" style={{ width: "100%", height: "60vw", paddingBottom: 50, paddingLeft: 20, paddingRight: 20 }} className="mapmobile">
                                </iframe>
                            </Hidden>
                            <Hidden only={['sm', 'lg', 'md', 'xl']}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3313.1772619180415!2d35.89075651516629!3d33.85932228065895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f35f6d1072043%3A0xf4eab94e22a65c43!2sLINGERIE%20FID%C3%88LE!5e0!3m2!1sen!2slb!4v1631105256172!5m2!1sen!2slb"
                                    allowfullscreen="" loading="lazy" style={{ width: "100%", height: "80vw", paddingBottom: 50, paddingLeft: 10, paddingRight: 10 }} className="mapmobile">
                                </iframe>
                            </Hidden>
                        </Grid>
                        {/* <Grid item xs={12} md={6} sm={12} lg={1} /> */}
                    </Grid>
                </Grid>
            </form>
            {
                success !== undefined ? (
                    success ? (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                            <Alert onClose={handleSnackClose} severity="success">
                                we will reach you back as fast as possible
                            </Alert>
                        </Snackbar>
                    ) : (
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                            <Alert onClose={handleSnackClose} severity="error">
                                There is something wrong , your request is lost please try again
                            </Alert>
                        </Snackbar>
                    )) : null
            }
        </>
    )
}
export default ContactUs