import React, { useState, useContext } from "react";
import {

  BoldLink1,
  BoxContainer,
  FormContainer,
  Input,
  MutedLink,
  SubmitButton,
} from "./common";
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';
import { Marginer } from "../marginer";
import { AccountContext } from "./accountContext";
import MetaData from '../layout/MetaData';
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../actions/userActions'
import axios from "axios";
import './style.css';

const Register = ({ history }) => {
  const { switchToSignin } = useContext(AccountContext);

  const [openSnack, setSnackOpen] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
  })

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };
  const { name, email, password } = user;
  const dispatch = useDispatch();

  const { loading } = useSelector(state => state.auth);


  const submitHandler = async (e) => {
    e.preventDefault();
    if (name.trim() === '' && email.trim() === '' && password.trim() === '') {
      setMessage('Please enter your information')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() === '' && email.trim() === '' && password.trim() !== '') {
      setMessage('Please enter name & email')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() === '' && email.trim() !== '' && password.trim() === '') {
      setMessage('Please enter name & password')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() !== '' && email.trim() === '' && password.trim() === '') {
      setMessage('Please enter email & password')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() === '' && email.trim() !== '' && password.trim() !== '') {
      setMessage('Please enter name')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() !== '' && email.trim() !== '' && password.trim() === '') {
      setMessage('Please enter a password')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() !== '' && email.trim() === '' && password.trim() !== '') {
      setMessage('Please enter email')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() !== '' && email.trim() !== '' && password.trim() !== '' && password.length < 6) {
      setMessage('Your password must be longer than 6 characters')
      setSuccess(false);
      setSnackOpen(true);
    }
    else if (name.trim() !== '' && email.trim() !== '' && password.trim() !== '' && name.length > 30) {
      setMessage('Your name cannot exceed 30 characters')
      setSuccess(false);
      setSnackOpen(true);
    }
    else {
      const formData = new FormData();
      formData.set('name', name);
      formData.set('email', email.toLowerCase().trim());
      formData.set('password', password);
      const res = await axios.post(`https://backend.lingeriefidele.com/api/v1/register`, formData);
      if (!res.data.success) {
        setMessage('Email already exist')
        setSuccess(false);
        setSnackOpen(true);
      }
      else {
        setSuccess(true);
        setSnackOpen(true);
        localStorage.setItem('User', JSON.stringify({ ...res?.data }));
        sessionStorage.removeItem("keyAnim");
        window.location.reload(false);
      }


      /*await axios.post(`http://localhost:4000/api/v1/register`, formData).then(response => {
      }, err => {
      });

      const data = await dispatch(register(formData))
      if (data) {
        setSuccess(true);
        setSnackOpen(true);
        window.location.href = "/";
      }
      if (!data) {
        setMessage('Email already exist')
        setSuccess(false);
        setSnackOpen(true);
    }*/
    }
  }

  const onChange = e => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const [showPassword, setShowPassword] = useState(false);

  return (
    <BoxContainer>
      <FormContainer onSubmit={submitHandler} encType='multipart/form-data'>
        <MetaData title={'Register User'} />
        <Input type="name"
          id="name_field"
          className="form-control"
          name='name'
          value={name}
          style={{ marginBottom: "10px" }}
          placeholder="Name"
          onChange={onChange} />
        <Input type="email"
          id="email_field"
          className="form-control"
          name='email'
          style={{ marginBottom: "10px" }}
          value={email}
          placeholder="Email"
          onChange={onChange} />
        <div className="pass-wrapper">
          <Input
            type={showPassword ? "text" : "password"}
            id="password_field"
            className="form-control"
            name='password'
            value={password}
            placeholder="Password"
            style={{ marginBottom: "10px" }}
            onChange={onChange} />
          {showPassword ? (
            <i class="fa fa-eye-slash" style={{ cursor: "pointer", position: "absolute", right: "10px" }}
              onClick={() => setShowPassword(showPassword => !showPassword)}></i>
          ) : (
            <i class="fa fa-eye" style={{ cursor: "pointer", position: "absolute", right: "10px" }}
              onClick={() => setShowPassword(showPassword => !showPassword)}></i>
          )}
        </div>

        <SubmitButton
          id="register_button"
          type="submit"
          disabled={loading ? true : false}
          type="submit">
          SIGN UP
        </SubmitButton>

      </FormContainer>
      <Marginer direction="vertical" margin={10} />
      {/* <SubmitButton
        id="register_button"
                            type="submit"
                            className="btn btn-block py-3"
                            disabled={loading ? true : false}
      type="submit">Signup</SubmitButton> */}
      <Marginer direction="vertical" margin="1em" />
      <MutedLink href="#">
        Already have an account?
        <BoldLink1 href="#" onClick={switchToSignin}>
          SIGN IN
        </BoldLink1>
      </MutedLink>
      {
        success !== undefined ? (
          success ? (
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert onClose={handleSnackClose} severity="success">
                Successfully registered
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
              <Alert onClose={handleSnackClose} severity="error">
                {message}
              </Alert>
            </Snackbar>
          )) : null
      }
    </BoxContainer>
  );
}

export default Register
