import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import logoImage from './logo.png'

// ----------------------------------------------------------------------
LogoWhite.propTypes = {
  sx: PropTypes.object
};

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '50%',
  maxHeight: '50%',
});



export default function LogoWhite({ sx }) {
  // const navigate = useNavigate()
  const goToHomePage = () => {
    window.location.href = "/";
};
  return <Img onClick={goToHomePage} src={logoImage} sx={{ minWidth: 150, minHeight: 145, maxWidth: 160,maxHeight:153, ...sx }} />;
}