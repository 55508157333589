import { makeStyles } from "@material-ui/core/styles";
const UseStyles = makeStyles((theme) => ({
    rootAds: {
        [theme.breakpoints.down("xs")]: {
            maxWidth: 274,

        }, [theme.breakpoints.up("lg")]: {
            maxWidth: 400,
        },
    },
    Favorite: {
        [theme.breakpoints.down("xs")]: {
            position: "absolute",
            top: "0.1em !important",
            right: '0px !important',

        }, [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: "3px !important",
            right: '3px !important',
            zIndex: 200,
        },
    },
    Status: {
        [theme.breakpoints.down("xs")]: {
            position: "absolute",
            top: "0px !important",
            left: '0px !important',
            background: '#c8e6c9 !important',
            color: '#256029 !important',
            borderRadius: '2px !important',
            padding: '0.25em 0.5rem !important',
            textTransform: 'uppercase !important',
            fontWeight: '700 !important',
            fontSize: '12px !important',
            letterSpacing: '0.3px !important',
            zIndex: 200,
        }, [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: "0px !important",
            left: '0px !important',
            zIndex: 200,
            background: '#c8e6c9 !important',
            color: '#256029 !important',
            borderRadius: '2px !important',
            padding: '0.25em 0.5rem !important',
            textTransform: 'uppercase !important',
            fontWeight: '700 !important',
            fontSize: '12px !important',
            letterSpacing: '0.3px !important',
        },
    },
    StatusOut: {
        [theme.breakpoints.down("xs")]: {
            position: "absolute",
            top: "0px !important",
            left: '0px !important',
            background: '#ffcdd2 !important',
            color: '#c63737 !important',
            borderRadius: '2px !important',
            padding: '0.25em 0.5rem !important',
            textTransform: 'uppercase !important',
            fontWeight: '700 !important',
            fontSize: '12px !important',
            letterSpacing: '0.3px !important',
            zIndex: 200,
        }, [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: "0px !important",
            left: '0px !important',
            zIndex: 200,
            background: '#ffcdd2 !important',
            color: '#c63737 !important',
            borderRadius: '2px !important',
            padding: '0.25em 0.5rem !important',
            textTransform: 'uppercase !important',
            fontWeight: '700 !important',
            fontSize: '12px !important',
            letterSpacing: '0.3px !important',
        },
    },
    Title: {
        fontFamily: 'Roboto'
    },
    price: {
        color: '#C9E4E7',
        fontWeight: 'bold'
    },
    radiored: {
        color: '#621940',
    },
    radiogrey: {
        color: 'grey',
    },
    radioblack: {
        color: 'black',
    },
    radiogreen: {
        color: '#728342',
    },
    colors: {
        marginLeft: -4
    },
    product: {
        position: 'relative',
        perspective: '800px',
        width: '306px',
        height: '471px',
        transformStyle: 'preserve-3d',
        transition: 'transform 5s',
        float: 'left',
        marginRight: '23px',
        webkitTransition: 'width 500ms ease-in-out',
    },
    productfront: {
        width: '315px',
        height: '480px',
        background: '#fff',
        position: 'absolute',
        left: '-5px',
        top: '-5px',
        webkitTransition: 'all 100ms ease-out',
        transition: 'all 100ms ease-out',
        '& img': {
            width: '100%',
        }
    },
    make3D: {
        width: '305px',
        height: '470px',
        position: 'absolute',
        top: '10px',
        left: '10px',
        overflow: 'hidden',
        transformStyle: 'preserve-3d',
        webkitTransition: ' 100ms ease-out',
        transition: '100ms ease-out',
    },
    rating: {
        marginTop: '-1em',
    },

}));
export default UseStyles