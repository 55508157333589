import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'
import { Sidebar } from './Sidebar'
import axios from 'axios'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { newProduct, clearErrors } from '../../actions/productActions'
import { NEW_PRODUCT_RESET } from '../../constants/productConstants';
import { getCategories, getSubCategories } from '../../actions/categoryActions';
import SubCatOpt from './SubCategoryOption';
import { Checkbox, CircularProgress, InputAdornment, TextField } from "@material-ui/core";
import './style.css'
import Select from 'react-select';
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab';

let OptionsState = [];
const NewProduct = ({ history }) => {
    const [name, setName] = useState('');
    const [productId, setProductId] = useState('');
    const [price, setPrice] = useState(0);
    const [dollarPrice, setDollarPrice] = useState(0);
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('Women');
    const [subcategory, setSubCategory] = useState('');
    const [subSubcategory, setSubSubCategory] = useState('');
    const [collectionType, setCollectionType] = useState('');
    // const [seller, setSeller] = useState('');
    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [mainImage, setMainImage] = useState('');
    const [mainImagePrev, setMainImagePrev] = useState('');
    const [size, setSize] = useState('');
    const [statusOpt, setStatusOpt] = useState('INSTOCK');
    const [optDescription, setOptDescription] = useState('');
    const [optCode, setOptCode] = useState('');
    const [color, setColor] = useState('');
    const [oprice, setOprice] = useState(0);
    const [doprice, setDoprice] = useState(0);
    const [discountRate, setDiscountRate] = useState(0);
    //const [quantity, setQuantity] = useState('');
    const [categories, setCategories] = useState([]);
    const [subCats, setSubCats] = useState([]);
    const [cat] = useState('Men');
    //const [discountChecked, setChecked] = useState(false)
    const [noOfOptions, setNoOfOptions] = useState(0);
    const [openSnack, setSnackOpen] = useState(false);
    const [messageBody, setMessageBody] = useState('');
    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false)
    };
    const [StatusOptions] = useState([
        { value: 'INSTOCK', label: 'INSTOCK' },
        { value: 'OUTSTOCK', label: 'OUTStock' }
    ]);
    const [subsubcategory] = useState([
        { value: '', label: '' },
        { value: 'Jacket', label: 'Jacket' },
        { value: 'Tracksuit', label: 'Tracksuit' },
        { value: 'Legging', label: 'Legging' },
        { value: 'Top', label: 'Top' },
        { value: 'Pants', label: 'Pants' },
        { value: 'Bath', label: 'Bath' },
        { value: 'Baptism', label: 'Baptism' },
        { value: 'Face', label: 'Face' }
    ])
    const [collectiontype] = useState([
        { value: '', label: '' },
        { value: 'Winter', label: 'Winter' },
        { value: 'Summer', label: 'Summer' }
    ])
    const [sizes] = useState([
        { value: 'One Size', label: 'One Size' },
        { value: 'XS', label: 'XS' },
        { value: 'S', label: 'S' },
        { value: 'M', label: 'M' },
        { value: 'L', label: 'L' },
        { value: 'XL', label: 'XL' },
        { value: '2XL', label: '2XL' },
        { value: '3XL', label: '3XL' },
        { value: '4XL', label: '4XL' },
        { value: '5XL', label: '5XL' },
        { value: 'S-M', label: 'S-M' },
        { value: 'L-XL', label: 'L-XL' },
        { value: 'NB-0 Month', label: 'NB-0 Month' },
        { value: '3 Months', label: '3 Months' },
        { value: '6 Months', label: '6 Months' },
        { value: '9 Months', label: '9 Months' },
        { value: '12 Months', label: '12 Months' },
        { value: '18 Months', label: '18 Months' },
        { value: '24 Months', label: '24 Months' },
        { value: '30 Months', label: '30 Months' },
        { value: '36 Months', label: '36 Months' },
        { value: '0-3', label: '0-3' },
        { value: '3-6', label: '3-6' },
        { value: '6-9', label: '6-9' },
        { value: '9-12', label: '9-12' },
        { value: '12-18', label: '12-18' },
        { value: '18-24', label: '18-24' },
        { value: '24-30', label: '24-30' },
        { value: '30-36', label: '30-36' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '1/2', label: '1/2' },
        { value: '3/4', label: '3/4' },
        { value: '5/6', label: '5/6' },
        { value: '7/8', label: '7/8' },
        { value: '9/10', label: '9/10' },
        { value: '11/12', label: '11/12' },
        { value: '13/14', label: '13/14' },
        { value: '15/16', label: '15/16' },
        { value: '35', label: '35' },
        { value: '36', label: '36' },
        { value: '37', label: '37' },
        { value: '38', label: '38' },
        { value: '39', label: '39' },
        { value: '40', label: '40' },
        { value: '41', label: '41' },
        { value: '42', label: '42' },
        { value: '43', label: '43' },
        { value: '44', label: '44' },
        { value: '45', label: '45' },
        { value: '46', label: '46' },
        { value: 'Single', label: 'Single' },
        { value: 'Double', label: 'Double' },
        { value: 'King', label: 'King' }
    ]);
    const [colors, setColors] = useState([]);

    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, success } = useSelector(state => state.newProduct);
    const [User] = useState(JSON.parse(localStorage.getItem('User')));
    const user = User ? User.user : null
    const [originalDolarRate, setOriginalDollarRate] = useState(0);
    const getOriginalDollarRate = async () => {
        try {
            const response = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/dollarRate`)
            const response1 = await axios.get(`https://backend.lingeriefidele.com/api/v1/admin/allColors`)
            setOriginalDollarRate(response.data.dr[0].value);
            response1.data.colors.push({ value: "", label: "" });
            setColors(response1.data.colors);

        } catch (error) {
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        getOriginalDollarRate();
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
        if (success) {
            history.push('/admin/dashboard');
            alert.success('Product created successfully');
            dispatch({ type: NEW_PRODUCT_RESET })
        }
        const data = await dispatch(getCategories());
        setCategories(data);
        const subCats = await dispatch(getSubCategories(cat));
        setSubCats(subCats);
    }, [dispatch, alert, error, success, history, cat])
    const submitHandler = async (e) => {
        e.preventDefault();
        if (mainImage === '' || !mainImage) {
            setMessageBody('Select Main Image')
            setSnackOpen(true);
            return;
        }
        if (productId.trim() === '' || name.trim() === '' || description.trim() === '' || category.trim() === '' || subcategory.trim() === '') {
            // || seller.trim() === '') {
            setMessageBody('Empty Fields!')
            setSnackOpen(true);
            return;
        }
        /*if (images.length <= 0) {
            setMessageBody('Required 1 image minimum')
            setSnackOpen(true);
            return;
        }*/
        if (OptionsState.length <= 0) {
            setMessageBody('Required 1 option minimum')
            setSnackOpen(true);
            return;
        }
        let isValid = true;
        OptionsState.forEach(o => {
            //if (o.color === '' || o.size === '') {
            if (o.size === '') {
                isValid = false;
            }
        })
        if (!isValid) {
            setMessageBody('All options must have size')
            setSnackOpen(true);
            return;
        }
        let IMAGES = [];
        const formData = new FormData();
        formData.append('images', mainImage);
        if (images && images.length > 0) {
            images.forEach(image => {
                formData.append('images', image)
            })
        }
        for (var pair of formData.entries()) {
            IMAGES.push(pair[1]);
        }
        let options = [];
        options = OptionsState;
        options.forEach(o => {
            o.color = o.color.value
            o.size = o.size.value
            o.oprice = o.oprice
            o.doprice = o.doprice
            o.description = o.description
            o.optCode = o.optCode
            o.statusOpt = o.statusOpt.value ? o.statusOpt.value : 'INSTOCK'
        })
        if (subSubcategory === '') {
            const form = {
                productId: productId,
                name: name,
                price: price,
                dollarPrice: dollarPrice,
                description: description,
                category: category,
                subcategory: subcategory,
                collectiontype: collectionType,
                // seller: seller,
                options: options,
                images: IMAGES,
                discountRate: discountRate
            }
            /*if (discountChecked) {
                form.discountRate = discountRate
                form.isChecked = discountChecked
            }*/
            await dispatch(newProduct(form, user._id));
        } else {
            const form = {
                productId: productId,
                name: name,
                price: price,
                dollarPrice: dollarPrice,
                description: description,
                category: category,
                subcategory: subcategory,
                subsubcategory: subSubcategory,
                collectiontype: collectionType,
                // seller: seller,
                options: options,
                images: IMAGES,
                discountRate: discountRate
            }
            /*if (discountChecked) {
                form.discountRate = discountRate
                form.isChecked = discountChecked
            }*/
            await dispatch(newProduct(form, user._id));
        }
    }

    const removeImage = (i) => {
        /*imagesPreview.splice(i, 1);
        images.splice(i, 1);*/
        setImagesPreview(oldArray => oldArray.filter((c, idx) => idx !== i))
        setImages(oldArray => oldArray.filter((c, idx) => idx !== i))
    }
    const onChange1 = e => {
        const file = e.target.files[0]
        setMainImage('');
        setMainImagePrev('');
        //files.forEach(file => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setMainImagePrev(reader.result)
                setMainImage(reader.result)
            }
        }
        reader.readAsDataURL(file)
        //})
    }
    const onChange = e => {
        const files = Array.from(e.target.files)
        /*setImagesPreview([]);
        setImages([])*/
        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview(oldArray => [...oldArray, reader.result])
                    setImages(oldArray => [...oldArray, reader.result])
                }
            }
            reader.readAsDataURL(file)
        })
    }
    const statusHandler = (e, index) => {
        OptionsState[index].statusOpt = e;
        setStatusOpt(e);
    }
    const colorHandler = (e, index) => {
        OptionsState[index].color = e;
        setColor(e);
    }
    /*const quantityHandler = (e, index) => {
        OptionsState[index].quantity = parseInt(e.target.value);
        setQuantity(e.target.value)
    }*/
    const sizeHandler = (e, index) => {
        OptionsState[index].size = e;
        setSize(e);
    }
    const optDescriptionHandler = (e, index) => {
        OptionsState[index].optdescription = e.target.value;
        setOptDescription(e);
    }
    const optCodeHandler = (e, index) => {
        OptionsState[index].optCode = e.target.value;
        setOptCode(e);
    }
    const opriceHandler = (e, index) => {
        OptionsState[index].doprice = e.target.value;
        setDoprice(e.target.value);
        OptionsState[index].oprice = e.target.value * originalDolarRate;
        setOprice(e.target.value);
    }
    const addAnother = () => {
        let isValid = true;
        for (let idx = 0; idx < noOfOptions; idx++) {
            //if (OptionsState[idx].color === '' || OptionsState[idx].size === '') {
            if (OptionsState[idx].size === '') {
                isValid = false;
            }
        }
        if (isValid) {
            setNoOfOptions(noOfOptions + 1);
            OptionsState.push({ size: '', color: '', doprice: 0, oprice: 0, optdescription: '', optCode: '', statusOpt: 'INSTOCK' })// quantity: 0
        } else {
            setMessageBody('Please fill size')
            setSnackOpen(true);
        }
    }
    const removeOption = (idx) => {
        setNoOfOptions(noOfOptions - 1);
        OptionsState.splice(idx, 1);
    }
    const min = 0;
    const max = 100
    return (
        <Fragment>
            <MetaData title={'New Product'} />
            <div className="row">
                {/* <div className="col-12 col-md-2">
                    <Sidebar />
                </div> */}
                <div className="col-12">
                    <div className="wrapper my-5">
                        <form className="shadow-lg" onSubmit={submitHandler} encType='multipart/form-data'>
                            <h1 className="mb-4 titlecms">New Product</h1>
                            <div className="row">
                                <div className="col-sm">
                                    <label htmlFor="name_field">Product Id</label>
                                    <input
                                        type="text"
                                        id="name_field"
                                        className="form-control"
                                        value={productId}
                                        onChange={(e) => setProductId(e.target.value)}
                                    />
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="name_field">Name</label>
                                    <input
                                        type="text"
                                        id="name_field"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label htmlFor="price_field">Dollar Price</label>
                                    <input
                                        type="number"
                                        id="price_field"
                                        className="form-control"
                                        value={dollarPrice}
                                        onChange={(e) => { setDollarPrice(e.target.value); setPrice((e.target.value * originalDolarRate)) }}
                                    />
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="price_field">Price</label>
                                    <input
                                        type="number"
                                        id="price_field"
                                        className="form-control"
                                        value={price}
                                        //onChange={(e) => setPrice(e.target.value)}
                                        disabled
                                        readOnly
                                    />
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="price_field">Discount Rate</label>
                                    <div className="row">
                                        {/* <div className='col-sm'>
                                            <Checkbox size="small" value={discountChecked} onClick={() => setChecked(!discountChecked)} />
                                        </div> */}
                                        <div className='col-sm'>
                                            <TextField
                                                type='number'
                                                value={discountRate}
                                                // disabled={!discountChecked}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                }}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    if (value > max) value = max;
                                                    if (value < min) value = min;
                                                    setDiscountRate(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label htmlFor="description_field">Description</label>
                                    <textarea className="form-control" id="description_field" rows="8" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label htmlFor="category_field">Category</label>
                                    <select className="form-control" id="category_field" value={category} onChange={(e) => setCategory(e.target.value)}>
                                        {
                                            categories.data !== undefined ? categories.data.map(category => (
                                                <option key={category} value={category.name} >{category.name}</option>
                                            )) : null
                                        }
                                    </select>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="subCat_field">Sub-Category</label>
                                    <select className="form-control" id="subCat_field" value={subcategory} onChange={(e) => setSubCategory(e.target.value)}>
                                        <SubCatOpt category={category} />
                                    </select>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="category_field">Sub-Sub-Category</label>
                                    <select className="form-control" id="category_field" value={subSubcategory} onChange={(e) => setSubSubCategory(e.target.value)}>
                                        {
                                            subsubcategory.map(category => (
                                                <option key={category} value={category.value} >{category.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-sm">
                                    <label htmlFor="category_field">Collection Type</label>
                                    <select className="form-control" id="category_field" value={collectionType} onChange={(e) => setCollectionType(e.target.value)}>
                                        {
                                            collectiontype.map(category => (
                                                <option key={category} value={category.value} >{category.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {/* <div className="col-sm">
                                    <label htmlFor="seller_field">Seller Name</label>
                                    <input
                                        type="text"
                                        id="seller_field"
                                        className="form-control"
                                        value={seller}
                                        onChange={(e) => setSeller(e.target.value)}
                                    />
                                </div> */}
                            </div>
                            <div className="row mt-2 ml-1 mr-1">
                                <label htmlFor="customFile">Main Image</label>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='product_images'
                                        className='custom-file-input'
                                        id='customFile'
                                        onChange={onChange1}
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        Choose Main Image
                                    </label>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {
                                            mainImagePrev && mainImagePrev !== '' ?
                                                (<img src={mainImagePrev} key={mainImagePrev} alt="Images Preview" className="mt-3 mr-2" width="100" height="100" />)
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 ml-1 mr-1">
                                <label htmlFor="customFile">Images</label>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='product_images'
                                        className='custom-file-input'
                                        id='customFile'
                                        onChange={onChange}
                                        multiple
                                    />
                                    <label className='custom-file-label' htmlFor='customFile'>
                                        Choose Images
                                    </label>
                                </div>
                                <div className="row">
                                    {imagesPreview.map((img, idx) => (
                                        <div className="col">
                                            <i className='fa fa-close' style={{ cursor: 'pointer', position: 'absolute', right: 0, top: 1 }} onClick={e => removeImage(idx)}></i>
                                            <img src={img} key={img} alt="Images Preview" className="mt-3 mr-2" width="100" height="100" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col">
                                <h4 className="mb-2 titlecms mt-2">
                                    Options
                                    {
                                        noOfOptions === 0 ?
                                            (<i className="fa fa-plus ml-1" style={{ cursor: "pointer", color: "#A2D7E9" }} onClick={() => addAnother()}></i>)
                                            : null
                                    }
                                </h4>
                                <div className="col">
                                    {[...Array(noOfOptions)].map((elemt, index) => {
                                        return (
                                            <div className="container">
                                                <div className="row">
                                                    <div class="col-sm">
                                                        Option {index + 1}
                                                        <i className="fa fa-trash ml-1" style={{ cursor: "pointer", color: "red" }} onClick={() => removeOption(index)}></i>
                                                        {
                                                            index === OptionsState.length - 1 ?
                                                                (<i className="fa fa-plus ml-1" style={{ cursor: "pointer", color: "#A2D7E9" }} onClick={() => addAnother()}></i>)
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sm">
                                                        <label htmlFor="color_field">Status</label>
                                                        <Select
                                                            id="color_field"
                                                            placeholder="Select Status"
                                                            options={StatusOptions}
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].statusOpt : statusOpt}
                                                            onChange={(e) => { statusHandler(e, index) }}
                                                        />
                                                    </div>
                                                    <div className="col-sm">
                                                        <label htmlFor="color_field">Color</label>
                                                        <Select
                                                            id="color_field"
                                                            placeholder="Select Color"
                                                            options={colors}
                                                            isLoading={!colors}
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].color : color}
                                                            onChange={(e) => { colorHandler(e, index) }}
                                                        />
                                                        {/* <select className="form-control" id="color_field" value={OptionsState[index] !== undefined ? OptionsState[index].color : color} onChange={(e) => {
                                                            colorHandler(e, index)
                                                        }}>
                                                            {colors.map(color => (
                                                                <option key={color} value={color}>{color}</option>
                                                            ))}
                                                        </select> */}
                                                    </div>
                                                    {/* <div className="col-sm">
                                                        <label htmlFor="quantity_field">Quantity</label>
                                                        <input
                                                            type="number"
                                                            id="quantity_field"
                                                            className="form-control"
                                                            min="0"
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].quantity : quantity}
                                                            onChange={(e) => quantityHandler(e, index)}
                                                        />
                                                    </div> */}
                                                    <div className="col-sm">
                                                        <label htmlFor="size_field">Sizes</label>
                                                        <Select
                                                            id="color_field"
                                                            placeholder="Select Size"
                                                            options={sizes}
                                                            isLoading={!sizes}
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].size : size}
                                                            onChange={(e) => { sizeHandler(e, index) }}
                                                        />
                                                        {/* <select className="form-control" id="size_field" value={OptionsState[index] !== undefined ? OptionsState[index].size : size} onChange={(e) => sizeHandler(e, index)}>
                                                            {sizes.map(size => (
                                                                <option key={size} value={size} >{size}</option>
                                                            ))}
                                                        </select> */}
                                                    </div>
                                                    <div className="col-sm">
                                                        <label htmlFor="size_field">Price($)</label>
                                                        <input
                                                            type="number"
                                                            id="price_field"
                                                            className="form-control"
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].doprice : doprice}
                                                            onChange={(e) => { opriceHandler(e, index) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sm">
                                                        <label htmlFor="size_field">Price</label>
                                                        <input
                                                            type="number"
                                                            id="price_field"
                                                            className="form-control"
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].oprice : oprice}
                                                            disabled
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="col-sm">
                                                        <label htmlFor="size_field">Code</label>
                                                        <input
                                                            type="text"
                                                            id="price_field"
                                                            className="form-control"
                                                            onChange={(e) => { optCodeHandler(e, index) }}
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].optCode : optCode}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <label htmlFor="size_field">Description</label>
                                                        <input
                                                            type="text"
                                                            id="price_field"
                                                            className="form-control"
                                                            onChange={(e) => { optDescriptionHandler(e, index) }}
                                                            value={OptionsState[index] !== undefined ? OptionsState[index].optdescription : optDescription}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <button
                                        id="login_button"
                                        type="submit"
                                        className="btn btn-block py-3"
                                        disabled={loading ? true : false}>
                                        CREATE
                                    </button>
                                </div>
                                <div className="row">
                                    {loading ? (<CircularProgress />) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
                <Alert onClose={handleSnackClose} severity="error">
                    {messageBody}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}
export default NewProduct